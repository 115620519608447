import React from 'react'
import styled from 'styled-components'

interface CustomToggleProps {
  children: React.ReactNode
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const CustomToggle = React.forwardRef<HTMLDivElement, CustomToggleProps>(
  ({ children, onClick }, ref) => (
    <ItemContainer>
      <div ref={ref} onClick={(e) => onClick(e)}>
        {children}
      </div>
    </ItemContainer>
  )
)

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  width: 27px;
  height: 22px;

  .dropdown-item {
    padding: 0;
    border-radius: 8px;
  }
`

export default CustomToggle
