import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'
// import * as auth from '../../app/modules/auth'
import modalReducer from '../../app/modules/redux-toolkit/modalSlicer'
import alertReducer from '../../app/modules/redux-toolkit/alertSlicer'
import countryReducer from '../../app/modules/redux-toolkit/countrySlicer'
import storeReducer from '../../app/modules/redux-toolkit/storeSlicer'
import planReducer from '../../app/modules/redux-toolkit/planSlicer'
import userReducer from '../../app/modules/redux-toolkit/userSlicer'

export const rootReducer = combineReducers({
  // auth: auth.reducer,
  modal: modalReducer,
  alert: alertReducer,
  countries: countryReducer,
  stores: storeReducer,
  plans: planReducer,
  user: userReducer,
})

export type RootState = ReturnType<typeof rootReducer>

// export function* rootSaga() {
//   yield all([auth.saga()])
// }
