import clsx from 'clsx'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks} from '../../../partials'
import {useLayout} from '../../core'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {config} = useLayout()

  return (
    <Container className='d-flex align-items-stretch flex-shrink-0'>
      {/* <Link className='log-out' to='/logout'>
        <span className='content'>Log out</span>
      </Link> */}
    </Container>
  )
}

export {Topbar}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin: 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  // background-color: #009efd;
  // color: #ffffff;
  // .content {
  //   color: #ffffff;
  // }
`
