import React, { useState, useEffect } from 'react'
import { PricingCell, PercentCell } from './PricingCell'
import styled from 'styled-components'
import Input from '../../../components/Input'
import Select from '../../../components/Select'
import { ButtonPrimary, CustomHeader } from '../../../components/CustomStyledComponents'
import { FaPlus, FaTrash } from 'react-icons/fa'
import { SelectContainer } from './StoreSettings'
import { storeSettingsApis } from '../../../../setup/apis/store/storeSettingsApis'
import {
  PricingMarkupItemType, PricingMarkupRowType,
  PricingMarkupType,
} from '../../../../setup/types/request-data-types/RequestDataTypes'
import { CurrencyType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { triggerAlert } from '../../../components/Alert'
import {toAbsoluteUrl} from "../../../../_metronic/helpers";

type Props = {
  storeId: number
  className: string
  data?: Array<object>
  currencyOptions: CurrencyType[]
  pricingPointOptions: CurrencyType[]
  pricingMarkup?: PricingMarkupType
}

export type MarkupRow = {
  startingPoint: {
    cash: number | ''
  }
  defaultVal: {
    cash: number | ''
    percent: number | ''
  }
  minVal: {
    cash: number | ''
    percent: number | ''
    relative: boolean
  }
  maxVal: {
    cash: number | ''
    percent: number | ''
    relative: boolean
  }
}

const blankMarkup: MarkupRow = {
  startingPoint: { cash: '' },
  defaultVal: { cash: '', percent: '' },
  minVal: { cash: '', percent: '', relative: false },
  maxVal: { cash: '', percent: '', relative: false },
}

const PricingSettingsV2: React.FC<Props> = ({
  className,
  storeId,
  currencyOptions,
  pricingPointOptions,
  pricingMarkup
}) => {
  const [markupRows, setMarkupRows] = useState<MarkupRow[]>([blankMarkup])
  const [inputCurrency, setInputCurrency] = useState<CurrencyType>(currencyOptions[0])
  const [tableCurrency, setTableCurrency] = useState<CurrencyType>(currencyOptions[0])
  const [inputPricingPoint, setInputPricingPoint] = useState<CurrencyType>(pricingPointOptions[0])
  const [loading, setLoading] = useState<boolean>(false)
  const [invalidRows, setInvalidRows] = useState<number[]>([])
  const [amazonFee, setAmazonFee] = useState(0);
  const [marketgapFee, setMarketgapFee] = useState(0);
  const [taxFee, setTaxFee] = useState(0);

  useEffect(() => {
    getData()
  }, [storeId])

  useEffect(() => {
    findInvalidRows()
  }, [markupRows])

  async function getData() {
    console.log(pricingMarkup);
    setAmazonFee(pricingMarkup?.defaultFees.amazonFee ?? 0);
    setMarketgapFee(pricingMarkup?.defaultFees.marketgapFee ?? 0);
    setTaxFee(pricingMarkup?.defaultFees.taxFee ?? 0);

    const mappedResponse: any[] = pricingMarkup?.rows?.map(
      (item: PricingMarkupRowType, i: number) => ({
        startingPoint: {
          cash: item?.from,
        },
        defaultVal: {
          cash: item?.markupFlat,
          percent: item?.markupPercentage,
        },
        minVal: {
          cash: item?.markupResultFlat,
          percent: item.markupResultPercent,
          relative: false,
        },
        maxVal: {
          percent: item.profitPercent,
          relative: false,
        },
      })
    ) ?? []
    setMarkupRows((prev) => (pricingMarkup?.rows?.length ? mappedResponse : [{ ...blankMarkup, startingPoint: { cash: 0 } }]))
    setInputCurrency(
        pricingMarkup?.table?.inputCurrencyCode
        ? {
          value: pricingMarkup?.table?.inputCurrencyCode,
          label: pricingMarkup?.table?.inputCurrencyCode,
        }
        : currencyOptions[0]
    )
    setTableCurrency(
        pricingMarkup?.table?.tableCurrencyCode
        ? {
          value: pricingMarkup?.table?.tableCurrencyCode,
          label: pricingMarkup?.table?.tableCurrencyCode,
        }
        : currencyOptions[0]
    )
    setInputPricingPoint(
        pricingMarkup?.table?.inputPricingPoint
        ? {
          value: pricingMarkup?.table?.inputPricingPoint,
          label: pricingMarkup?.table?.inputPricingPoint,
        }
        : pricingPointOptions[0]
    )
  }

  function _onChange({ target }: any, which: keyof MarkupRow, index: number) {
    setMarkupRows((prev) =>
      prev.map((item: MarkupRow, i: number) => _renderItem({ target }, which, index, i, item))
    )

    if (which === 'defaultVal') {

      if (target.name === 'cash') {
        setMarkupRows(prevData =>
            prevData.map((row, i) => {
              if (i === index) {
                return {
                  ...row,
                  minVal: {
                    ...row.minVal,
                    [target.name]: target.value,
                  },
                };
              }
              return row;
            }))
      }

      if (target.name === 'percent') {
        setMarkupRows(prevData =>
            prevData.map((row, i) => {
              console.log(prevData);


              if (i === index) {
                return {
                  ...row,
                  minVal: {
                    ...row.minVal,
                    // @ts-ignore
                    [target.name]: amazonFee + marketgapFee + taxFee + Number(target.value),
                  },
                  maxVal: {
                    ...row.maxVal,
                    // @ts-ignore
                    [target.name]: calculatePercentageForStage2(amazonFee, marketgapFee, Number(target.value), taxFee),
                  }
                };
              }
              return row;
            }))
      }
    }
  }

  function findInvalidRows() {
    let rows: any = {}
    markupRows.forEach((item, i) => {
      let idx = item.startingPoint.cash
      rows[idx] = {
        count: (rows?.[idx]?.count || 0) + 1,
        indexes: [...(rows?.[idx]?.indexes || []), i],
      }
    })
    setInvalidRows((prev) =>
      [].concat(
        ...Object.keys(rows)?.reduce((acc: any[], key) => {
          if (rows[key].count > 1) {
            return [...acc, ...(rows[key].indexes || [])]
          }
          return acc
        }, [])
      )
    )
  }

  function _renderItem(
    { target }: any,
    which: keyof MarkupRow,
    index: number,
    i: number,
    item: MarkupRow
  ) {
    if (i === index) {
      if (target.type === 'checkbox') {
        return { ...item, [which]: { ...item[which], [target.name]: target.checked } }
      }
      return {
        ...item,
        [which]: { ...item[which], [target.name]: target.value },
      }
    }
    return item
  }

  function onRowAdded(index: number) {
    setMarkupRows((prev) => [...prev.slice(0, index + 1), blankMarkup, ...prev.slice(index + 1)])
  }

  function onRowDeleted(index: number) {
    setMarkupRows((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
  }

  function calculatePercentageForStage2(amazonFee: number, marketgapFee: number, markupPercent: number, tax: number) {

    var totalPercent = amazonFee + marketgapFee + markupPercent + tax;

    return parseFloat((((100 / (100 -totalPercent )) * 100) - 100).toFixed(2));
  }

  function getTableRows() {
    return markupRows.map((item, index) => (
      <tr key={index}>
        <td>
          <div className='d-flex align-items-center'>
            <Strong>FROM</Strong>
            <TdContent className='d-flex justify-content-start'>
              <Input
                id={`cash${index}`}
                type='number'
                name='cash'
                value={item?.startingPoint?.cash}
                placeholder='0.00'
                readOnly={index === 0}
                onChange={(e) => {
                  _onChange(e, 'startingPoint', index)
                }}
                className={`form-control ${invalidRows.includes(index) ? `invalid` : ``}`}
                min={0}
              />
            </TdContent>
          </div>
          <div className='form-check form-check-sm form-check-custom form-check-solid relative'></div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <PricingCell
                currencyOptions={currencyOptions}
                onChange={_onChange}
                markup={item?.defaultVal}
                value='defaultVal'
                index={index}
                selectClassName='custom-select'
                inputClassName='custom-input'
                cashOnly={true}
                hidePlus={true}
            />
          </div>
          <div className='form-check form-check-sm form-check-custom form-check-solid relative'></div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <PricingCell
              currencyOptions={currencyOptions}
              onChange={_onChange}
              markup={item?.defaultVal}
              value='defaultVal'
              index={index}
              selectClassName='custom-select'
              inputClassName='custom-input'
              percentOnly={true}
              hidePlus={true}
            />
          </div>
          <div className='form-check form-check-sm form-check-custom form-check-solid relative'></div>
        </td>
        <td >
          <div className='d-flex align-items-center'>
            <PricingCell
                currencyOptions={currencyOptions}
                onChange={_onChange}
                markup={item.maxVal}
                value='minVal'
                index={index}
                selectClassName='custom-select'
                inputClassName='custom-input'
                disabled={true}
                percentOnly={true}
                hidePlus={true}
            />
            {/*<div>{calculatePercentage(amazonFee, marketgapFee, taxFee)}</div>*/}
          </div>
          <div className='form-check form-check-sm form-check-custom form-check-solid relative'></div>
        </td>
        <td className='pe-1'>
          <div className='d-flex align-items-center'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={(e) => onRowAdded(index)}
            >
              <FaPlus color='#a1a5b7' />
            </button>
            <button
              className={`btn btn-icon  btn-bg-light btn-active-color-danger btn-sm ${index === 0 && `invisible`
                }`}
              onClick={() => onRowDeleted(index)}
              style={{ marginLeft: '20px' }}
            >
              <FaTrash color='#a1a5b7' />
            </button>
          </div>
          <div className='form-check form-check-sm form-check-custom form-check-solid relative'></div>
        </td>
      </tr>
    ))
  }

  async function applyChanges() {
    try {
      setLoading(true)
      const requestDataItems: any = markupRows.map((item) => ({
        from: +item?.startingPoint?.cash || 0,
        markupFlat: +item?.defaultVal?.cash || 0,
        markupPercentage: +item?.defaultVal?.percent || 0,
        markupResultFlat: +item?.minVal?.cash || 0,
        markupResultPercent: +item?.minVal?.percent || 0,
        profitPercent: +item?.maxVal?.percent|| 0
      }))
      const requestData = {
        rows: requestDataItems,
        table: {
          inputCurrencyCode: inputCurrency.value,
          tableCurrencyCode: tableCurrency.value,
          inputPricingPoint: inputPricingPoint.value
        },
        defaultFees: {
          amazonFee: amazonFee,
          marketGapFee: marketgapFee,
          taxFee: taxFee
        },
        effectiveRate: 0,
        currency: inputCurrency?.value,
      };

      const response = await storeSettingsApis.updatePricingSettings(storeId, requestData)
      if (response?.status === 200) {
        triggerAlert('Changes saved successfully')
      }
    } catch (e) {
      console.log('error', e)
      triggerAlert('Something went wrong', 'error')
    } finally {
      setLoading(false)
    }
  }

  function isInValid() {
    const initialRow = markupRows[0]
    const invalid = markupRows.findIndex(item => item.startingPoint.cash === '') !== -1 || Object.values(initialRow).
      every(value => (value.cash === 0 || value.cash === ''))
    if (!markupRows.length || loading || invalidRows.length || invalid) return true
  }

  useEffect(() => {
    setMarkupRows(prevData =>
        prevData.map((row, i) => {
          let resultValue = amazonFee + marketgapFee + Number(row.defaultVal.percent) + taxFee;

          return {
            ...row,
            minVal: {
              ...row.minVal,
              // @ts-ignore
              percent: resultValue
            },
            maxVal: {
              ...row.maxVal,
              // @ts-ignore
              percent: calculatePercentageForStage2(amazonFee, marketgapFee, Number(row.defaultVal.percent), taxFee)
            }
          };
        }))
  }, [marketgapFee, amazonFee, taxFee]);

  return (
    <Container className={`card ${className}`}>
      {/* begin::Header */}
      <CustomHeader>Pricing Markup Settings</CustomHeader>
      {/* end::Header */}
      {/* begin::Body */}
      {/*<SelectContainer className='currency-select'>*/}
      {/*  <label className='form-label fw-bold'>Select Input Currency</label>*/}
      {/*  <Select*/}
      {/*    name='layout-builder[layout][header][width]'*/}
      {/*    value={inputCurrency}*/}
      {/*    onChange={(option) => setInputCurrency(option)}*/}
      {/*    options={currencyOptions}*/}
      {/*    withRs*/}
      {/*    placeholder='Select currency...'*/}
      {/*  />*/}
      {/*</SelectContainer>*/}

      <SelectContainer className='currency-select'>
        <label className='form-label fw-bold'>Default Markup</label>

        <div className='default-markup-fee'>
          <div className='default-markup-fee-item'>
            <div>Amazon Fee:</div>
            <div>
              <Input
                id={'amazon-fee-input'}
                type='number'
                name='amazonFee'
                value={amazonFee}
                placeholder='0'
                readOnly={false}
                onChange={(e) => setAmazonFee(e ? parseInt(e.target.value) : 0)}
                className='form-control'
                min={0}
              />
            </div>
          </div>
          <div className='default-markup-fee-item'>
            <div>Marketgap Fee:</div>
            <div>
              <Input
                id={'marketgap-fee-input'}
                type='number'
                name='marketgapFee'
                value={marketgapFee}
                placeholder='0'
                readOnly={false}
                onChange={(e) => {
                    setMarketgapFee(e ? parseInt(e.target.value) : 0);
                }}
                className='form-control input-group'
                min={0}
              />
            </div>
          </div>
          {/*<div className='default-markup-fee-item'>*/}
          {/*  <div>Effective Rate:</div>*/}
          {/*  <div className='pt-3 pl-3'> {calculatePercentage(amazonFee, marketgapFee, taxFee)}%</div>*/}
          {/*</div>*/}
          <div className='default-markup-fee-item'>
            <div>Tax Fee:</div>
            <div>
              <Input
                  id={'tax-fee-input'}
                  type='number'
                  name='taxFee'
                  value={taxFee}
                  placeholder='0'
                  readOnly={false}
                  onChange={(e) => {
                    setTaxFee(e ? parseInt(e.target.value) : 0);
                  }}
                  className='form-control input-group'
                  min={0}
              />
            </div>
          </div>
          <div className='default-markup-fee-item narrow'>
            <div>Total:</div>
            <div className='pt-3 pl-3'>{amazonFee + marketgapFee + taxFee}%</div>
          </div>
        </div>
      </SelectContainer>
      <div className='card-body py-3'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='info-text'>
            <img src={toAbsoluteUrl('/media/svg/misc/info.svg')} className='mb-1 me-3' />
            <div>
              <b>Pricing Rules:</b>
              <br />
              Rules will be evaluated top to bottom and stops upon the first match.
              <br />
              From is <b>Inclusive</b> while To is <b>Exclusive</b>.
            </div>
          </div>
        </div>
        <div className='table-responsive table-wrapper'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead className=' bg-white'>
              <tr className='fw-bolder text-muted' id={'PricingMarkup'}>
                <th className='w-175px'>Purchase Price + Shipping Pricing</th>
                <th className='min-w-100px'>Flat Profit Amount (USD)</th>
                <th className='min-w-120px'>Profit % of the Sale price</th>
                <th className='min-w-120px'>Effective Converted Percentage</th>
                <th className='min-w-50px'>Actions</th>
              </tr>
            </thead>
            <tbody>{getTableRows()}</tbody>
          </table>
        </div>
      </div>
      <hr className='custom-hr' />
      <div className='apply-button'>
        <ButtonPrimary onClick={applyChanges} disabled={isInValid()}>
          Apply Changes
        </ButtonPrimary>
      </div>
    </Container>
  )
}

export { PricingSettingsV2 }

const Container = styled.div`
  .custom-hr {
    margin: 0;
  }

  .currency-select {
    margin: 0 0 24px 24px;
    
    .default-markup-fee {
      display: flex;
    }
    
    label {
      
    }
    
    .default-markup-fee-item {
      display: flex;
      flex-direction: column;
      margin-right: 25px;
      width: 100px;
      &.narrow {
        width: 49px;
      }
      
      input  {
        margin-top: 5px;
        width: 85%;
        height: 29px;
      }
    }
  }

  .relative {
    margin-top: 10px;
  }

  .to-left {
    margin-left: 5px;
    font-size: 13px;
    font-weight: 500;
    color: #a1a5bd;
  }

  .table-wrapper {
    // height: 43vh;
    // overflow: auto;
  }

  thead {
    z-index: 1;
  }

  .custom-select {
    background-color: #d9f1fe;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: none;
    :focus {
      outline: none;
    }
  }

  .custom-input {
    background-color: #d9f1fe;
    border: none;
  }

  .apply-button {
    margin: 24px;
  }

  .info-text {
    border-radius: 8px;
    background-color: #eff2f5;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 12px;
    padding: 12px;
  }
`

const TdContent = styled.div`
  .invalid {
    color: tomato;
  }

  input {
    font-size: 14px;
    padding: 3px;
  }

  select {
    :focus {
      outline: none;
    }
    border: none;
  }
`

const Strong = styled.strong`
  margin-inline: 3px;
  font-size: 12px;
`
