import { FC } from 'react';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { ButtonTertiarry } from '../../components/CustomStyledComponents'
import { useQuery } from '../../utils/customHooks'

type Props = {
  params?: any
}

const SuccessfullyCreatedStore: FC<Props> = ({ params }) => {
  const history = useHistory<any>()
  const storeId = useQuery().get('storeid')

  function goToStoreSettings() {
    history.push('/stores/store-settings', { storeId })
  }

  return (
    <Container>
      <div className='header-info'>Your Store has been created</div>
      <div>
        <img src={toAbsoluteUrl('/media/downloaded-images/successfully-created.png')} width={180} />
      </div>
      <div className='footer-info'>
        <span>Now you can configure store settings, import</span>
        <span>products, and benefit from selling your goods.</span>
      </div>
      <div className='footer-buttons'>
        <ButtonTertiarry
          onClick={goToStoreSettings}
          paddingy={10}
          fontSize={18}
          fontWeight={500}
          grow={1}
          className='w-100'
        >
          Store Settings
        </ButtonTertiarry>
        <ButtonTertiarry
          onClick={() => history.push('/stores/store-list')}
          paddingy={10}
          fontSize={18}
          fontWeight={500}
          className='ms-5 w-100'
          grow={1}
        >
          Store List
        </ButtonTertiarry>
      </div>
    </Container>
  )
}

export { SuccessfullyCreatedStore }

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px;
  min-height: 512px;
  align-items: center;
  width: 480px;
  background-color: #f5f8fa;
  border: 1px solid #cdcfda;
  box-sizing: border-box;
  box-shadow: -4px -4px 12px rgba(120, 146, 165, 0.05), 4px 4px 12px rgba(120, 146, 165, 0.05);
  border-radius: 24px;

  .header-info {
    white-space: nowrap;
    font-size: 26px;
    font-weight: 600;
  }

  .footer-info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
  }

  .footer-buttons {
    width: 100%;
    display: flex;
  }
`
