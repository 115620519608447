import { FC, useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { initialBlackList, initialWhiteList, ProductFilters } from './WhiteBlackListing'
import { FaChevronRight } from 'react-icons/fa'
import { ButtonSecondary, CustomHeader } from '../../../components/CustomStyledComponents'
import { SelectContainer } from '../../stores/store-settings/StoreSettings'
import Select from '../../../components/Select'
import { useSelector } from 'react-redux'
import { activeStoreSelector } from '../../../modules/selectors/reduxSelectors'
import { StoreType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { importProductsApis } from '../../../../setup/apis/product/importProductsApis'
import { triggerAlert } from '../../../components/Alert'
type Props = {}

export type Store = {
  value: string
  label: string
}

const StoreSelection: FC<Props> = () => {
  const history = useHistory<ProductFilters>()
  const state = history.location.state
  const storeOptions = useSelector(activeStoreSelector)
  const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])
  const [initSelectedStore, setInitSelectedStore] = useState<StoreType>(storeOptions[0])

  useEffect(() => {
    if (history.location.state?.store) {
      setSelectedStore(state.store)
      setInitSelectedStore(state.store)
    }
  }, [])

  async function nextStep() {
    if (!selectedStore?.storeId) {
      triggerAlert('First choose store', 'error')
      return
    }
    const exists = await importProductsApis.checkExistingFilter(selectedStore.storeId)
    if (!exists) {
      await importProductsApis.addFilter(selectedStore.storeId, 'Default Filter')
    }
    history.push('/products/white-listing', {
      store: selectedStore,
      whiteList:
        state?.whiteList && selectedStore.storeId === initSelectedStore.storeId
          ? state?.whiteList
          : initialWhiteList,
      blackList:
        state?.blackList && selectedStore.storeId === initSelectedStore.storeId
          ? state?.blackList
          : initialBlackList,
      products: state?.products,
    })
  }

  return (
    <Container className='card'>
      <div className='d-flex align-items-center justify-content-between'>
        <CustomHeader>Step 1: Choose a store</CustomHeader>
        <ButtonSecondary className='button' onClick={nextStep} disabled={!selectedStore?.storeId}>
          Next Step <FaChevronRight />
        </ButtonSecondary>
      </div>
      <hr className='m-0' />
      <div className='body-container'>
        <SelectContainer className='store-select'>
          <label className='form-label fw-bold'>Select Store</label>
          <Select
            name='layout-builder[layout][header][width]'
            value={selectedStore}
            onChange={(option) => setSelectedStore(option)}
            options={storeOptions}
            withRs
          />
        </SelectContainer>
        <div className='image'>
          <img src={toAbsoluteUrl('/media/downloaded-images/Steps.png')} width={300} />
        </div>
      </div>
    </Container>
  )
}

export { StoreSelection }

const Container = styled.div`
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  border-radius: 8px;
  min-width: 700px;

  .body-container {
    display: flex;
    padding: 24px;
    justify-content: space-between;
  }

  .button {
    margin: 24px;
  }

  .store-select {
    margin: 24px;
  }

  .image {
    margin: 24px 60px 48px 72px;
  }
`
