import { TabTypes } from '../utils/types'

interface ITab {
  title: string
  value: TabTypes
}

const Tabs = ({
  tabData,
  tab,
  setTab,
}: {
  tabData: ITab[]
  tab: string
  setTab: (val: TabTypes) => void
}) => {
  return (
    <>
      <div className='d-flex tab-container'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          {tabData.map((item) => (
            <li
              key={item.value}
              className='nav-item  custom-nav cursor-pointer'
              onClick={() => setTab(item.value)}
            >
              <span
                className={`nav-link text-active-primary me-6 ${tab === item.value && 'active'}`}
              >
                {item.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <hr className='mt-0 mb-8' />
    </>
  )
}

export default Tabs
