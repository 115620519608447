import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../redux/AuthCRUD'
import styled from 'styled-components'
import {accountApis} from '../../../../setup/apis/account/accountApis'
import {ForgotPasswordType} from '../../../../setup/types/request-data-types/RequestDataTypes'
import {triggerAlert} from '../../../components/Alert'
import {ButtonPrimary, ButtonTertiarry} from '../../../components/CustomStyledComponents'
import Countdown, {zeroPad} from 'react-countdown'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [confirmEmail, setConfirmEmail] = useState<boolean>(false)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      requestPassword(values)
    },
  })
  const [wait, setWait] = useState<number>(0)

  useEffect(() => {
    const duration = sessionStorage.getItem('wait')
    if (duration && +duration) {
      setConfirmEmail(true)
      setWait(+duration)
    }

    return () => {
      console.log(wait)
      sessionStorage.setItem('wait', `${wait || 0}`)
    }
  }, [])

  async function requestPassword(values: ForgotPasswordType) {
    try {
      setLoading(true)
      const response = confirmEmail
        ? await accountApis.sendEmailConfirmation(values)
        : await accountApis.forgotPassword(values)
      if (response?.status === 200) {
        triggerAlert(
          'Check your email please. The link to change your password has been sent to your email.'
        )
        setWait(180000)
      }
    } catch (e: any) {
      if (e?.response?.status === 400) {
        setConfirmEmail(true)
        triggerAlert(
          `${e?.response?.data?.errors?.map((item: any) => `\n${item?.description}`)}`.replaceAll(
            ',',
            ''
          ),
          'error'
        )
      } else if (e?.response?.status === 404) {
        const message: any = e?.response?.data?.Message
        triggerAlert(`${message}`, 'error')
      } else {
        triggerAlert('Something went wrong', 'error')
      }
    } finally {
      setLoading(false)
    }
  }

  const Completionist = () => <span>You are good to go!</span>
  const renderer = ({hours, minutes, seconds, completed}: any) => {
    if (completed) {
      setWait(0)
      return <Completionist />
    } else {
      return (
        <span className='duration-information'>
          You can resend confirmation email after {minutes}:{zeroPad(seconds)}
        </span>
      )
    }
  }
  return (
    <Container>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{`${
            confirmEmail ? 'Confirm email' : 'Forgot Password ?'
          }`}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          {confirmEmail ? (
            <div className='text-gray-400 fw-bold fs-4'>
              Confirmation link will be sent to your email.
            </div>
          ) : null}
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder='Insert your email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'input-value',
              {'input-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {wait ? <Countdown date={Date.now() + 180000} renderer={renderer} /> : null}
        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <ButtonPrimary
            type='submit'
            className='me-8'
            disabled={formik.errors.email || loading || wait}
          >
            {
              <span className='indicator-label'>{`${
                confirmEmail ? 'Confirm email' : 'Submit'
              }`}</span>
            }
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </ButtonPrimary>
          <Link to='/auth/login'>
            <ButtonTertiarry
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              fontSize={16}
              paddingy={10}
              paddingx={20}
              fontWeight={600}
              // className='btn btn-lg btn-disabled fw-bolder'
            >
              Cancel
            </ButtonTertiarry>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </Container>
  )
}

const Container = styled.div`
  .btn-disabled {
    background-color: #f1faff;
    color: #009ef7;
  }

  .btn-disabled: hover {
    background-color: #66c5fa;
    color: #ffffff;
  }

  .input-value {
    display: block;
    width: 100%;
    border: 1px solid #cdcfda;
    border-radius: 8px;
    padding: 11px;
    font-size: 14px;
    font-weight: 500;
    color: #3f4254;

    ::-ms-reveal {
      display: none;
    }
  }

  .input-value:focus,
  .input-invalid:focus {
    outline: none;
    box-shadow: 0 0 1px 3px #c0e7fd;
    border: 1px solid #cdcfda;
  }

  .input-invalid {
    border: 1px solid tomato;
  }

  .duration-information {
    display: block;
    font-size: 14px;
    text-align: center;
    padding: 24px;
  }
`
