import React, { FC } from 'react';

type Props = {
    title?: string;
    content?: string;
};

export const Integration: FC<Props> = ({ title, content }) => {
    return (
        // <!-- Integration section -->
            <section className="bg-white">
                <div className="mx-auto w-5/6 bg-slate-50 rounded-[25px] max-w-[1700px]">
                    <h2 className="pt-20 text-center text-[35px] font-bold font-['Inter'] leading-9 lg:leading-[43.05px]">
                        Integration</h2>
                    <p
                        className="mt-[15px] mx-auto max-w-xs md:max-w-md lg:max-w-none text-center text-slate-500 text-lg font-normal font-['Inter'] leading-6 lg:leading-7">
                        MarketGap integrates directly with the following Amazon Marketplaces
                        </p>

                    {/* <!-- Regions grid --> */}
                    <div
                        className="mx-20 sm:mx-[103px] grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-[90px] mt-[65px] pb-[81px]">
                        {/* <!-- Region card --> */}
                        <div className="">
                            <h3 className="text-zinc-900 text-[25px] font-bold font-['Inter'] leading-relaxed">Europe</h3>
                            {/* <!-- Countries list--> */}
                            <div className="mt-[45px] flex flex-col gap-[24px] items-start justify-start">
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/United-Kingdom.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">
                                        Amazon.co.uk</p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Germany.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.de
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Spain.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.es
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/France.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.fr
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Italy.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.it
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Netherlands.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.nl
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Poland.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.pl
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Sweden.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.se
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Turkey.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">
                                        Amazon.com.tr</p>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Region card --> */}
                        <div className="">
                            <h3 className="text-zinc-900 text-[25px] font-bold font-['Inter'] leading-relaxed">Asia-Pacific
                            </h3>
                            {/* <!-- Countries list--> */}
                            <div className="mt-[45px] flex flex-col gap-[24px] items-start justify-start">
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Japan.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">
                                        Amazon.co.jp</p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/India.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.in
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Australia.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">
                                        Amazon.com.au</p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Singapore.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.sg
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Region card --> */}
                        <div className="">
                            <h3 className="text-zinc-900 text-[25px] font-bold font-['Inter'] leading-relaxed">Middle East
                            </h3>
                            {/* <!-- Countries list--> */}
                            <div className="mt-[45px] flex flex-col gap-[24px] items-start justify-start">
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/United-Arab-Emirates.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.ae
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Egypt.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.eg
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Saudi-Arabia.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.sa
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Region card --> */}
                        <div className="">
                            <h3 className="text-zinc-900 text-[25px] font-bold font-['Inter'] leading-relaxed">Americas</h3>
                            {/* <!-- Countries list--> */}
                            <div className="mt-[45px] flex flex-col gap-[24px] items-start justify-start">
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/United_States.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.com
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Canada.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">Amazon.ca
                                    </p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Mexico.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">
                                        Amazon.com.mx</p>
                                </div>
                                <div className="inline-flex justify-between items-center gap-[15px]"><img className=""
                                        src="./assets/img/flags/Brazil.svg" />
                                    <p className="text-slate-800 text-lg font-bold font-['Inter'] leading-normal">
                                        Amazon.com.br</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    );
};