export function getFileSizeInKB(size: number) {
  return Math.floor((size / 1024) * 100) / 100
}

export function formatMoney(amount: number, currency: string) {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: currency,
  }).format(amount)
}

export function getWhiteBlackListType(value: string, whiteList: string[], blackList: string[]) {
  if (whiteList.includes(value)) return 'allowed'
  if (blackList.includes(value)) return 'blocked'
  return 'default'
}

export function filterBySearchValue(el: string, searchValue: string) {
  return el.toLowerCase().includes(searchValue.toLowerCase())
}

export function pluralize(str: string) {
  if (str.endsWith('y')) return `${str.slice(0, -1)}ies`
  return ['s', 'sh', 'ch', 'x', 'z'].some((char) => str.endsWith(char)) ? `${str}es` : `${str}s`
}
