export const saveToSessionStorage = (name: string, obj: Object) => {
    try {
        const data = JSON.stringify(obj)
        sessionStorage.setItem(name, data)
    } catch (e) {
        console.log('error', e)
    }
}

export const loadFromSessionStorage = (name: string) => {
    try {
        const data = sessionStorage.getItem(name) || ''
        return JSON.parse(data)
    } catch (e) {
        console.log('error', e)
        return undefined
    }
}
