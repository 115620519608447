import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import OrderList from './OrderList'
import { OrderDetails } from './OrderDetails'

const orderBreadCrumbs: Array<PageLink> = [
  {
    title: 'Orders',
    path: '/orders/orderlist',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Orders',
    path: '/orders/order-details/:id',
    isSeparator: true,
    isActive: false,
  },
]

const OrderPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/orders/order-list'>
          <PageTitle breadcrumbs={orderBreadCrumbs}>Order List</PageTitle>
          <OrderList />
        </Route>
        <Route path='/orders/order-details/:id'>
          <PageTitle breadcrumbs={orderBreadCrumbs}>Order Details</PageTitle>
          <OrderDetails />
        </Route>
      </Switch>
    </>
  )
}

export default OrderPage
