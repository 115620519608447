import { FC } from 'react';
import { FaChevronLeft } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

type Props = {
  params?: any
}

const PaymentFailed: FC<Props> = ({ params }) => {
  const history = useHistory<any>()

  function goBack() {
    history.push('/settings/account-settings')
  }

  return (
    <Container>
      <div className='header-info'>Something went wrong...</div>
      <div className='image'>
        <img src={toAbsoluteUrl('/media/downloaded-images/failed-payment.png')} width={250} />
      </div>
      <div className='footer-info'>
        <span className='mt-2 fs-4 custom-link' onClick={goBack}>
          <span>
            <FaChevronLeft /> Go back
          </span>
        </span>
      </div>
    </Container>
  )
}

export { PaymentFailed }

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 48px;
  min-height: 512px;
  align-items: center;
  width: 480px;
  background-color: #f5f8fa;
  border: 1px solid #cdcfda;
  box-sizing: border-box;
  box-shadow: -4px -4px 12px rgba(120, 146, 165, 0.05), 4px 4px 12px rgba(120, 146, 165, 0.05);
  border-radius: 24px;

  .header-info {
    white-space: nowrap;
    font-size: 26px;
    font-weight: 600;
  }

  .footer-info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
  }

  .footer-buttons {
    width: 100%;
    display: flex;
  }

  .custom-link {
    color: #009ef7;
    cursor: pointer;
  }
`
