/* eslint-disable react/jsx-no-target-blank */
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { logout } from '../../../../app/utils/authUtils'

export function AsideMenuMain() {
  return (
    <>
      {/*<AsideMenuItem*/}
      {/*  to='/dashboard'*/}
      {/*  icon='/media/icons/duotone/Design/PenAndRuller.svg'*/}
      {/*  title={'Dashboard/Reports'}*/}
      {/*  fontIcon='bi-app-indicator'*/}
      {/*/>*/}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/stores'
        title='Stores'
        fontIcon='bi-archive'
        icon='/media/icons/duotone/Shopping/Bag1.svg'
      >
        <AsideMenuItem to='/stores/store-list' title='Store List' hasBullet={true} isSub />
        {/* <AsideMenuItem to='/stores/create-store' title='New Store' hasBullet={true} isSub /> */}
        <AsideMenuItem to='/stores/store-settings' title='Store Settings' hasBullet={true} isSub />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/discover'
        title='Discover'
        icon='/media/icons/duotone/Home/Bulb1.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/discover/categories' title='Categories' hasBullet={true} isSub />
        <AsideMenuItem to='/discover/brands' title='Brands' hasBullet={true} isSub />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/inventory'
        title='Inventory'
        icon='/media/icons/duotone/Home/Book2.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/inventory/brands' title='Brands' hasBullet={true} isSub />
        <AsideMenuItem to='/inventory/categories' title='Categories' hasBullet={true} isSub />
        <AsideMenuItem to='/inventory/asins' title='ASINS' hasBullet={true} isSub />
        <AsideMenuItem
          to='/inventory/restricted-keywords'
          title='Restricted Keywords'
          hasBullet={true}
          isSub
        />
        <AsideMenuItem
          to='/inventory/restricted-phrases'
          title='Restricted Phrases '
          hasBullet={true}
          isSub
        />
        <AsideMenuItem
          to='/inventory/current-listing'
          title='Current Listing'
          hasBullet={true}
          isSub
        />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Products'
        icon='/media/icons/duotone/Clothes/Shirt.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem
          to='/products/store-selection'
          title='Import Products'
          hasBullet={true}
          isSub
        />
      </AsideMenuItemWithSub> */}

      {/* <AsideMenuItem
        icon='/media/icons/duotone/Text/Bullet-list.svg'
        to='/categories/categorylist'
        title='Categories'
        hasBullet={false}
      />
      <AsideMenuItem
        icon='/media/icons/duotone/Shopping/Price1.svg'
        to='/brands/brandlist'
        title='Brands'
        hasBullet={false}
      /> */}
      <AsideMenuItem
        icon='/media/icons/duotone/Shopping/Cart1.svg'
        to='/orders/order-list'
        title='Orders'
        hasBullet={false}
      />
      {/* <AsideMenuItem
        icon='/media/icons/duotone/General/Notifications1.svg'
        to='/notifications'
        title='Notifications'
        hasBullet={false}
        notificationCount={notifications?.count}
      /> */}
      {/* <AsideMenuItem
        icon='/media/icons/duotone/Communication/Call-1.svg'
        to='/contact-us'
        title='Contact Us'
        hasBullet={false}
      /> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Settings'
        icon='/media/icons/duotone/General/Settings-2.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem
          to='/settings/account-settings'
          title='Account Settings'
          hasBullet={true}
          isSub
        />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItem
        icon='/media/icons/duotone/General/Settings-2.svg'
        to='/settings/account-settings'
        title='Account Settings'
      /> */}
    </>
  )
}
