import { FC, useEffect, useRef, useState, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'


// new design components
 import './tailwind.css'
import { Header } from './sections/Header'
import { Hero } from './sections/Hero'
import { WhatIsMarketGap } from './sections/WhatIsMarketGap'
import { Features } from './sections/Features'
import { HowItWorks } from './sections/HowItWorks'
import { AdvancedFeatures } from './sections/AdvancedFeatures'
import { Pricing } from './sections/Pricing'
import { Integration } from './sections/Integration'
import { FAQ } from './sections/FAQ'
import { Footer } from './sections/Footer'
import { twj } from 'tw-to-css'
import {HeroDropshipping} from "./sections/HeroDropshipping";
import {HeroArbitrage} from "./sections/HeroArbitrage";



type Props = {}

const PublicPage: FC<Props> = () => {
  const pathName_arbitrage = '/Arbitrage';
  const pathName_dropshipping = '/Dropshipping';

  const history = useHistory()
  const homeRef = useRef<any>(null)
  const featuresRef = useRef<any>(null)
  const pricingRef = useRef<any>(null)

  const aboutUsRef = useRef<any>(null)
  const whatWeDoRef = useRef<any>(null)
  const faqRef = useRef<any>(null)

  const [scrollY, setScrollY] = useState<boolean>(false)

  useEffect(() => {
    history.listen(() => {
      window.scrollTo(0, 0)
    })
  }, [history])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  function handleScroll() {
    setScrollY(window.scrollY > 600)
  }

  const location = useLocation();

  let HeroComponent;
  switch (location.pathname) {
    case pathName_arbitrage:
      HeroComponent = <HeroArbitrage />;
      break;
    case pathName_dropshipping:
      HeroComponent = <HeroDropshipping />;
      break;
    default:
      HeroComponent = <Hero />;
  }

  function scrollTo(where: string) {
    const extraMargin = -60;

    switch (where) {
      case 'home':
        homeRef?.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case 'pricing':
        const pricingTop = pricingRef?.current?.offsetTop + extraMargin;
        window.scrollTo({ top: pricingTop, behavior: 'smooth' });
        // pricingRef?.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case 'faq':
        const faqTop = faqRef?.current?.offsetTop + extraMargin;
        window.scrollTo({ top: faqTop, behavior: 'smooth' });
        // faqRef?.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case 'features':
        const featuresTop = featuresRef?.current?.offsetTop + extraMargin;
        window.scrollTo({ top: featuresTop, behavior: 'smooth' });
        // featuresRef?.current?.scrollIntoView({ behavior: 'smooth' })
        break
    }
  }

  const handleHome = useCallback(() => scrollTo('home'), [])
  const handleFeatures = useCallback(() => scrollTo('features'), [])
  const handlePricing = useCallback(() => scrollTo('pricing'), [])
  const handleFAQ = useCallback(() => scrollTo('faq'), [])

  return (
    <Container>
      <style dangerouslySetInnerHTML={{__html: `
     *,
     ::before,
     ::after {
       box-sizing: border-box; /* 1 */
       border-width: 0; /* 2 */
       border-style: solid; /* 2 */
       border-color: #e5e7eb;
     }
     
     ::before,
     ::after {
       --tw-content: '';
     }
          
     html,
     :host {
      font-size: 16px !important; /* 1 */
       line-height: 1.5 !important; /* 1 */
       -webkit-text-size-adjust: 100%; /* 2 */
       -moz-tab-size: 4; /* 3 */
       tab-size: 4; /* 3 */
       font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
       -webkit-font-feature-settings: normal;
       font-feature-settings: normal;
       font-variation-settings: normal;
       -webkit-tap-highlight-color: transparent; /* 7 */
      }
     
    
     
     body {
       margin: 0; /* 1 */
       line-height: inherit; /* 2 */
       font-size: inherit !important; /* 2 */
       background-color: #fff !important;
     }
     
   
     
     hr {
       height: 0; /* 1 */
       color: inherit; /* 2 */
       border-top-width: 1px; /* 3 */
     }
     
    
     
     abbr:where([title]) {
       text-decoration: underline dotted;
     }
     
     
     
     h1,
     h2,
     h3,
     h4,
     h5,
     h6 {
       font-size: inherit;
       font-weight: inherit;
     }
     
     
     
     a {
       color: inherit;
       text-decoration: inherit;
     }
     
    
     
     b,
     strong {
       font-weight: bolder;
     }
     
     
     
     code,
     kbd,
     samp,
     pre {
       font-family: theme('fontFamily.mono', ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace); /* 1 */
       font-feature-settings: normal; /* 2 */
       font-variation-settings: normal; /* 3 */
       font-size: 1em; /* 4 */
     }
     
     small {
       font-size: 80%;
     }
     
     
     sub,
     sup {
       font-size: 75%;
       line-height: 0;
       position: relative;
       vertical-align: baseline;
     }
     
     sub {
       bottom: -0.25em;
     }
     
     sup {
       top: -0.5em;
     }
     
     
     
     table {
       text-indent: 0; /* 1 */
       border-color: inherit; /* 2 */
       border-collapse: collapse; /* 3 */
     }
     
     
     
     button,
     input,
     optgroup,
     select,
     textarea {
       font-family: inherit; /* 1 */
       font-feature-settings: inherit; /* 1 */
       font-variation-settings: inherit; /* 1 */
       font-size: 100%; /* 1 */
       font-weight: inherit; /* 1 */
       line-height: inherit; /* 1 */
       color: inherit; /* 1 */
       margin: 0; /* 2 */
       padding: 0; /* 3 */
     }
     
     
     
     button,
     select {
       text-transform: none;
     }
     
    
     
     button,
     [type='button'],
     [type='reset'],
     [type='submit'] {
       -webkit-appearance: button; /* 1 */
       background-color: transparent; /* 2 */
       background-image: none; /* 2 */
     }
     
     
     
     :-moz-focusring {
       outline: auto;
     }
     
     
     
     :-moz-ui-invalid {
       box-shadow: none;
     }
     
   
     
     progress {
       vertical-align: baseline;
     }
     
     
     
     ::-webkit-inner-spin-button,
     ::-webkit-outer-spin-button {
       height: auto;
     }
     
     [type='search'] {
       -webkit-appearance: textfield; /* 1 */
       outline-offset: -2px; /* 2 */
     }
     
     
     
     ::-webkit-search-decoration {
       -webkit-appearance: none;
     }
     
     
     
     ::-webkit-file-upload-button {
       -webkit-appearance: button; /* 1 */
       font: inherit; /* 2 */
     }
    
     
     summary {
       display: list-item;
     }
     
     
     
     blockquote,
     dl,
     dd,
     h1,
     h2,
     h3,
     h4,
     h5,
     h6,
     hr,
     figure,
     p,
     pre {
       margin: 0;
     }
     
     fieldset {
       margin: 0;
       padding: 0;
     }
     
     legend {
       padding: 0;
     }
     
     ol,
     ul,
     menu {
       list-style: none;
       margin: 0;
       padding: 0;
     }
     
    
     dialog {
       padding: 0;
     }
     
     
     
     textarea {
       resize: vertical;
     }
     
     
     input::placeholder,
     textarea::placeholder {
       opacity: 1; /* 1 */
       color: theme('colors.gray.400', #9ca3af); /* 2 */
     }
     
 
     button,
     [role="button"] {
       cursor: pointer;
     }
     
     
     :disabled {
       cursor: default;
     }
     
     
     
     img,
     svg,
     video,
     canvas,
     audio,
     iframe,
     embed,
     object {
       display: block; /* 1 */
       vertical-align: middle; /* 2 */
     }
     
    
     
     img,
     video {
       max-width: 100%;
       height: auto;
     }
     
     [hidden] {
       display: none;
     }

     
    `}} />
  
      {/* // start of new design  */}
      <div id="landingPage" style={twj(['overflow-x-clip'])} className="font-['Inter'] bg-[url('assets/img/Background.svg')] bg-right-top bg-no-repeat bg-[#F8FCFF] min-h-screen h-screen min-w-full">
        <Header
          homeHandler={handleHome}
          featuresHandler={handleFeatures}
          pricingHandler={handlePricing}
        />
        <main>
          <div ref={homeRef}>
            {HeroComponent}
          </div>
          <WhatIsMarketGap />
          <div ref={featuresRef}>
            <Features />
          </div>
          <HowItWorks />
          <AdvancedFeatures />
          <div ref={pricingRef}>
            <Pricing />
          </div>
          <Integration />
          <div ref={faqRef}>
            <FAQ />
          </div>
        </main>
        <Footer faqHandler={handleFAQ}/>
      </div>
      {/* // end of new design  */}
    </Container>
  )
}

export { PublicPage }

const Container = styled.div``

// const Container = styled.div`
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   position: relative;

//   .navigation-bar {
//     position: fixed;
//     width: 100%;
//     background-color: #1d1d25;
//     color: #ffffff;
//     z-index: 1;
//     display: flex;
//     justify-content: center;

//     &-container {
//       height: 80px;
//       width: 1400px;
//       padding-inline: 120px;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//     }
//   }

//   .logo {
//     font-size: 28px;
//     font-weight: 700;
//   }

//   .navigation-buttons {
//     font-size: 18px;
//     font-weight: 400;
//     color: #ffffffb2;
//     display: inline-block;
//     justify-content: space-between;
//     text-decoration: none;
//   }

//   .navigation-button {
//     margin: 0 18px;
//     cursor: pointer;
//     z-index: 99;

//     &:hover {
//       font-weight: 500;
//       color: #009ef7;
//     }
//   }

//   .arrow-up {
//     position: fixed;
//     bottom: 50px;
//     right: 50px;
//     z-index: 3;
//     cursor: pointer;
//     background-color: #009EF7;
//     opacity: 0.5;
//     animation: animation-scrolltop .4s ease-out .7;
//     width: 40px;
//     height: 40px;
//     border-radius: 8px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: #ffffff;

//     &:hover {
//       opacity: 1;
//     }
//   }

//   @media (max-width: 768px) {
//     .navigation-bar {
//       position: fixed;
//       width: 100%;

//       &-container {
//         padding-inline: 20px;
//       }
//     }

//     .home-container {
//       padding: 20px;
//     }

//     .navigation-buttons {
//       display: none;
//     }

//     .body-content {
//       display: flex;
//       flex-direction: column-reverse;

//       .title {
//         font-size: 60px;
//         margin-top: 30px;
//       }

//       .body-right {
//         display: flex;
//         justify-content: center;
//       }

//       img {
//         width: 60%;
//       }
//     }
//   }

//   @keyframes animation-scrolltop {
//     from {
//       margin-bottom: -15px
//     }
//     to {
//       margin-bottom: 0;
//     }
//   }
// `;
