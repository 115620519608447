import { useState } from 'react'

import { importProductsApis } from '../../setup/apis/product/importProductsApis'

const useAddFilter = (storeId: number, setShow: (val: boolean) => void, getFilters: any) => {
  const [isLoading, setIsLoading] = useState(false)

  async function addFilter(listType: string, filterType: string, value: string) {
    try {
      setIsLoading(true)

      const res = await importProductsApis.addStringFilter([
        {
          storeId,
          listType,
          filterType,
          value,
        },
      ])

      if (!!res) {
        console.log('asdres');
        setShow(false)
        getFilters()
      }
    } catch (err) {
      console.log(err)
    } finally {
      console.log('asd');
      getFilters();
      setIsLoading(false)
    }
  }

  return { isLoading, addFilter }
}

export default useAddFilter
