import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import AddStore from '../account-creation/AddStore'
import { OrderSummary } from '../registration/OrderSummary'
import { SubscriptionPlan } from '../registration/SubscriptionPlan'
import { AccountSettings } from './account-settings/AccountSettings'

const settingBreadCrumbs: Array<PageLink> = [
  {
    title: 'Settings',
    path: '/settings/account-settings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/settings/account-settings'>
          <PageTitle breadcrumbs={settingBreadCrumbs}>Account Settings</PageTitle>
          <AccountSettings />
        </Route>
        <Route path='/settings/subscription-plans'>
          <PageTitle breadcrumbs={settingBreadCrumbs}>Subscription Plans</PageTitle>
          <SubscriptionPlan />
        </Route>
        <Route path='/settings/order-summary'>
          <PageTitle breadcrumbs={settingBreadCrumbs}>Order Summary</PageTitle>
          <OrderSummary />
        </Route>
        <Route path='/settings/plan-edit'>
          <PageTitle breadcrumbs={settingBreadCrumbs}>Add Store</PageTitle>
          <AddStore token='' />
        </Route>
      </Switch>
    </>
  )
}

export default SettingPage
