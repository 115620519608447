import {createSlice} from '@reduxjs/toolkit'

const initialState = false

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggle: (state) => !state,
    close: () => false,
  },
})

export const {toggle, close} = modalSlice.actions
export default modalSlice.reducer
