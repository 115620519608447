import { FC } from 'react';
import styled from 'styled-components'
import { AccountType } from './AccountSettings'

type Props = {
  data: AccountType
  enableEditing: () => void
}

const AccountSettingsData: FC<Props> = ({ data, enableEditing }) => {
  return (
    <Container>
      <div className='left-side'>
        <div className='data-row'>
          <div className='data-item'>
            <span className='input-label'>First name</span>
            <input type='text' className='input-value' value={data?.firstName} readOnly />
          </div>
          <div className='data-item'>
            <span className='input-label'>Last name</span>
            <input type='text' className='input-value' value={data?.lastName} readOnly />
          </div>
        </div>
        <div className='data-row'>
          <div className='data-item'>
            <span className='input-label'>Email</span>
            <input type='text' className='input-value' value={data?.email} readOnly />
          </div>
          <div className='data-item'>
            <span className='input-label'>Password</span>
            <input type='password' className='input-value' value={data?.password} readOnly />
          </div>
        </div>
        <div className='data-row'>
          <div className='data-item'>
            <span className='input-label'>Username</span>
            <input type='text' className='input-value' value={data?.username} readOnly />
          </div>
          {/* <div className='data-item'>
            <span className='input-label'>Country</span>
            <input type='text' className='input-value' value={data?.countryName} readOnly />
          </div> */}
        </div>
      </div>
      <div className='right-side'>
        <div className='button' onClick={enableEditing}>
          <span>Reset password</span>
        </div>
      </div>
    </Container>
  )
}

export { AccountSettingsData }

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 32px 24px 32px;

  .data-row {
    padding: 12px 0;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .left-side {
    display: flex;
    flex-direction: column;
  }

  .right-side {
    display: flex;
    justify-content: space-between;
  }

  .data-item {
    display: flex;
    flex-direction: column;
    margin-right: 96px;
    width: 280px;
  }

  .label {
    font-size: 15px;
    font-weight: 700;
  }

  @media only screen and (max-width: 698px) {
    .label {
      width: 100px;
    }
  }

  .input-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .input-value {
    display: block;
    border: none;
    border-bottom: 1px solid #cdcfda;
    padding-block: 11px;
    font-size: 14px;
    font-weight: 500;
    color: #3f4254;
    cursor: default;
  }

  .input-value:focus {
    outline: none;
  }

  .button {
    border-radius: 5px;
    background-color: #e4e6ef;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-weight: 600;
    cursor: pointer;
  }

  .button:hover {
    background-color: #ececec;
  }

  .button:active {
    background-color: #cccccc;
  }

  .verified-data {
    padding: 2px 15px;
    margin-left: 24px;
    background-color: #50cd89;
    color: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
  }
`
