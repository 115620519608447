import {FC, useEffect, useState} from 'react'
import styled from 'styled-components'
import {ButtonPrimary, CustomHeader} from '../../../components/CustomStyledComponents'
import {AccountSettingsData} from './AccountSettingsData'
import {Subscriptions} from './Subscriptions'
import {AccountSettingsForm} from './AccountSettingsForm'
import {CountryType} from '../../../../setup/types/response-data-types/ResponseDataTypes'
import {useDispatch, useSelector} from 'react-redux'
import {accountApis} from '../../../../setup/apis/account/accountApis'
import {countrySelector, tokenSelector} from '../../../modules/selectors/reduxSelectors'
import {getUserId} from '../../../utils/authUtils'
import {storeApis} from '../../../../setup/apis/store/storeApis'
import {getStores} from '../../../modules/redux-toolkit/storeSlicer'

export type AccountType = {
  firstName: string
  lastName: string
  username: string
  email: string
  password: string
  newPassword: string
  currentPassword: string
  countryId: number
  countryName: string
}

const phoneRegExp = /^^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
export const phoneMask = ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]

export const initialData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '------',
  newPassword: '',
  currentPassword: '',
  countryId: -1,
  countryName: '',
  username: '',
}

const AccountSettings: FC = () => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [data, setData] = useState<AccountType>(initialData)
  const [editPassword, setEditPassword] = useState<boolean>(false)
  const countryOptions = useSelector(countrySelector)
  const [country, setCountry] = useState<CountryType>(countryOptions[0])
  const token = useSelector(tokenSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    _getStores()
    getData()
  }, [])

  useEffect(() => {
    if (countryOptions?.length) {
      setData(
        (prev) =>
          ({
            ...prev,
            countryName:
              countryOptions.find((item) => item.countryId === prev.countryId)?.label ||
              countryOptions?.[0]?.label,
          } || initialData)
      )
      setCountry(
        countryOptions.find((item) => item?.countryId === data?.countryId) || countryOptions[0]
      )
    }
  }, [countryOptions])

  async function _getStores() {
    const response = await storeApis.getAll()
    dispatch(getStores(response?.data) || [])
  }

  async function getData() {
    const response = await accountApis.getUserById(getUserId(token))
    setData(
      {
        ...response,
        countryName:
          countryOptions.find((item) => item.countryId === response.countryId)?.label ||
          countryOptions?.[0]?.label,
        password: initialData.password,
        currentPassword: '',
        newPassword: '',
      } || initialData
    )
    setCountry(
      countryOptions.find((item) => item?.countryId === response?.countryId) || countryOptions[0]
    )
  }

  function _resetPassword() {
    setEditMode((prev) => {
      return true
    })
    setEditPassword((prev) => !prev)
  }

  function _saveChanges(values: AccountType) {
    setData((prev) => ({...prev, ...values}))
  }

  function _manageEditMode() {
    setEditMode((prev) => {
      return !prev
    })
  }

  function _manageCountry(country: CountryType, values: AccountType) {
    setCountry((prev) => country)
    setData((prev) => ({...prev, countryId: values.countryId, countryName: values.countryName}))
  }

  function _manageEditPassword() {
    setEditPassword((prev) => !prev)
  }

  function _closeEditMode() {
    setEditMode((prev) => {
      return !prev
    })
    setEditPassword((prev) => false)
  }
  return (
    <ParentContainer>
      <Container className='card'>
        <div className='card-header align-items-center p-0'>
          <CustomHeader>Account Settings</CustomHeader>
          {!editMode && (
            <div className='header-buttons'>
              <ButtonPrimary
                onClick={() =>
                  setEditMode((prev) => {
                    return !prev
                  })
                }
              >
                Edit Profile
              </ButtonPrimary>
            </div>
          )}
        </div>
        <hr className='m-0' />
        {editMode ? (
          <AccountSettingsForm
            manageEditMode={_manageEditMode}
            data={data}
            saveChanges={_saveChanges}
            closeEditMode={_closeEditMode}
            manageCountry={_manageCountry}
            country={country}
            manageEditPassword={_manageEditPassword}
            editPassword={editPassword}
            countryOptions={countryOptions}
          />
        ) : (
          <AccountSettingsData data={data} enableEditing={() => _resetPassword()} />
        )}
      </Container>
      {/*<Subscriptions />*/}
    </ParentContainer>
  )
}

export {AccountSettings}

const ParentContainer = styled.div`
  color: #3f4254;
  min-width: 1200px;
  margin-bottom: 48px;
`

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);

  min-width: 910px;

  .header-buttons {
    padding: 0 24px;

    button {
      margin-left: 12px;
    }
  }
`

export const phoneCodeOptions = [
  {
    value: '+1',
    label: '+1',
  },
  {
    value: '+43',
    label: '+43',
  },
  {
    value: '+994',
    label: '+994',
  },
  {
    value: '+380',
    label: '+380',
  },
  {
    value: '+7',
    label: '+7',
  },
  {
    value: '+99',
    label: '+99',
  },
]
