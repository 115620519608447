import { FC } from 'react';
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const PaymentMethod: FC = () => {
  return (
    <Container>
      {/*<div className='method-label'>*/}
      {/*  <span>Payment Method</span>*/}
      {/*</div>*/}
      {/*<div className='method-body'>*/}
      {/*  <span>*/}
      {/*    <img src={toAbsoluteUrl(`/media/svg/misc/stripe.svg`)} className='mb-1 me-2' /> Stripe*/}
      {/*  </span>*/}
      {/*</div>*/}
    </Container>
  )
}

export { PaymentMethod }

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 36px;

  .method-label {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .method-body {
    padding: 12px;
    border-radius: 50px;
    background-color: #e6f5fe;
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
  }
`
