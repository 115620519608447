/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Keyword } from './WhiteBlackListing'

type Props = {
  className: string
  data?: Keyword[]
  title: string
  removeRow: (item: Keyword, title: string) => void
  filterType: string
}

const KeywordTable: FC<Props> = ({ className, data, title, removeRow, filterType }) => {
  function getTableRows() {
    return data?.map((item: Keyword, index: number) => (
      <Row key={index}>
        <div className='title'>
          <span className='text-dark fw-bolder  mb-1 ps-5 fs-6'>
            {title === 'asin' ? item.asin : item.title}
          </span>
        </div>
        <div className='num-of-items'>
          {/*<span className='text-dark fw-bold  mb-1 fs-6'>*/}
          {/*  {title === 'asin' ? item.title : `${item.numberOfItems} items were blacklisted`}*/}
          {/*</span>*/}
        </div>
        <div className='date'>
          {/*<span className='text-dark fw-bold  mb-1 fs-6'>{`updated ${moment(*/}
          {/*  new Date(item?.updateDate)*/}
          {/*).format('l')} ${moment(new Date(item?.updateDate)).format('LT')}`}</span>*/}
        </div>
        <div className='action'>
          <span
            className='btn fw-bolder btn-sm button-danger'
            onClick={() => removeRow(item, filterType)}
          >
            Undo
          </span>
        </div>
      </Row>
    ))
  }
  return <Container>{getTableRows()}</Container>
}

export { KeywordTable }

const Container = styled.div`
  max-height: 300px;
  overflow: auto;
  padding-right: 12px;

  //-------------scrollbar------------

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #e4e6ef;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #40b6f9;
  }

  :hover::-webkit-scrollbar-thumb {
    background-color: #40b6f9;
  }

  scrollbar-width: 6px;
  scrollbar-color: #40b6f9 #e4e6ef;
`

const Row = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
  background-color: #f0f4f8;
  border-radius: 5px;
  padding: 10px;
  .title {
    display: inline-block;
    margin: 0 0 0 20px;
    width: 241px;
  }

  :first-child {
    margin-top: 0;
  }

  .num-of-items {
    text-wrap: wrap;
    display: inline-block;
    width: 360px;
    margin-right: 40px;
  }

  .date {
    display: inline-block;
    border-radius: 8px;
    width: 300px;
  }

  .action {
    display: inline-block;
    border-radius: 8px;
    width: 100px;
  }

  .button-danger {
    color: #894848;
    background-color: #faeaea;
  }
`
