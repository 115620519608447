import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { StoreType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { ButtonPrimary, CustomHeader } from '../../../components/CustomStyledComponents'
import useGetStoreFilterSettings from '../../../hooks/useGetStoreFilterSettings'
import { activeStoreSelector } from '../../../modules/selectors/reduxSelectors'
import { FilterTypes, ListByTab } from '../../filters/importProductsFilters'
import { filterBySearchValue } from '../../../utils/helperUtils'
import InventoryTable from '../../../components/InventoryTable'
import RemoveButton from '../../../components/RemoveButton'
import AddListModal from '../../../components/AddListModal'
import StoreSelect from '../../../components/StoreSelect'
import useAddFilter from '../../../hooks/useAddFilter'
import SearchBar from '../../../components/SearchBar'
import { TabTypes } from '../../../utils/types'
import Tabs from '../../../components/Tabs'
import View from '../../../components/View'
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";

export interface Item {
  categoryId: string
  categoryName: string
  parentCategoryName: string
  parentCategoryId: string
  childCategoryCount: number
  productCount: number
  totalProductCount: number
  value: string
}

const Categories = () => {
  const storeOptions = useSelector(activeStoreSelector)

  const [show, setShow] = useState(false)
  const [tab, setTab] = useState<TabTypes>('white')
  const [selectedId, setSelectedId] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])
  const history = useHistory<any>()
  const params: { storeId: string } = useParams();

    const { isLoading, getCategories, category, setCategory } =
    useGetStoreFilterSettings<Item>(
      'categotyWhiteList',
      'categotyBlackList',
      selectedStore?.storeId,
      true
    )

  const { isLoading: addIsLoading, addFilter } = useAddFilter(
    selectedStore?.storeId,
    setShow,
      getCategories
  )

    useEffect(() => {
        if (storeOptions.length) {
            let selectedStoreId: number;
            if (params.storeId) {
                selectedStoreId = parseInt(params.storeId);
                sessionStorage.setItem('storeId', String(params.storeId));
            } else if (sessionStorage.getItem('storeId')) {
                selectedStoreId = parseInt(sessionStorage.getItem('storeId') ?? '0');
                history.push(`/inventory/categories/${storeOptions.find(item => item.storeId === selectedStoreId)?.storeId}`);
            } else {
                selectedStoreId = storeOptions[0].storeId;
            }
            const selectedStore = storeOptions.find(item => item.storeId === selectedStoreId) || storeOptions[0];
            setSelectedStore(selectedStore);
            history.push(`/inventory/categories/${selectedStore.storeId}`);
        }
    }, [storeOptions]);

    function handleStoreSelect(option: StoreType) {
        setSelectedStore(option);
        sessionStorage.setItem('storeId', String(option.storeId));
        history.push(`/inventory/categories/${option.storeId}`);
    }

  useEffect(() => {
    getCategories();
  }, [selectedStore?.storeId])

  function handleSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target

    setSearchValue(value)
  }

  const tableData = useMemo(() => {
    let list = tab === 'white' ? category["whiteList"] : category["blackList"];
    return list.filter((el) => filterBySearchValue(el.categoryName, searchValue))
      .map((item) => ({
        name: item.categoryName,
        path: item.parentCategoryName,
        button: (
          <RemoveButton<Item>
            selectedId={selectedId}
            value={item.categoryId}
            setWhiteList={(val) => {
                // @ts-ignore
                setCategory(prevCategory => ({
                    ...prevCategory,
                    whiteList: val
                }));
            }
            }
            setBlackList={(val) => {
                // @ts-ignore
                setCategory(prevCategory => ({
                    ...prevCategory,
                    blackList: val
                }));
                }
            }
            setSelectedId={setSelectedId}
            listType={ListByTab[tab]}
            storeId={selectedStore?.storeId}
            filterType={FilterTypes.Category}
            filterCallback={(list: Item[], value: string) =>
              list.filter((item) => item.categoryId !== value)
            }
          />
        ),
      }))
  }, [tab, category, searchValue])

  return (
    <div style={{paddingBlockEnd: '100px'}}>
      <CustomHeader marginl='0'>Category List</CustomHeader>

      <div className='d-flex justify-content-end'>
        <StoreSelect
          selectedStore={selectedStore}
          handleSelect={(option: StoreType) => handleStoreSelect(option)}
        />

        <ButtonPrimary
          onClick={() => setShow(true)}
          bgColor='#037FC5'
          paddingx={22}
          paddingy={12}
          fontSize={13}
          marginl={12}
        >
          Add to list
        </ButtonPrimary>
      </div>

      <Tabs
        tab={tab}
        setTab={setTab}
        tabData={[
          { title: 'Allowed', value: 'white' },
          { title: 'Blocked', value: 'black' },
        ]}
      />

      <SearchBar handleChange={handleSearchValueChange} page='Categories' />

      <InventoryTable tHeader={['Category', 'Path', '']} data={tableData} />

      <View isLoading={isLoading}>
        <AddListModal
          show={show}
          page='category'
          setShow={setShow}
          addFilter={addFilter}
          isLoading={addIsLoading}
          listType={ListByTab[tab]}
          selectedStore={selectedStore}
          filterType={FilterTypes.Category}
          handleStoreSelect={(option: StoreType) => setSelectedStore(option)}
        />
      </View>
    </div>
  )
}

export default Categories
