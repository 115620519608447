import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { StoreType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { ButtonPrimary, CustomHeader } from '../../../components/CustomStyledComponents'
import useGetStoreFilterSettings from '../../../hooks/useGetStoreFilterSettings'
import { activeStoreSelector } from '../../../modules/selectors/reduxSelectors'
import { FilterTypes, ListTypes } from '../../filters/importProductsFilters'
import { filterBySearchValue } from '../../../utils/helperUtils'
import InventoryTable from '../../../components/InventoryTable'
import AddListModal from '../../../components/AddListModal'
import RemoveButton from '../../../components/RemoveButton'
import StoreSelect from '../../../components/StoreSelect'
import useAddFilter from '../../../hooks/useAddFilter'
import SearchBar from '../../../components/SearchBar'
import Tabs from '../../../components/Tabs'
import View from '../../../components/View'
import {StyledDiv90} from "../Onboarding.styles";

type Props = {
    store: StoreType | undefined;
}

const OnboardingInventoryRestrictedKeywords = (props: Props) => {
    const storeOptions = useSelector(activeStoreSelector)

    const [show, setShow] = useState(false)
    const [tab, setTab] = useState<string>('black')
    const [selectedId, setSelectedId] = useState('')
    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedStore, setSelectedStore] = useState<StoreType>(props.store ? props.store : storeOptions[0])

    const { isLoading, getKeywords } = useGetStoreFilterSettings<string>(
        '',
        'titleWordBlacklist',
        selectedStore?.storeId
    )

    const { isLoading: addIsLoading, addFilter } = useAddFilter(
        selectedStore?.storeId,
        setShow,
        getKeywords
    )

    useEffect(() => {
        // if (selectedStore) getKeywords()
    }, [selectedStore?.storeId])

    function handleSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target

        setSearchValue(value)
    }

    // const tableData = keywords['blackList']
    //     .filter((el) => filterBySearchValue(el, searchValue))
    //     .map((item) => ({
    //         name: item,
    //         button: (
    //             <RemoveButton
    //                 value={item}
    //                 selectedId={selectedId}
    //                 listType={ListTypes.BlackList}
    //                 storeId={selectedStore?.storeId}
    //                 filterType={FilterTypes.TitleWord}
    //                 setSelectedId={setSelectedId}
    //                 setWhiteList={(val) => {
    //                     // @ts-ignore
    //                     setKeywords(prevCategory => ({
    //                         ...prevCategory,
    //                         whiteList: val
    //                     }));
    //                 }
    //                 }
    //                 setBlackList={(val) => {
    //                     // @ts-ignore
    //                     setKeywords(prevCategory => ({
    //                         ...prevCategory,
    //                         blackList: val
    //                     }));
    //                     }
    //                 }
    //             />
    //         ),
    //     }))

    return (
        <StyledDiv90>
            <CustomHeader marginl='0'>Restricted Keyword List</CustomHeader>

            {/*<div className='d-flex justify-content-end'>*/}
            {/*    <StoreSelect*/}
            {/*        selectedStore={selectedStore}*/}
            {/*        handleSelect={(option: StoreType) => setSelectedStore(option)}*/}
            {/*    />*/}

            {/*    <ButtonPrimary*/}
            {/*        onClick={() => setShow(true)}*/}
            {/*        bgColor='#037FC5'*/}
            {/*        paddingx={22}*/}
            {/*        paddingy={12}*/}
            {/*        fontSize={13}*/}
            {/*        marginl={12}*/}
            {/*    >*/}
            {/*        Add to list*/}
            {/*    </ButtonPrimary>*/}
            {/*</div>*/}

            <Tabs tabData={[{ title: 'Blocked', value: 'black' }]} tab={tab} setTab={setTab} />

            <SearchBar handleChange={handleSearchValueChange} page='Restricted Keywords' />

            <View isLoading={isLoading}>
                {/*<InventoryTable tHeader={['Restricted Keyword List', '']} data={tableData} />*/}
            </View>

            <AddListModal
                show={show}
                page='keyword'
                setShow={setShow}
                addFilter={addFilter}
                isLoading={addIsLoading}
                selectedStore={selectedStore}
                listType={ListTypes.BlackList}
                filterType={FilterTypes.TitleWord}
                handleStoreSelect={(option: StoreType) => setSelectedStore(option)}
            />
        </StyledDiv90>
    )
}

export default OnboardingInventoryRestrictedKeywords
