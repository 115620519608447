import React, {FC, useEffect, useState} from 'react';
import {Container, FullContainer, HalfContainer, StyledDiv} from './Onboarding.styles';
import {Button} from 'react-bootstrap-v5';
import {useSelector} from 'react-redux';
import {activeStoreSelector} from '../../modules/selectors/reduxSelectors';
import {CurrencyType, StoreType} from '../../../setup/types/response-data-types/ResponseDataTypes';
import {Store} from '../stores/Store';
import {OnboardingEnum} from '../../../onboarding.enum';
import {OnboardingStoreDetails} from './Store/OnboardingStoreDetails';
import {OnboardingStorePricing} from './Store/OnboardingStorePricing';
import {currencyApis} from '../../../setup/apis/currency/currencyApis';
import {pricingPointApis} from '../../../setup/apis/pricing-point/pricingPointApis';
import {OnboardingShippingSettings} from './Store/OnboardingStoreShippingSettings';
import {OnboardingAdditionalSettings} from './Store/OnboardingStoreAdditional';
import OnboardingCategoriesPage from './Categories/OnboardingCategories';
import OnboardingBrands from './Brands/OnboardingBrands';
import OnboardingInventoryBrands from './Inventory/OnboardingInventoryBrands';
import {useHistory, useParams} from 'react-router-dom';
import OnboardingInventoryCategories from './Inventory/OnboardingInventoryCategories';
import OnboardingInventoryAsins from "./Inventory/OnboardingInventoryAsins";
import OnboardingInventoryRestrictedKeywords from "./Inventory/OnboardingInventoryRestrictedKeywords";
import OnboardingInventoryRestrictedPhrases from "./Inventory/OnboardingInventoryPhrases";

type Props = {}

export const Onboarding: FC<Props> = () => {
    const [onboardingStep, setOnboardingStep] = useState(1);
    const [currencyOptions, setCurrencyOptions] = useState<CurrencyType[]>([]);
    const [pricingPointOptions, setPricingPointOptions] = useState<CurrencyType[]>([]);
    const storeOptions = useSelector(activeStoreSelector);
    const history = useHistory();
    const params: { storeId: string } = useParams();
    const [selectedStore, setSelectedStore] = useState<StoreType>();

    useEffect(() => {
        if (storeOptions.length) {
            if (params.storeId) {
                if (storeOptions.find(item => item.storeId === parseInt(params.storeId))) {
                    setSelectedStore(storeOptions.find(item => item.storeId === parseInt(params.storeId)))
                } else {
                }
            } else {
                setSelectedStore(storeOptions[0]);
                history.push(`/onboarding/${storeOptions[0].storeId}`);
            }
        }
    }, [storeOptions])

    const getCurrencies = async () => {
        const response: string[] = await currencyApis.getAll()
        setCurrencyOptions(response?.map((item) => ({value: item, label: item} || [])))
    }

    const getPricingPoints = async () => {
        const response: string[] = await pricingPointApis.getAll()
        setPricingPointOptions(response?.map((item) => ({value: item, label: item} || [])))
    }

    function getList() {
        if (!storeOptions.length) {
            return (
                <div className='no-stores text-nowrap'>Oops... It looks like you got no stores yet</div>
            )
        }
        return storeOptions?.map((store: StoreType, i: number) => (
            <Store key={i} data={store} className='card-xl-stretch mb-xl-8 store'/>
        ))
    }

    useEffect(() => {
        getCurrencies();
        getPricingPoints();
    }, []);


    return (
        <Container>
            <StyledDiv>
                <Button
                    onClick={() => {
                        if (onboardingStep > 1) {
                            setOnboardingStep(onboardingStep - 1);
                        }
                    }}
                >
                    {'<'}
                </Button>
                <span>
          {onboardingStep}/{Object.keys(OnboardingEnum).length / 2}
        </span>
                <Button
                    onClick={() => {
                        const maxStep = Object.keys(OnboardingEnum).length / 2;
                        if (onboardingStep < maxStep) {
                            setOnboardingStep(onboardingStep + 1);
                        }
                    }}
                >
                    {'>'}
                </Button>

            </StyledDiv>
            {onboardingStep === OnboardingEnum.STORES_LIST && (
                <div className='stores-wrapper'>{getList()}</div>
            )}

            {onboardingStep === OnboardingEnum.STORE_DETAILS && (
                <HalfContainer>
                    <OnboardingStoreDetails storeId={selectedStore?.storeId || storeOptions[0].storeId}/>
                </HalfContainer>
            )}

            {onboardingStep === OnboardingEnum.PRICING_MARKUP && (
                <OnboardingStorePricing
                    storeId={selectedStore?.storeId || storeOptions[0].storeId}
                    className='mb-5 mb-xl-8'
                    currencyOptions={currencyOptions}
                    pricingPointOptions={pricingPointOptions}
                />
            )}

            {onboardingStep === OnboardingEnum.SHIPPING_PRICING && (
                <OnboardingShippingSettings
                    storeId={selectedStore?.storeId || storeOptions[0].storeId}
                    className='mb-5 mb-xl-8'
                    currencyOptions={currencyOptions}
                    selectedStore={selectedStore || storeOptions[0]}
                />
            )}

            {onboardingStep === OnboardingEnum.CATEGORIES && (
                <OnboardingCategoriesPage/>
            )}

            {onboardingStep === OnboardingEnum.BRANDS && <OnboardingBrands/>}

            {onboardingStep === OnboardingEnum.INVENTORY_BRANDS && (
                <FullContainer>
                    <OnboardingInventoryBrands/>
                </FullContainer>
            )}

            {onboardingStep === OnboardingEnum.INVENTORY_CATEGORIES && (
                <FullContainer>
                    <OnboardingInventoryCategories/>
                </FullContainer>
            )}

            {onboardingStep === OnboardingEnum.INVENTORY_ASINS && (
                <FullContainer>
                    <OnboardingInventoryAsins store={selectedStore}/>
                </FullContainer>
            )}

            {onboardingStep === OnboardingEnum.INVENTORY_RESTRICTED_KEYWORDS && (
                <FullContainer>
                    <OnboardingInventoryRestrictedKeywords store={selectedStore}/>
                </FullContainer>
            )}

            {onboardingStep === OnboardingEnum.INVENTORY_PHRASES && (
                <FullContainer>
                    <OnboardingInventoryRestrictedPhrases store={selectedStore}/>
                </FullContainer>
            )}

        </Container>
    );
};
