import {FC, useEffect, useState} from 'react'
import styled from 'styled-components'
import {StoreInfo} from './StoreInfo'
import {ProductsListInfo} from './ProductsListInfo'
import {OrdersListInfo} from './OrdersListInfo'
import {ReportChart} from './ReportChart'
import {
  ProductInfoType,
  OrderInfoType,
  StoreType,
  ChartReportType,
} from '../../../setup/types/response-data-types/ResponseDataTypes'
import {useSelector} from 'react-redux'
import {activeStoreSelector} from '../../modules/selectors/reduxSelectors'
import {reportApis} from '../../../setup/apis/report/reportApis'

type Props = {}

const {day, week, month} = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
}

export type StoreInfoType = {
  selectedStore: StoreType
  numOfOrders: number
  totalSales: number
  topCategory: string
  selectedChart: string
}

const initialStoreInfo = {
  selectedStore: 'Skip Hop Store',
  numOfOrders: 0,
  totalSales: 0,
  topCategory: '',
  selectedChart: 'Sale',
}

const initialProductInfo = {
  totalAmountCount: 0,
  successImportedCount: 0,
  pendingImportedCount: 0,
  errorImportedCount: 0,
}

const initialOrderInfo = {
  totalAmountCount: 0,
  unknownCount: 0,
  pendingAvailabilityCount: 0,
  pendingCount: 0,
  unshippedCount: 0,
  partiallyShippedCount: 0,
  shippedCount: 0,
  canceledCount: 0,
  unfulfillableCount: 0,
  totalSalesCount: 0,
  topCategory: '',
}

const initialChartData = {
  dataCurrent: [],
  dataPrevious: [],
  labels: [],
  maxData: 0,
}

export type ChartDataType = {
  dataCurrent: number[]
  dataPrevious: number[]
  labels: string[]
  maxData: number
}

const Reports: FC = () => {
  const [tab, setTab] = useState<string>(day)
  const stores = useSelector(activeStoreSelector)
  const [chartData, setChartData] = useState<ChartDataType>(initialChartData)
  const [loading, setLoading] = useState<boolean>(false)
  const [storeInfo, setStoreInfo] = useState<StoreInfoType>({
    ...initialStoreInfo,
    selectedStore: stores[0],
  })
  const [productInfo, setProductInfo] = useState<ProductInfoType>(initialProductInfo)
  const [orderInfo, setOrderInfo] = useState<OrderInfoType>(initialOrderInfo)

  useEffect(() => {
    if (stores.length) {
      setStoreInfo((prev) => ({...prev, selectedStore: stores?.[0]}))
      getProductInfo(stores?.[0]?.storeId)
      getOrderInfo(stores?.[0]?.storeId)
      getChartReport(stores?.[0]?.storeId)
    }
  }, [stores.length])

  async function getProductInfo(storeId: number) {
    try {
      setLoading(true)
      const response = await reportApis?.getProductIfno(storeId)
      setProductInfo(response)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  async function getOrderInfo(storeId: number) {
    try {
      setLoading(true)
      const response = await reportApis?.getOrderIfno(storeId)
      setOrderInfo(response)
      setStoreInfo((prev) => ({
        ...prev,
        numOfOrders: response?.totalAmountCount,
        totalSales: response?.totalSalesCount,
        topCategory: response?.topCategory,
      }))
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  async function getChartReport(stId?: number, rType?: string, dType?: string) {
    try {
      setLoading(true)
      let dateType = dType ?? tab
      const response: ChartReportType = await reportApis?.getChartReport({
        storeId: stId ?? storeInfo?.selectedStore?.storeId,
        reportChartType: rType ?? storeInfo?.selectedChart,
        reportChartDateType: dateType,
      })
      let dataCurrent: number[],
        dataPrevious: number[],
        labels: string[] = []

      dataCurrent = response?.currentReport?.map((item) => item?.count)
      dataPrevious = response?.previousReport?.map((item) => item?.count)
      labels = response?.currentReport?.map((item) =>
        dateType === day
          ? item?.hour?.toString() || ''
          : dateType === week
          ? item?.weekDay || ''
          : new Date(item?.orderDate)?.getDate()?.toString() || ''
      )
      const maxData =
        dataCurrent?.concat(dataPrevious)?.reduce((acc: number, item: number) => {
          return Math.max(item, acc)
        }, 0) || 0
      setChartData((prev) => ({dataCurrent, dataPrevious, labels, maxData}))
    } catch (er) {
      console.log(er)
    } finally {
      setLoading(false)
    }
  }

  function _onTabChanged(tabName: string) {
    setTab((prev) => tabName)
    getChartReport(undefined, undefined, tabName)
  }

  function _onStoreSelect(store: StoreType) {
    setStoreInfo((prev) => ({...prev, selectedStore: store}))
    getProductInfo(store?.storeId)
    getOrderInfo(store?.storeId)
    getChartReport(store?.storeId)
  }

  function _onChartChange(chart: string) {
    setStoreInfo((prev) => ({...prev, selectedChart: chart}))
    getChartReport(undefined, chart)
  }

  return (
    <Container>
      <div className='d-flex overflow-auto h-45px tabs'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fw-bolder flex-nowrap justify-content-between'>
          <li className='nav-item' onClick={() => _onTabChanged(day)}>
            <span className={`nav-link custom-nav text-active-primary  ${tab === day && 'active'}`}>
              {day}
            </span>
          </li>
          <li className='nav-item' onClick={() => _onTabChanged(week)}>
            <span
              className={`nav-link custom-nav text-active-primary  ${tab === week && 'active'}`}
            >
              {week}
            </span>
          </li>
          <li className='nav-item' onClick={() => _onTabChanged(month)}>
            <span
              className={`nav-link custom-nav text-active-primary ${tab === month && 'active'}`}
            >
              {month}
            </span>
          </li>
        </ul>
      </div>
      <hr className='m-0' color='#CDCFDA' />
      <StoreInfo
        info={storeInfo}
        manageStoreSelection={_onStoreSelect}
        manageSelectedChart={_onChartChange}
      />
      <div className='chart-container'>
        <ReportChart chartData={chartData} dateType={tab} />
      </div>
      <div className='footer-info'>
        <ProductsListInfo info={productInfo} selectedStore={storeInfo?.selectedStore} />
        <OrdersListInfo info={orderInfo} selectedStore={storeInfo?.selectedStore} />
      </div>
    </Container>
  )
}

export {Reports}

const Container = styled.div`
  min-width: 1000px;
  margin-bottom: 48px;

  .custom-nav {
    font-size: 16px;
  }

  .chart-container {
    padding-top: 24px;
  }

  .footer-info {
    display: flex;
  }

  .tabs {
    cursor: pointer;
  }
`
