import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { TC } from './TermsAndConditions'

const termsandconditionsBreadCrumbs: Array<PageLink> = [
    {
        title: 'Terms & Conditions',
        path: '/terms-conditions',
        isSeparator: false,
        isActive: false,
    },
]

const TermsAndConditions: React.FC = () => {
    return (
        <>
            <Switch>
                <Route path='/terms-conditions'>
                    <PageTitle breadcrumbs={termsandconditionsBreadCrumbs}>Terms & Conditions</PageTitle>
                    <TC />
                </Route>
            </Switch>
        </>
    )
}

export default TermsAndConditions
