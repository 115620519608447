import { FC, useMemo } from 'react';
import { FaChevronLeft } from 'react-icons/fa'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ButtonSecondary, CustomHeader } from '../../components/CustomStyledComponents'
import { RegistrationType } from './SubscriptionPlan'

const OrderSummary: FC = () => {
  const history = useHistory<RegistrationType>()
  const state = history.location.state

  const plan = useMemo(() => {
    return state?.plan
  }, [])

  function _previousPage() {
    const fromSettings = history.location.pathname.includes('settings')
    history.push(fromSettings ? '/settings/subscription-plans' : '/registration/account-details', {
      plan: state?.plan,
      details: state?.details,
      card: state?.card,
    })
  }

  return (
    <Container className='card'>
      <div className='card'>
        <div className='card-header align-items-center p-0'>
          <CustomHeader>Order Summary</CustomHeader>
          <div className='header-buttons'>
            <ButtonSecondary onClick={() => _previousPage()}>
              <FaChevronLeft /> Previous Step
            </ButtonSecondary>
            {history.location.pathname.includes('settings') ? null : (
              <ButtonSecondary className='ms-5' onClick={() => history.push('/auth')}>
                Back To Login
              </ButtonSecondary>
            )}
          </div>
        </div>
        <hr className='m-0' />
        <SummaryCard>
          <div className='summary-header'>
            <span>Payment Method</span>
          </div>
          <div className='stripe'>
            <div className='stripe-icon' />
            <div className='ms-2'>
              <span>Stripe</span>
            </div>
          </div>
          <hr className='mt-7' />
          <div className='conditions-wrapper'>
            <div className='conditions'>
              <span className='conditions-label'>Plan</span>
              <span className='conditions-value'>{plan?.title}</span>
            </div>
            <div className='conditions'>
              <span className='conditions-label'>Number of stores</span>
              <span className='conditions-value'>{plan?.numOfStores}</span>
            </div>
            <div className='conditions'>
              <span className='conditions-label'>Number of listings</span>
              <span className='conditions-value'>{plan?.numOfListings}</span>
            </div>
            <div className='conditions'>
              <span className='conditions-label'>Percentage of sales</span>
              <span className='conditions-value'>{plan?.percentOfSale}%</span>
            </div>
            <div className='conditions'>
              <span className='conditions-label'>Duration (days)</span>
              <span className='conditions-value'>{plan?.duration}</span>
            </div>
            <div className='conditions'>
              <span className='conditions-label'>Price</span>
              <span className='conditions-value'>${plan?.price}</span>
            </div>
            <hr className='mt-5' />
          </div>
          <div className='total'>
            <span className='total-label'>Total</span>
            <span className='total-value'>${plan?.price}</span>
          </div>
          <div className='approve'>
            <ButtonSecondary grow={1}>Pay</ButtonSecondary>
          </div>
        </SummaryCard>
      </div>
    </Container>
  )
}

export { OrderSummary }

const SummaryCard = styled.div`
  background-color: #009ef7;
  width: 464px;
  margin-block: 24px;
  margin-inline: auto;
  border-radius: 20px;
  padding: 24px;

  .summary-header {
    color: #ffffff;
    font-size: 26px;
    font-weight: 700;
    marginp: 24px 0;
  }

  .stripe {
    margin-top: 16px;
    background-color: #80cffb;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
  }

  .stripe-icon {
    height: 18px;
    width: 18px;
    background-color: #c0e7fd;
    border: 4px solid #3f4254;
    border-radius: 50%;
  }

  .conditions,
  .total {
    padding-block: 8px;
    display: flex;
    justify-content: space-between;
  }

  .conditions-label,
  .conditions-value {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
  }

  .conditions-value {
    font-weight: 700;
  }

  .total-label,
  .total-value {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
  }

  .approve {
    margin-top: 24px;
    width: 100%;
    flex-grow: 1;
    display: flex;
  }
`

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 2px #cccccc;

  .header-buttons {
    display: flex;

    padding: 24px;

    button {
      margin-left: 12px;
      justify-content: center;
    }
  }
`
