import React from 'react'
import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap-v5'
import { AiOutlineCheck, AiOutlineCaretDown } from 'react-icons/ai'
import { ImBlocked } from 'react-icons/im'

import { Options } from './WhiteBlackListSelect'

type Props = {
  value: Options
}

const options = {
  allowed: { icon: <AiOutlineCheck color='white' />, color: '#50CD89' },
  blocked: { icon: <ImBlocked color='white' />, color: '#F1416C' },
  default: { icon: <AiOutlineCaretDown color='white' />, color: '#80CFFB' },
}

const CustomItem: React.FC<Props> = ({ value = 'default' }) => {
  return (
    <Dropdown.Item eventKey={value}>
      <ItemContainer bgColor={options[value].color}>{options[value].icon}</ItemContainer>
    </Dropdown.Item>
  )
}

const ItemContainer = styled.div<any>(
  ({ bgColor }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: ${bgColor};
    cursor: pointer;
    width: 27px;
    height: 22px;
  `
)

export default CustomItem
