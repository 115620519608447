import React, { FC } from 'react';

type Props = {
    title?: string;
    content?: string;
};
 
export const AdvancedFeatures: FC<Props> = ({ title, content }) => {
    return (
        // <!-- Advanced features section -->
            <section className="bg-white tw-py-20 lg:py-[107px]">
                <div className="mx-auto w-5/6 max-w-[1700px]">
                    <h2 className="text-center text-[35px] font-bold font-['Inter'] leading-[43.05px]">Advanced Features
                    </h2>
                    <p className="mt-[5px] text-center text-slate-500 text-lg font-normal font-['Inter'] leading-7"></p>
                    {/* <!-- Advanced features grid --> */}
                    <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-[15px] mt-10">
                        {/* <!-- Advanced feature card --> */}
                        <div className="h-64 bg-blue-50 rounded-[20px] flex flex-col items-center justify-center">
                            <img className="w-[100px] h-[100px] rounded-full"
                                src="./assets/img/advanced-features/Dimension.svg" />
                            <p className="px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-normal">
                                Dimensions filter</p>
                        </div>
                        {/* <!-- Advanced feature card --> */}
                        <div className="h-64 bg-blue-50 rounded-[20px] flex flex-col items-center justify-center">
                            <img className="w-[100px] h-[100px] rounded-full"
                                src="./assets/img/advanced-features/Taxes.svg" />
                            <p className="px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-normal">
                                Taxes Markup</p>
                        </div>
                        {/* <!-- Advanced feature card --> */}
                        <div className="h-64 bg-blue-50 rounded-[20px] flex flex-col items-center justify-center">
                            <img className="w-[100px] h-[100px] rounded-full"
                                src="./assets/img/advanced-features/Currency.svg" />
                            <p className="px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-normal">
                                Currency Conversion</p>
                        </div>
                        {/* <!-- Advanced feature card --> */}
                        <div className="h-64 bg-blue-50 rounded-[20px] flex flex-col items-center justify-center">
                            <img className="w-[100px] h-[100px] rounded-full"
                                src="./assets/img/advanced-features/Shipping.svg" />
                            <p className="px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-normal">
                                Shipping Pricing Table</p>
                        </div>
                        {/* <!-- Advanced feature card --> */}
                        <div className="h-64 bg-blue-50 rounded-[20px] flex flex-col items-center justify-center">
                            <img className="w-[100px] h-[100px] rounded-full"
                                src="./assets/img/advanced-features/Brands.svg" />
                            <p className="px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-normal">
                                Brands List</p>
                        </div>
                        {/* <!-- Advanced feature card --> */}
                        <div className="h-64 bg-blue-50 rounded-[20px] flex flex-col items-center justify-center">
                            <img className="w-[100px] h-[100px] rounded-full"
                                src="./assets/img/advanced-features/Keywords.svg" />
                            <p className="px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-normal">
                                Keywords Filter</p>
                        </div>
                        {/* <!-- Advanced feature card --> */}
                        <div className="h-64 bg-blue-50 rounded-[20px] flex flex-col items-center justify-center">
                            <img className="w-[100px] h-[100px] rounded-full"
                                src="./assets/img/advanced-features/Categories.svg" />
                            <p className="px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-normal">
                                Categories Filter</p>
                        </div>
                        {/* <!-- Advanced feature card --> */}
                        <div className="h-64 bg-blue-50 rounded-[20px] flex flex-col items-center justify-center">
                            <img className="w-[100px] h-[100px] rounded-full"
                                src="./assets/img/advanced-features/Pricing.svg" />
                            <p className="px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-normal">
                                Pricing Table</p>
                        </div>
                    </div>
                </div>
            </section>
    )
};