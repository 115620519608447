import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../redux/AuthCRUD'
import styled from 'styled-components'
import {useQuery} from '../../../utils/customHooks'
import {accountApis} from '../../../../setup/apis/account/accountApis'
import {triggerAlert} from '../../../components/Alert'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {preventSpaces} from '../../../utils/inputFieldUtils'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .trim()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .trim()
        .oneOf([Yup.ref('password')], "Passwords don't match"),
    }),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [isVisiblePass, setIsVisiblePass] = useState<boolean>()
  const [isVisibleConfPass, SetIsVisibleConfPass] = useState<boolean>()
  const history = useHistory()
  const query = useQuery()
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      resetPassword(values.password, values.confirmPassword)
    },
  })

  useEffect(() => {
    verifyLink()
  }, [])

  async function verifyLink() {
    try {
      const userId = query.get('userId') || ''
      const token = query.get('token')?.replaceAll(' ', '%2B') || ''
      const response = await accountApis.verifyLink(userId, token)
      if (!response?.data) {
        triggerAlert('This link has been expired.', 'error')
      }
    } catch (e: any) {
      if (e?.response?.status === 400) {
        triggerAlert(e?.response?.data?.Message || 'Something went wrong', 'error')
      } else {
        triggerAlert('Something went wrong', 'error')
      }
    }
  }

  async function resetPassword(password: string, confirmPassword: string) {
    try {
      setLoading(true)
      const userId = query.get('userId') || ''
      const token = query.get('token')?.replaceAll(' ', '+') || ''
      const requestData = {
        userId,
        token,
        password,
        confirmPassword,
      }
      const response = await accountApis.resetPassword(requestData)
      if (response?.status === 200) history.push('/auth')
    } catch (e: any) {
      if (e?.response?.status === 400) {
        triggerAlert(e?.response?.data?.Message || 'Something went wrong', 'error')
      } else {
        triggerAlert('Something went wrong', 'error')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-15'>
          {/* begin::Title */}
          <h1 className='text-dark'>Reset Your Password</h1>
          {/* end::Title */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}

        <div className='fv-row mb-10'>
          <label className='input-label'>New Password</label>
          <div className='icon-container'>
            <input
              type={isVisiblePass ? 'text' : 'password'}
              autoComplete='new-password'
              placeholder='Insert a password'
              onKeyDown={(e) => preventSpaces(e)}
              {...formik.getFieldProps('password')}
              className={clsx(
                'input-value',
                {'input-invalid': formik.touched.password && formik.errors.password},
                {
                  'input-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <div className='icon' onClick={() => setIsVisiblePass((prev) => !prev)}>
              <img
                src={toAbsoluteUrl(`/media/svg/misc/eye${isVisiblePass ? 'Slashed' : ''}.svg`)}
              />
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block info-color'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='input-label'>Confirm Password</label>
          <div className='icon-container'>
            <input
              type={isVisibleConfPass ? 'text' : 'password'}
              autoComplete='new-password'
              placeholder='Confirm your password'
              onKeyDown={(e) => preventSpaces(e)}
              {...formik.getFieldProps('confirmPassword')}
              className={clsx(
                'input-value',
                {
                  'input-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                },
                {
                  'input-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
            />
            <div className='icon' onClick={() => SetIsVisibleConfPass((prev) => !prev)}>
              <img
                src={toAbsoluteUrl(`/media/svg/misc/eye${isVisibleConfPass ? 'Slashed' : ''}.svg`)}
              />
            </div>
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block info-color'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn flex-grow-1 btn-lg btn-primary fw-bolder mt-5'
            disabled={!formik.isValid || loading}
          >
            <span className='indicator-label'>Change Password</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </Container>
  )
}

const Container = styled.div`
  .btn-disabled {
    background-color: #f1faff;
    color: #009ef7;
  }

  .btn-disabled: hover {
    background-color: #66c5fa;
    color: #ffffff;
  }

  .input-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .input-value {
    display: block;
    width: 100%;
    border: 1px solid #cdcfda;
    border-radius: 8px;
    padding: 11px;
    font-size: 14px;
    font-weight: 500;
    color: #3f4254;

    ::-ms-reveal {
      display: none;
    }
  }

  .input-value:focus,
  .input-invalid:focus {
    outline: none;
    box-shadow: 0 0 1px 3px #c0e7fd;
    border: 1px solid #cdcfda;
  }

  .create-account {
    margin: 24px;
  }

  .icon-container {
    position: relative;
  }

  .icon {
    position: absolute;
    top: 13px;
    right: 12px;
    cursor: pointer;
  }

  .input-invalid {
    border: 1px solid tomato;
  }

  .save-button {
    margin-block: 24px;
  }

  .input-cap {
    text-transform: capitalize;

    //placeholder
    ::-webkit-input-placeholder {
      text-transform: none;
    }
    :-moz-placeholder {
      text-transform: none;
    }
    ::-moz-placeholder {
      text-transform: none;
    }
    :-ms-input-placeholder {
      text-transform: none;
    }
    ::placeholder {
      text-transform: none;
    }
  }
`
