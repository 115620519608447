import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import BrandList from './BrandList'

const brandBreadCrumbs: Array<PageLink> = [
  {
    title: 'Brands',
    path: '/brands/brandlist',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BrandPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/brands/brandlist'>
          <PageTitle breadcrumbs={brandBreadCrumbs}>Brand List</PageTitle>
          <BrandList />
        </Route>
      </Switch>
    </>
  )
}

export default BrandPage
