import React from 'react'
import { Dropdown } from 'react-bootstrap-v5'
import styled from 'styled-components'

import CustomToggle from './CustomToggle'
import CustomMenu from './CustomMenu'
import CustomItem from './CustomItem'
import When from '../When'

export type Options = 'allowed' | 'blocked' | 'default'

type Props = {
  id: string
  options: Options[]
  selectedValue: Options
  childIsBlocked?: boolean | undefined
  handleSelect: (eventKey: string | null, id: string, selectedValue: string) => void
}

const WhiteBlackListSelect: React.FC<Props> = ({
  selectedValue,
  options,
  handleSelect,
  id,
  childIsBlocked,
}) => {
  return (
    <Dropdown
      onSelect={(value) => handleSelect(value, id, selectedValue)}
      className='d-inline-block mx-2'
    >
      <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
        <CustomItem value={selectedValue} />
      </Dropdown.Toggle>

      <When condition={!childIsBlocked}>
        <MenuContainer>
          <Dropdown.Menu as={CustomMenu}>
            {options
              .filter((item) => item !== selectedValue)
              .map((item) => (
                <CustomItem key={item} value={item} />
              ))}
          </Dropdown.Menu>
        </MenuContainer>
      </When>
    </Dropdown>
  )
}

const MenuContainer = styled.div`
  .dropdown-menu {
    min-width: 4rem;
    box-shadow: 1px 0px 4px darkgray;
  }

  .dropdown-menu > ul {
    padding-left: 0;
    margin-bottom: 0;
  }
`

export default WhiteBlackListSelect
