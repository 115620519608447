/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {accountApis} from '../../../../setup/apis/account/accountApis'
import {LoginType} from '../../../../setup/types/request-data-types/RequestDataTypes'
import {AUTH_STATUS, deleteUserDataForReg, getUserId} from '../../../utils/authUtils'
import {preventSpacesAndNumbers} from '../../../utils/inputFieldUtils'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import styled from 'styled-components'
import {triggerAlert} from '../../../components/Alert'
import {ButtonPrimary} from '../../../components/CustomStyledComponents'
import {getToken} from '../../redux-toolkit/userSlicer'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .trim()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  username: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [cleared, setCleared] = useState<boolean>(false)
  const [isVisiblePass, setIsVisiblePass] = useState<boolean>(false)

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      signIn()
    },
  })

  async function signIn() {
    try {
      setLoading(true)
      const response =
        (await accountApis.login(formik.values.username, formik.values.password)) || {}
      dispatch(getToken(response?.data))
      deleteUserDataForReg()
      localStorage.setItem('authStatus', AUTH_STATUS.loggedIn)

    } catch (e: any) {
      if (
        e.response?.status === 400 &&
        e?.response?.data?.error_description === 'invalid_username_or_password'
      ) {
        triggerAlert('Invalid username or password', 'error')
      }
    } finally {
      setLoading(false)
    }
  }

  function isVisible(name: keyof {username: string; password: string}) {
    return formik.values[name]
  }

  function clearInput() {
    setCleared((prev) => !prev)
    formik.values.username = ''
  }

  return (
    <Container
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to Market Gap</h1>
        {/*<div className='text-gray-400 fw-bold fs-4'>*/}
        {/*  New Here?{' '}*/}
        {/*  <Link to='/registration' className='link-primary fw-bolder'>*/}
        {/*    <a href='#' className='link-primary fw-bolder'>*/}
        {/*      Create an Account*/}
        {/*    </a>*/}
        {/*  </Link>*/}
        {/*</div>*/}
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='mb-12'>
          <label className='input-label'>Username</label>
          <div className='icon-container'>
            <input
              type='username'
              autoComplete='off'
              required
              placeholder='Insert your username'
              {...formik.getFieldProps('username')}
              className={clsx(
                'input-value',
                {'input-invalid': formik.touched.username && formik.errors.username},
                {
                  'input-valid': formik.touched.username && !formik.errors.username,
                }
              )}
            />
            {isVisible('username') ? (
              <div className='icon' onClick={() => clearInput()}>
                <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
              </div>
            ) : null}
          </div>
          {formik.touched.username && formik.errors.username && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block info-color'>
                <span role='alert'>{formik.errors.username}</span>
              </div>
            </div>
          )}
        </div>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <div className='icon-container'>
          <input
            type={isVisiblePass ? 'text' : 'password'}
            autoComplete='off'
            placeholder='Insert your password'
            {...formik.getFieldProps('password')}
            className={clsx(
              'input-value',
              {
                'input-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <div className='icon' onClick={() => setIsVisiblePass((prev) => !prev)}>
            <img src={toAbsoluteUrl(`/media/svg/misc/eye${isVisiblePass ? 'Slashed' : ''}.svg`)} />
          </div>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <ButtonPrimary
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={loading || !formik.isValid}
        >
          {!loading && <span className=''>Continue</span>}
          {loading && (
            <span style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </ButtonPrimary>

        {/* begin::Separator */}
        {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </Container>
  )
}

const Container = styled.form`
  .input-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .input-value {
    display: block;
    width: 100%;
    border: 1px solid #cdcfda;
    border-radius: 8px;
    padding: 11px;
    font-size: 14px;
    font-weight: 500;
    color: #3f4254;

    ::-ms-reveal {
      display: none;
    }
  }

  .input-value:focus,
  .input-invalid:focus {
    outline: none;
    box-shadow: 0 0 1px 3px #c0e7fd;
    border: 1px solid #cdcfda;
  }

  .icon-container {
    position: relative;
  }

  .icon {
    position: absolute;
    top: 13px;
    right: 12px;
    cursor: pointer;
  }

  .input-invalid {
    border: 1px solid tomato;
  }
`
