import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import StoreList from './StoreList'
import StoreSettings from './store-settings/StoreSettings'
import { SuccessfullyCreatedStore } from './SuccessfullyCreatedStore'
import { PaymentFailed } from './PaymentFailed'

const storeBreadCrumbs: Array<PageLink> = [
  {
    title: 'Stores',
    path: '/stores/store-list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const StorePage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/stores/store-list'>
          <PageTitle breadcrumbs={storeBreadCrumbs}>Store List</PageTitle>
          <StoreList />
        </Route>
        <Route path='/stores/store-settings/:storeId?'>
          <PageTitle breadcrumbs={storeBreadCrumbs}>Store Settings</PageTitle>
          <StoreSettings />
        </Route>
        <Route path='/stores/successfully-created'>
          <PageTitle breadcrumbs={storeBreadCrumbs}>Successfully Created Store</PageTitle>
          <SuccessfullyCreatedStore />
        </Route>
        <Route path='/stores/payment-failed'>
          <PageTitle breadcrumbs={storeBreadCrumbs}>Something Went Wrong</PageTitle>
          <PaymentFailed />
        </Route>
      </Switch>
    </>
  )
}

export default StorePage
