import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
    title?: string;
    content?: string;
};

export const HeroDropshipping: FC<Props> = ({ title, content }) => {
    const history = useHistory()

    return (
        // <!-- Main Hero section -->
        <section className="relative isolate pt-14 overflow-visible overflow-x-clip">
            <div className="mx-auto max-w-[1464px] px-8  lg:py-32 tw-py-14">
                {/* <!-- Text section --> */}
                <div
                    className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-0 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-0">
                    <h1 className="max-w-2xl text-4xl text-[#1D1D25] sm:text-6xl lg:col-span- xl:col-auto"><span
                            className="font-light">Simplify</span><br /><span className="font-semibold">Amazon-to-Amazon Dropshipping</span></h1>
                    <div className="mt-6 max-w-xl lg:mt-0 lg:row-start-2 xl:col-end-1 xl:row-start-1">
                        <p className="text-normal leading-8 text-[#1D1D25] capitalize font-light font-['Inter']">Expand
                            your Amazon business globally with minimal upfront investment. Leverage our powerful
                            tools to maximize profits, streamline listings, and effortlessly reach customers across
                            10+ Amazon marketplaces</p>
                        <p className="mt-2 text-lg leading-8 text-[#1D1D25] capitalize font-semibold font-['Inter']">
                            Join Now and Get Started with Market Gap and Elevate
                            Your Amazon Sale</p>
                        <div className="sm:max-w-[240px] mt-10 flex items-center gap-x-9">
                            <button type="button"
                            onClick={() => history.push('/registration/create-account')}
                                className="font-['Inter'] w-full text-sm uppercase font-medium leading-6 rounded-3xl bg-[#1D1D25] text-white border border-gray-100 inline-flex items-center justify-center gap-x-2 px-6 py-3">
                                <svg className="h-[21px] w-[21px] text-white fill-current" width="30" height="30"
                                    viewBox="0 0 30 30" fill="none">
                                    <g clip-path="url(#clip0_1_6654)">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M10.8519 22.751L8.05193 20.651C7.49193 20.231 7.52011 19.0238 7.89128 17.5489L4.55193 18.026L4.37693 16.801L6.47693 14.001L9.3961 13.584C9.9043 12.552 10.472 11.5909 11.0269 10.851C13.1269 8.05098 17.8519 6.12598 19.9519 7.70098C22.0519 9.27598 21.5269 14.351 19.4269 17.151C18.8715 17.8916 18.1083 18.705 17.2599 19.4818L17.6769 22.401L15.5769 25.201L14.3519 25.376L13.8749 22.0366C12.5629 22.8059 11.4126 23.1715 10.8519 22.751ZM10.5019 20.301L9.37143 19.4531C9.36886 19.3636 9.37067 19.2741 9.37685 19.1848C9.41325 18.6303 9.58948 17.8513 9.88663 16.9608C10.4932 15.1429 11.5056 13.1295 12.4269 11.901C14.1059 9.66238 17.8127 8.28408 18.9019 9.10098C19.9911 9.91788 19.7059 13.8624 18.0269 16.101C17.1056 17.3295 15.4557 18.8658 13.8808 19.9564C13.1084 20.4905 12.4112 20.8776 11.8878 21.068C11.804 21.0991 11.7188 21.1261 11.6324 21.1489L10.5019 20.301ZM14.7019 14.701C15.0732 14.9795 15.54 15.099 15.9994 15.0334C16.4589 14.9677 16.8735 14.7223 17.1519 14.351C17.4304 13.9797 17.55 13.513 17.4843 13.0535C17.4187 12.594 17.1732 12.1795 16.8019 11.901C16.4306 11.6225 15.9639 11.5029 15.5044 11.5686C15.045 11.6342 14.6304 11.8797 14.3519 12.251C14.0735 12.6223 13.9539 13.089 14.0195 13.5485C14.0852 14.0079 14.3306 14.4225 14.7019 14.701Z" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_6654">
                                            <rect width="21" height="21"
                                                transform="translate(12.6) rotate(36.8699)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                Get started for free
                                </button>
                        </div>
                    </div>
                    {/* <!-- Images -->
                    <!-- Images for mobile --> */}
                    <div className="relative w-full max-w-screen flex flex-col mt-10 lg:hidden">
                        <img className="w-full" src="./assets/img/Dashboard-sm.png" />
                    </div>

                    {/* <!-- Images for iPad Pro (1024px) --> */}
                    <div className="col-start-2 row-span-2 hidden lg:block relative w-auto max-w-screen xl:row-span-2 xl:row-end-2 xl:mt-[-40px]">
                        <img className="w-full" src="./assets/img/Dashboard-sm.png" />
                    </div>

                    {/* <!-- Images for desktop --> */}
                    <div
                        className="hidden w-full max-w-lg lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 top-[-90px] relative justify-center items-center">
                        <div
                            className="z-10 w-[429px] h-[264px] left-0 top-0 absolute justify-center items-center inline-flex">
                            <div className="w-[429px] h-[264px] relative">
                                <div
                                    className="w-[429px] h-[264px] left-0 top-0 absolute bg-slate-50 bg-opacity-5 rounded-[10.82px] shadow-xl backdrop-blur-[31px]">
                                </div>
                                <img className="w-[412.77px] h-[247.23px] left-[8.11px] top-[8.12px] absolute rounded-md"
                                    src="./assets/img/Background.png" />
                            </div>
                        </div>
                        <div className="z-50 w-[793px] h-[488px] left-[64px] top-[186px] absolute">
                            <div className="w-[803px] h-[502px] left-[-5px] top-[-7px] absolute">
                                <div
                                    className="w-[803px] h-[502px] left-0 top-0 absolute bg-slate-50 bg-opacity-5 rounded-[20px] shadow-xl backdrop-blur-[31px]">
                                </div>
                                <img className="w-[763px] h-[457px] left-[20px] top-[22px] absolute rounded-[10px]"
                                    src="./assets/img/Background-2.png" />
                            </div>
                            <div
                                className="z-100 w-[397px] h-[341.88px] pb-[0.88px] left-[471px] top-[260px] absolute justify-center items-center inline-flex">
                                <div className="w-[401px] h-[341px] relative">
                                    <div
                                        className="w-[401px] h-[341px] left-0 top-0 absolute bg-slate-50 bg-opacity-5 rounded-[10.01px] shadow-xl backdrop-blur-[25px]">
                                    </div>
                                    <img className="w-[381.98px] h-[319.59px] left-[9.51px] top-[10.49px] absolute rounded-[5.01px]"
                                        src="./assets/img/Background-3.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Text Hero section --> */}
            </div>
        </section>)
};