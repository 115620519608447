export const getCurrentUrl = (pathname: string): string => {
  const [url] = pathname.split(/[?#]/);
  return url;
}

export const checkIsActive = (pathname: string, url: string): boolean => {
  if (url === '/') {
    return false;
  }

  const currentUrl = getCurrentUrl(pathname);
  if (!currentUrl || !url) {
    return false;
  }

  return currentUrl === url || currentUrl.startsWith(url);
}
