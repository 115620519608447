import axios from 'axios'
import { CategorySearchType } from '../../types/request-data-types/RequestDataTypes'

export const categoryApis = {
  async getAll(requestData: CategorySearchType) {
    const page = requestData?.page ? requestData?.page + 1 : 1
    return await axios
      .post(
        `${process.env.REACT_APP_PROXY}/Categories?categoryName=${
          requestData?.categoryName || ''
        }&page=${(requestData?.page || 0) + 1}&pagesize=${requestData?.pageSize || ''}&storeId=${
          requestData?.storeId || ''
        }&ExcludeBlackListedCategories=${requestData?.ExcludeBlackListedCategories || ''}`,
        requestData?.parentIds || []
      )
      .then((res) => res.data || [])
      .catch((er) => {
        console.log('error', er)
        return []
      })
  },

  async getAllTree(requestData: CategorySearchType) {
    return await axios
      .post(
        `${process.env.REACT_APP_PROXY}/Categories/Tree?categoryName=${
          requestData?.categoryName || ''
        }&page=${(requestData?.page || 0) + 1}&pagesize=${requestData?.pageSize || ''}&storeId=${
          requestData?.storeId || ''
        }&ExcludeBlackListedCategories=${requestData?.ExcludeBlackListedCategories || ''}`,
        requestData?.parentIds || []
      )
      .then((res) => res.data || [])
      .catch((er) => {
        console.log('error', er)
        return []
      })
  },
}
