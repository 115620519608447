import {AnyRecord} from 'dns'
import {FC} from 'react'
import {Line} from 'react-chartjs-2'
import styled from 'styled-components'
import {ChartDataType} from './Reports'

type Props = {
  chartData: ChartDataType
  dateType: string
}

const ReportChart: FC<Props> = ({
  chartData: {dataCurrent, dataPrevious, labels, maxData},
  dateType,
}) => {
  const dates = {
    current: dateType === 'Day' ? 'Today' : dateType === 'Week' ? 'This week' : 'This Month',
    previous: dateType === 'Day' ? 'Yesterday' : dateType === 'Week' ? 'Last week' : 'Last Month',
    label:
      dateType === 'Day'
        ? 'Daily Update'
        : dateType === 'Week'
        ? 'Weekly update'
        : 'Monthly Update',
    unit: dateType === 'Day' ? 'Hours' : 'Days',
  }

  const data = (canvas: any) => {
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 120)
    gradient.addColorStop(0, 'rgba(0, 158, 247, 0.5)')
    gradient.addColorStop(1, 'rgba(204, 236, 253, 0.2)')

    return {
      labels,
      datasets: [
        {
          fill: 'start',
          label: dates.current,
          data: dataCurrent,
          //   backgroundColor: 'rgba(255, 206, 86, 0.2)',
          backgroundColor: gradient,
          borderColor: '#009EF7',
          pointBorderColor: '#009EF7',
          pointHoverBorderColor: '#009EF7',
          borderWidth: 2,
        },
        {
          label: dates.previous,
          data: dataPrevious,
          backgroundColor: '#C4C4C4',
          borderColor: '#C4C4C4',
          pointBorderColor: '#C4C4C4',
          pointHoverBorderColor: '#C4C4C4',
          borderWidth: 2,
        },
      ],
    }
  }

  return (
    <Container>
      <div className='header-line'>
        <span>{dates.label}</span>
      </div>
      <div>
        <Line
          height={400}
          width={600}
          data={data}
          options={{
            plugins: {
              tooltip: {
                intersect: false,
                bodyColor: '#ffffff',
                borderColor: '#000000',
                callbacks: {
                  title: () => '',
                  // label: () => '',
                },
              },
              legend: {
                position: 'top',
                align: 'end',
                labels: {
                  boxHeight: 2,
                  boxWidth: 24,
                  padding: 30,
                },
                onClick: (evt, legendItem, legend) => {
                  const index = legendItem.datasetIndex
                  const ci = legend.chart

                  legend.chart.data.datasets.forEach((d, i) => {
                    ci.show(i)
                    d.hidden = false
                  })

                  if (!legendItem.hidden) {
                    ci.hide(index)
                    legendItem.hidden = true
                  }

                  ci.update()
                },
              },
            },
            responsive: true,
            showLine: true,
            elements: {
              line: {
                tension: 0.4,
              },
              point: {
                radius: 0,
                hoverRadius: 5,
                borderWidth: 5,
              },
            },
            maintainAspectRatio: false,
            scales: {
              yAxes: {
                grid: {
                  //   color: 'transparent',
                },
                min: 0,
                max: Math.ceil((maxData + 20) / 10) * 10,
                beginAtZero: true,
                position: 'right',
              },
              xAxes: {
                grid: {
                  color: 'transparent',
                },
              },
            },
          }}
        />
      </div>
      <div className='footer-line'>
        <span>{dates.unit}</span>
      </div>
    </Container>
  )
}

export {ReportChart}

const Container = styled.div`
  background-color: #ffffff;
  padding: 24px 24px 12px 24px;
  border-radius: 8px;
  box-shadow: -4px -4px 12px rgba(120, 146, 165, 0.05), 4px 4px 12px rgba(120, 146, 165, 0.05);
  cursor: pointer;
  display: flex;
  flex-direction: column;

  .header-line {
    margin-bottom: -24px;
    font-size: 19px;
    font-weight: 700;
  }

  .footer-line {
    font-size: 14px;
    font-weight: 500;
    color: #888888;
    align-self: center;
  }
`
