import axios from 'axios'
import {ProductSearchDataType} from '../../types/request-data-types/RequestDataTypes'

export const productApis = {
  async getPaging(searchData: ProductSearchDataType) {
    if (!searchData.priceUpdateDateTo) {
      searchData.priceUpdateDateFrom = undefined
    }
    if (!searchData.inventoryUpdateDateTo) {
      searchData.inventoryUpdateDateFrom = undefined
    }
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/Products`, {...searchData, page: searchData.page + 1})
      .then((res) => res?.data)
      .catch((er) => console.log('error', er))
  },

  async getProgressLists(storeId: number) {
    return await axios
        .post(`${process.env.REACT_APP_PROXY}/Products/Progress?storeId=${storeId}`)
        .then((res) => {
          return res?.data
        })
        .catch((er) => console.log('error', er))
  },
}
