import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { OrderFiltersType, OrderStatus, OrderStatusType, OrderType } from './OrderList'
import styled from 'styled-components'
import { CustomHeader } from '../../components/CustomStyledComponents'
import { FaSearch } from 'react-icons/fa'
import { DateTimePicker } from '../../components/DateTimePicker'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useOnClickOutside } from '../../utils/customHooks'
import { onlyNumbers, preventMinuses } from '../../utils/inputFieldUtils'

type Props = {
  loading: boolean
  className?: string
  data: Array<OrderType>
  filterData: OrderFiltersType
  statusFilterData: { title: keyof OrderStatusType; selected: boolean }[]
  manageStatusFilters: (status: keyof OrderStatusType) => void
  manageStringFilters: (filterName: keyof OrderFiltersType, name: string) => void
  manageNumberFilters: (filterName: keyof OrderFiltersType, value: number) => void
  manageDateFilters: (dates: any) => void
  manageNotes: (orderId: number, note: string) => void
  goToSingleOrder: (order: OrderType) => void
}

const OrderTable: React.FC<Props> = ({
  data,
  filterData,
  manageStatusFilters,
  manageStringFilters,
  manageNumberFilters,
  manageDateFilters,
  manageNotes,
  goToSingleOrder,
  loading,
  statusFilterData,
}) => {
  const [activeCol, setActiveCol] = useState<string>('')
  const [activeNoteIdx, setActiveNoteIdx] = useState<number>(-1)
  const [noteTmp, setNoteTmp] = useState<string>('')
  const tHeadRef = useRef<any>()
  const inputRef = useRef<any>()

  useOnClickOutside(tHeadRef, () => setActiveCol(''))

  useEffect(() => {
    if (activeNoteIdx !== -1) {
      setNoteTmp(data?.[activeNoteIdx]?.note || '')
      inputRef.current.focus()
    } else {
      setNoteTmp('')
    }
  }, [activeNoteIdx])

  function _manageActiveCol(colName: string) {
    setActiveCol(colName)
  }

  function _getFilters() {
    return statusFilterData.map((item, index) => (
      <div
        className='d-flex justify-content-start align-items-center fs-6 fw-bold mt-3 px-2 cursor-pointer'
        key={index}
        onClick={() => manageStatusFilters(item?.title)}
      >
        <div className='form-check me-2 form-check-sm form-check-solid '>
          <input
            id={`selected${index}`}
            name='selected'
            className='form-check-input'
            type='checkbox'
            value='1'
            checked={item.selected}
          />
        </div>
        <div>
          <span>
            {item.title === 'PartiallyShipped'
              ? 'Partially Shipped'
              : item.title === 'PendingAvailability'
                ? 'Pending Availability'
                : item.title}
          </span>
        </div>
      </div>
    ))
  }

  function rightNote(e: any, index: number) {
    setActiveNoteIdx(index)
  }

  function saveNote(orderId: number) {
    manageNotes(orderId, noteTmp)
    setActiveNoteIdx(-1)
  }

  function statusStyle(item: OrderType) {
    return (!['pending', 'shipped', 'unshipped']?.includes(item?.orderStatus?.toLowerCase()) ? 'pending' : OrderStatus[item?.orderStatus])?.toLowerCase()
  }

  function getTableRows() {
    return data?.map((item: OrderType, index: number) => (
      <tr key={index} className='custom-tbl-row' onClick={() => goToSingleOrder(item)}>
        <td className='ps-4'>
          <span className='custom-tbl-data'>{item.storeMicroOrderId}</span>
        </td>
        <td className='pe-7'>
          <div className={`status-${statusStyle(item)}`}>
            <span className='custom-tbl-data'>{OrderStatus[item?.orderStatus] || ''}</span>
          </div>
        </td>
        <td>
          <span className='custom-tbl-data'>{moment(item.orderedOn).format('lll')}</span>
        </td>
        <td>
          <span className='custom-tbl-data'>
            {item.orderTotal ? `$${item.orderTotal}` : 'Free'}
          </span>
        </td>
        <td className='custom-tbl-data'>{item.quantity}</td>
        <td>
          {item?.storeSKUs?.map((sku, i) =>
            i < 5 ? (
              <div key={i}>
                <span className='custom-tbl-data'>{sku}</span>
              </div>
            ) : null
          )}
          {item.storeSKUs.length > 5 ? <span className='note-span'>See more...</span> : null}
        </td>
        <td>
          <span className='custom-tbl-data'>
            {item.shippingTotal ? `$${item.shippingTotal}` : 'Free'}
          </span>
        </td>
        <td className='note-td' onClick={(e) => e.stopPropagation()}>
          {activeNoteIdx === index ? (
            <div>
              <input
                ref={inputRef}
                type='text'
                className='note-input'
                placeholder='take a note... '
                value={noteTmp}
                onKeyDown={(e) => e.key === 'Enter' && saveNote(item.storeMicroOrderId)}
                onChange={({ target }) => setNoteTmp(target.value)}
              />
              <div className='d-flex mt-3 '>
                <span
                  className='ms-8 me-2 custom-btn custom-btn-primary'
                  onClick={() => saveNote(item.storeMicroOrderId)}
                >
                  Save
                </span>
                <span
                  className='custom-btn custom-btn-secondary'
                  onClick={() => setActiveNoteIdx(-1)}
                >
                  Cancel
                </span>
              </div>
            </div>
          ) : (
            <div className='max-w-250px pe-7' onClick={(e) => e.stopPropagation()}>
              {item.note ? (
                <span onClick={(e) => rightNote(e, index)} className='add-note-span'>
                  {item.note}
                </span>
              ) : (
                <span onClick={(e) => rightNote(e, index)} className='add-note-span'>
                  You can add a note right here
                </span>
              )}
            </div>
          )}
        </td>
      </tr>
    ))
  }
  return (
    <Container>
      {/* begin::Header */}
      <CustomHeader>Order List</CustomHeader>
      <hr className='m-0' />
      <div className='table-div table-responsive table-wrapper'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-6 text-nowrap'>
          {/* begin::Table head */}
          <thead className='sticky-top' ref={tHeadRef}>
            <tr className='custom-tbl-head'>
              <th className='ps-4 min-w-100px rounded-top-start'>
                <div className='d-flex filter-parent'>
                  <span>Order ID</span>
                  <div onClick={() => _manageActiveCol('storeMicroOrderId')}>
                    {filterData.orderIdFilter ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === 'storeMicroOrderId' ? (
                    <div className='filter-table filter-string-input'>
                      <input
                        type='text'
                        min={0}
                        onKeyDown={(e) => onlyNumbers(e)}
                        className='note-input'
                        value={filterData?.orderIdFilter}
                        onChange={(e) => manageNumberFilters('orderIdFilter', +e.target.value)}
                      />
                      <div className='search-icon'>
                        <FaSearch />
                      </div>
                      <div className='mt-4'>
                        <span>Insert the order ID you are looking for</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </th>
              <th className='min-w-125px'>
                <div className='d-flex filter-parent'>
                  <div>
                    <span>Status</span>
                  </div>
                  <div onClick={() => _manageActiveCol('status')}>
                    {filterData?.orderStatusFilters?.length ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === 'status' ? (
                    <div className='filter-table filter-status'>{_getFilters()}</div>
                  ) : null}
                </div>
              </th>
              <th className='min-w-125px'>
                <div className='d-flex'>
                  <span>Ordered On</span>
                  <div className='filter-parent' onClick={() => _manageActiveCol('orderedOn')}>
                    {filterData?.orderedOnFromFilter && filterData?.orderedOnToFilter ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/calendar-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/calendar.svg')}
                        className='icon icon-date'
                      />
                    )}
                    <div className='date-container'>
                      <DateTimePicker
                        className='w-20px h-20px'
                        startDate={filterData?.orderedOnFromFilter}
                        endDate={filterData?.orderedOnToFilter}
                        onChange={(dates) => manageDateFilters(dates)}
                        showPopperArrow={false}
                        forFilter
                        selectsRange
                      />
                    </div>
                  </div>
                </div>
              </th>
              <th className='min-w-125px'>
                <div className='d-flex filter-parent'>
                  <span>Total price</span>
                  <div onClick={() => _manageActiveCol('totalPrice')}>
                    {filterData?.priceFromFilter || filterData?.priceToFilter ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === 'totalPrice' ? (
                    <div className='filter-table filter-number-input'>
                      <span>{`From `}</span>
                      <input
                        type='number'
                        min={0}
                        onKeyDown={(e) => preventMinuses(e)}
                        placeholder='0.00'
                        className='note-input'
                        value={filterData?.priceFromFilter}
                        onChange={(e) => manageNumberFilters('priceFromFilter', +e.target.value)}
                      />
                      <span>{`To `}</span>
                      <input
                        type='number'
                        min={0}
                        onKeyDown={(e) => preventMinuses(e)}
                        placeholder='0.00'
                        className='note-input'
                        value={filterData?.priceToFilter}
                        onChange={(e) => manageNumberFilters('priceToFilter', +e.target.value)}
                      />
                      <span>{`USD`}</span>
                    </div>
                  ) : null}
                </div>
              </th>
              <th className='min-w-125px'>
                <div className='d-flex filter-parent'>
                  <span>Quantity</span>
                  <div onClick={() => _manageActiveCol('quantity')}>
                    {filterData?.quantityFromFilter || filterData?.quantityToFilter ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === 'quantity' ? (
                    <div className='filter-table filter-number-input'>
                      <span>{`From `}</span>
                      <input
                        type='number'
                        min={0}
                        onKeyDown={(e) => preventMinuses(e)}
                        placeholder='0.00'
                        className='note-input'
                        value={filterData?.quantityFromFilter}
                        onChange={(e) => manageNumberFilters('quantityFromFilter', +e.target.value)}
                      />
                      <span>{`To `}</span>
                      <input
                        type='number'
                        min={0}
                        onKeyDown={(e) => preventMinuses(e)}
                        placeholder='0.00'
                        className='note-input'
                        value={filterData?.quantityToFilter}
                        onChange={(e) => manageNumberFilters('quantityToFilter', +e.target.value)}
                      />
                      <span>{`USD`}</span>
                    </div>
                  ) : null}
                </div>
              </th>
              <th className='min-w-150px'>
                <div className='d-flex filter-parent'>
                  <span>SKU</span>
                  <div className='' onClick={() => _manageActiveCol('sku')}>
                    {filterData?.skuFilter ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === 'sku' ? (
                    <div className='filter-table filter-string-input'>
                      <input
                        type='text'
                        className='note-input'
                        value={filterData?.skuFilter}
                        onChange={(e) => manageStringFilters('skuFilter', e.target.value)}
                      />
                      <div className='search-icon'>
                        <FaSearch />
                      </div>
                      <div className='mt-4'>
                        <span>Insert the SKU you are looking for</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </th>
              <th className='min-w-100px'>
                <div className='d-flex filter-parent'>
                  <span>Shipping</span>
                  <div onClick={() => _manageActiveCol('shipping')}>
                    {filterData?.shippingTotalFromFilter || filterData?.shippingTotalToFilter ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === 'shipping' ? (
                    <div className='filter-table filter-number-input'>
                      <span>{`From `}</span>
                      <input
                        type='number'
                        min={0}
                        onKeyDown={(e) => preventMinuses(e)}
                        placeholder='0.00'
                        className='note-input'
                        value={filterData?.shippingTotalFromFilter}
                        onChange={(e) =>
                          manageNumberFilters('shippingTotalFromFilter', +e.target.value)
                        }
                      />
                      <span>{`To `}</span>
                      <input
                        type='number'
                        min={0}
                        onKeyDown={(e) => preventMinuses(e)}
                        placeholder='0.00'
                        className='note-input'
                        value={filterData?.shippingTotalToFilter}
                        onChange={(e) =>
                          manageNumberFilters('shippingTotalToFilter', +e.target.value)
                        }
                      />
                      <span>{`USD`}</span>
                    </div>
                  ) : null}
                </div>
              </th>
              <th className='min-w-250px rounded-top-end'>
                <div className='d-flex filter-parent align-items-center'>
                  <span>Note</span>
                  <div onClick={() => _manageActiveCol('note')}>
                    {filterData?.notesFilter ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/note-fill.svg')}
                        className='icon-note'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/note.svg')} className='icon-note' />
                    )}
                  </div>
                  {activeCol === 'note' ? (
                    <div className='filter-table filter-string-input note'>
                      <input
                        type='text'
                        className='note-input'
                        value={filterData?.notesFilter}
                        onChange={(e) => manageStringFilters('notesFilter', e.target.value)}
                      />
                      <div className='search-icon'>
                        <FaSearch />
                      </div>
                      <div className='mt-4'>
                        <span>Insert the note you are looking for</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}

          {loading ? (
            <tbody className='loading'>
              <tr>
                <td>Loading...</td>
              </tr>
            </tbody>
          ) : !loading && !data.length ? (
            <tbody className='loading'>
              <tr>
                <td>There are no orders in this store</td>
              </tr>
            </tbody>
          ) : (
            <tbody>{getTableRows()}</tbody>
          )}
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* begin::Body */}
    </Container>
  )
}

export { OrderTable }

const Container = styled.div`
  // min-width:1000px;
  // overflow:auto;

  thead {
    z-index: 1;
  }

  .table-wrapper {
    height: 52vh;
    overflow: auto;
    margin: 24px 12px;
    padding-inline: 12px;

    //-------------scrollbar------------

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #e4e6ef;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #40b6f9;
    }

    scrollbar-width: 6px;
    scrollbar-height: 6px;
    scrollbar-color: #40b6f9 #e4e6ef;
  }

  .custom-tbl-head {
    background-color: #d9f1fe;
    color: #525869;
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
  }

  .custom-tbl-data {
    font-size: 14px;
  }

  .custom-tbl-row {
    height: 80px;
  }

  .custom-tbl-row:hover {
    background-color: #f3faff;
    .status-shipped {
      border: 1px solid #50cd89;
    }

    .status-unshipped {
      border: 1px solid #f1416c;
    }

    .status-pending {
      border: 1px solid #ffc700;
    }
  }

  .status-shipped,
  .status-unshipped,
  .status-pending {
    padding: 5px;
    border-radius: 5px;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .status-shipped {
    background-color: #e8fff3;
    color: #50cd89;
  }

  .status-unshipped {
    background-color: #fff5f8;
    color: #f1416c;
  }

  .status-pending {
    background-color: #fff8dd;
    color: #ffc700;
  }

  .icon {
    margin: 0 0 2px 2px;
    cursor: pointer;
  }

  .icon-date {
    margin-left: 4px;
  }

  .date-container {
    position: absolute;
    top: -2px;
    left: 4px;
    cursor: pointer;
  }

  .loading {
    font-size: 18px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .filter-parent {
    position: relative;
  }

  .filter-table {
    position: absolute;
    top: 45px;
    left: 0px;
    width: 300px;
    height: 150px;
    display: flex;
    background-color: white;
    border: 2px solid #a1a5b7;
    border-radius: 5px;
  }

  .filter-status,
  .filter-string-input,
  .filter-number-input {
    width: 200px;
    height: 110px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-bottom: 8px;

    //-------------scrollbar------------

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #e4e6ef;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #40b6f9;
    }

    scrollbar-width: 6px;
    scrollbar-height: 6px;
    scrollbar-color: #40b6f9 #e4e6ef;
  }

  .filter-string-input {
    width: 300px;
    height: 150px;
    flex-direction: column;
    height: 100px;
    align-items: center;
    input {
      margin-top: 20px;
      height: 30px;
      width: 80%;
      text-indent: 20px;
      border: 1px solid #cccccc;
    }

    input:focus {
      outline: none;
      border: 1px solid #2684ff;
      box-shadow: 0 0 1px 2px #c0e7fd;
    }

    .search-icon {
      position: absolute;
      top: 24px;
      left: 40px;
    }
  }

  .filter-number-input {
    color: black;
    font-weight: 500;
    font-size: 12px;
    width: 270px;
    overflow: auto;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    input {
      padding: 5px;
      height: 30px;
      font-size: 13px;
      width: 30%;
    }

    // /* Chrome, Safari, Edge, Opera */
    // input::-webkit-outer-spin-button,
    // input::-webkit-inner-spin-button {
    //   -webkit-appearance: none;
    //   margin: 0;
    // }

    // /* Firefox */
    // input[type='number'] {
    //   -moz-appearance: textfield;
    // }
  }

  .note {
    left: -100px;
  }

  .note-input {
    border-radius: 5px;
    padding: 4px 8px;
    border: 1px solid #cccccc;
    font-size: 14px;
  }

  .note-input:focus {
    outline: none;
    border: 1px solid #2684ff;
    box-shadow: 0 0 1px 2px #c0e7fd;
  }

  .custom-btn {
    background-color: #009ef7;
    color: #ffffff;
    padding: 4px 12px;
    font-size: 13px;
    font-weight: 400;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }

  .custom-btn-primary {
    background-color: #009ef7;
    color: #ffffff;
  }

  .custom-btn-secondary {
    background-color: #e4e6ef;
    color: #000000;
  }

  .custom-btn-primary:hover {
    background-color: #80cffb;
    color: #ffffff;
  }

  .custom-btn-secondary:hover {
    background-color: #f3faff;
    color: #009ef7;
  }

  .custom-btn-primary:active {
    background-color: #037fc5;
    color: #ffffff;
  }

  .custom-btn-secondary:active {
    background-color: #a9dffc;
    color: #009ef7;
  }

  .note-span,
  .add-note-span {
    cursor: pointer;
  }

  .add-note-span {
    color: #a1a5bd;
  }

  .note-span {
    color: #009ef7;
  }

  .note-span:hover {
    text-decoration: underline;
  }

  .note-td {
    white-space: normal;
    word-break: break-all;
  }

  .icon-note {
    margin: 0 0 3px 3px;
    // color: #000000;
  }

  .rounded-top-start {
    border-top-left-radius: 8px;
  }

  .rounded-top-end {
    border-top-right-radius: 8px;
  }
`
