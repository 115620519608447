import moment from 'moment'
import { FC } from 'react';
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PlanOptionType } from './AccountCreation'

type Props = {
  plan: PlanOptionType
}

const SubscriptionPlan: FC<Props> = ({ plan }) => {
  return (
    <Container>
      <div className='image'>
        <img
          src={toAbsoluteUrl(`/media/downloaded-images/${plan?.listingsAmount || '5000'}-plan.png`)}
          width={50}
        />
      </div>
      <div className='plan-wrapper'>
        <div className='plan-info'>
          <span className='plan-label'>SELECTED SUBSCRIPTION PLAN</span>
          <span className='plan-title'>{plan?.name}</span>
          <span className='plan-count'>{plan?.listingsAmount.toLocaleString()} Listings</span>
        </div>
        <div className='plan-title-date-wrapper'>
          {/*<span className='plan-date'>{`Free till ${moment(new Date()).format('l')}`}</span>*/}
          {/*  <span className='plan-quantity'>Listings: {plan?.listingsAmount}</span>*/}
        </div>
      </div>
    </Container>
  )
}

export { SubscriptionPlan }
const Container = styled.div`
  display: flex;
  border: 1px solid #cdcfda;
  border-radius: 8px;
  padding: 24px;

  .image {
    padding-right: 12px;
  }

  .plan-wrapper {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .plan-label {
    font-size: 10px;
    font-weight: 700;
    color: #009ef7;
  }

  .plan-title {
    font-size: 24px;
    font-weight: 700;
  }

  .plan-count {
    font-size: 16px;
    font-weight: 300;
  }

  .plan-title-date-wrapper {
    display: flex;
    align-items: center;
  }
    .plan-quantity {
      
    }

  .plan-date {
    margin: 0 0 0 10px;
    background-color: #e6f5fe;
    padding: 3px 16px;
    display: flex;
    border-radius: 30px;
    align-items: center;
    color: #40b6f9;
    font-size: 10px;
    font-weight: 500;
    align-self: start;
  }
`
