import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {marketPlaceApis} from '../../../setup/apis/marketplaces/marketPlaceApis'
import {storeApis} from '../../../setup/apis/store/storeApis'
import {planApis} from '../../../setup/apis/subscription-plan/planApis'
import {
  MarketplaceType,
  StoreType,
} from '../../../setup/types/response-data-types/ResponseDataTypes'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {triggerAlert} from '../../components/Alert'
import {ButtonPrimary} from '../../components/CustomStyledComponents'
import Select from '../../components/Select'
import {getPlans} from '../../modules/redux-toolkit/planSlicer'
import {getToken} from '../../modules/redux-toolkit/userSlicer'
import {planSelector} from '../../modules/selectors/reduxSelectors'
import {AUTH_STATUS, deleteUserDataForReg} from '../../utils/authUtils'
import {capitalize, preventSpaces} from '../../utils/inputFieldUtils'
import {loadFromSessionStorage} from '../../utils/sessionStorageUtils'
import {PlanOptionType} from './AccountCreation'
import {PlanSelect} from './PlanSelect'
import {SubscriptionPlan} from './SubscriptionPlan'
import {PaymentMethod} from "./PaymentMethod";

type Props = {
  plan?: PlanOptionType
  token: string
}

const AddStore: FC<Props> = ({token, plan}) => {
  const planOptions = useSelector(planSelector)
  const [label, setLabel] = useState<string>('')
  const [selectedPlan, setSelectedPlan] = useState<PlanOptionType>(planOptions?.[0])
  const [marketplaces, setMarketplaces] = useState<MarketplaceType[]>([])
  const [marketplace, setMarketplace] = useState<MarketplaceType>(marketplaces?.[0])
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const history =
    useHistory<{from?: string; store?: StoreType; plan?: PlanOptionType; url?: string}>()

  useEffect(() => {
    _getPlans()
    if (!history.location?.state?.store) {
      _getMarketPlaces()
    }
  }, [])

  useEffect(() => {
    if (history.location?.state?.from) {
      setLabel(history.location?.state?.store?.label || '')
    }
  }, [])

  async function _getMarketPlaces() {
    const response = await marketPlaceApis.getAll(token)
    setMarketplaces(response?.data)
    setMarketplace(response?.data[0])
  }

  async function _getPlans() {
    const store = history.location?.state?.store
    if (store?.selectedSubscriptionPlanId) {
      plan = planOptions.find((p) => p.id === store.selectedSubscriptionPlanId)
    }
    setSelectedPlan((prev) => history.location?.state?.plan || plan || planOptions?.[0])
  }

  async function pay() {
    try {
      setLoading(true)
      if (history.location?.state?.store?.label === label) {
        if (history.location?.state?.store?.subscriptionPlanId) {
          const response = await storeApis.changePlan(
            history.location?.state?.store?.storeId,
            selectedPlan.id
          )
          if (response?.status === 202) {
            history.goBack()
          }
        } else {
          const successUrl = `${process.env.REACT_APP_PUBLIC_URL}/stores/successfully-created`
          const failedUrl = `${process.env.REACT_APP_PUBLIC_URL}/settings/account-settings`
          const response = await storeApis.addSubscription(
            history.location?.state?.store?.storeId,
            selectedPlan.id,
            successUrl,
            failedUrl
          )
          if (response?.data && typeof response?.data === 'string') {
            history.push('/stripe-checkout', {url: response?.data})
          }
        }
      } else {
        const response = await storeApis.create(
          {
            label,
            marketplaceCode: marketplace?.marketplaceId,
            subscriptionPlanId: selectedPlan.id,
            successCallbackUrl: `${process.env.REACT_APP_PUBLIC_URL}/stores/successfully-created`,
            cancelCallbackUrl: `${process.env.REACT_APP_PUBLIC_URL}/settings/account-settings`,
          },
          token
        )
        if (response?.data && typeof response?.data === 'string') {
          token && dispatch(getToken(loadFromSessionStorage('login_data')))
          token && localStorage.setItem('authStatus', AUTH_STATUS.loggedIn)
          deleteUserDataForReg()
          history.push('/stripe-checkout', {url: response?.data})
        }
      }
    } catch (e: any) {
      if (e?.response?.status === 400) {
        triggerAlert(e?.response?.data?.Message, 'error')
      }
    } finally {
      setLoading(false)
    }
  }

  function isInvalid() {
    const {state} = history.location
    return !label || (state?.store && state?.plan?.id === selectedPlan?.id)
  }

  return (
    <Container from={history.location?.state?.from}>
      <div className='upper-side'>
        <div className='selected-plan'>
          <div className='form-row'>
            <PaymentMethod />
          </div>
          <h2 className='choose-plan'>Choose a plan for your store</h2>
          <SubscriptionPlan plan={selectedPlan} />
        </div>
        <div className='inputs-wrapper'>
          <div className='input-wrapper'>
            <label className='input-label'>Store title</label>
            <div className='icon-container'>
              <input
                type='text'
                placeholder='Insert your store title'
                autoComplete='off'
                value={label}
                disabled={!!history.location.state?.store}
                className={'input-value input-cap'}
                onChange={(e) => setLabel(capitalize(e.target.value))}
              />
              {label && !!!history.location.state?.store ? (
                <div className='icon' onClick={() => setLabel('')}>
                  <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
                </div>
              ) : null}
            </div>
          </div>
          {!history.location?.state?.store ? (
            <div className='input-wrapper'>
              <label className='bold-label'>Marketplace</label>
              <Select
                name='marketplace'
                value={marketplace}
                onChange={(option) => setMarketplace(option)}
                options={marketplaces}
                fontSize='14px'
                withRs
              />
            </div>
          ) : null}
          <div className='input-wrapper'>
            <label className='input-label'>Select subscription plan</label>
            <PlanSelect
              options={planOptions}
              value={selectedPlan}
              onChange={(option) => setSelectedPlan(option)}
              inputClassName={'input-value'}
            />
          </div>
        </div>
        <hr className='m-0' />
        <div className='footer-summary'>
          <span>Due Now</span>
          <span>{`$${Math.floor(selectedPlan?.priceInCents / 100) || 0} US$/mo`}</span>
        </div>
      </div>
      <div className='summary-info'>
        <span>
          By placing this order, you get 1 store with {selectedPlan?.name.toLowerCase()} for 1
          month.
        </span>
        <span>You can change number of listings and add more stores in the future</span>
        <span>in the account settings section.</span>
      </div>
      <ButtonPrimary className='px-12 align-self-start' onClick={pay} disabled={isInvalid()}>
        Pay
        {loading ? (
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        ) : null}
      </ButtonPrimary>
    </Container>
  )
}

export default AddStore

const Container = styled.div<any>(
  ({from}) => `
  display: flex;
  flex-direction: column;

  ${
    from
      ? `width:600px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
    border-radius:8px;
    margin:auto;
    padding:24px;
    margin-bottom:48px;`
      : ''
  }

  .upper-side {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    border: 1px solid #cdcfda;
    border-radius: 8px;
  }

  .choose-plan {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 12px;
  }

  .inputs-wrapper,
  .selected-plan {
    padding: 0 24px 16px 24px;
  }

  .input-wrapper {
    padding-top: 24px;
  }

  .input-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .input-value {
    display: block;
    width: 100%;
    border: 1px solid #cdcfda;
    border-radius: 8px;
    padding: 11px;
    font-size: 14px;
    font-weight: 500;
    color: #3f4254;
  }

  .input-value:focus,
  .input-invalid:focus {
    outline: none;
    box-shadow: 0 0 1px 3px #c0e7fd;
    border: 1px solid #2684ff;
  }

  .footer-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    padding: 24px 24px 0 24px;
  }

  .icon-container {
    position: relative;
  }

  .icon {
    position: absolute;
    top: 13px;
    right: 12px;
    cursor: pointer;
  }

  .bold-label {
    font-size: 14px;
    font-weight: 600;
    color: #3f4254;
    white-space: nowrap;
    margin-bottom: 4px;
  }

  .input-cap {
    text-transform: capitalize;
    
    //placeholder
    ::-webkit-input-placeholder {
      text-transform: none;
    }
    :-moz-placeholder { 
        text-transform: none;
    }
    ::-moz-placeholder { 
        text-transform: none;
    }
    :-ms-input-placeholder { 
        text-transform: none;
    }
    ::placeholder { 
        text-transform: none;
    }
  }

  .summary-info {
    display: flex;
    flex-direction: column;
    color: #a1a5bd;
    font-size: 14px;
    font-weight: 400;
    margin-block: 24px;
  }
`
)
