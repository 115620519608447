import {FC, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useHistory} from 'react-router-dom'
import {useOnClickOutside} from '../../utils/customHooks'
import {PlanOptionType} from './AccountCreation'

type Props = {
  inputClassName: string
  dropdownClassName?: string
  options: PlanOptionType[]
  value: PlanOptionType
  onChange: (opion: PlanOptionType) => void
}

const PlanSelect: FC<Props> = ({onChange, inputClassName, dropdownClassName, options, value}) => {
  const [searchText, setSearchText] = useState<string>('')
  const [inputValue, setInputValue] = useState<string>(value?.name)
  const [chevron, setChevron] = useState<string>('')
  const [visible, setVisible] = useState<boolean>(false)
  const history = useHistory()
  const containerRef = useRef<any>()
  const inputRef = useRef<any>()
  const inputValueRef = useRef<string>(value?.name)
  function selectOption(option: PlanOptionType) {
    setInputValue(option?.name)
    inputValueRef.current = option?.name
    setVisible(false)
    onChange(option)
    setChevron('')
  }

  useOnClickOutside(containerRef, () => {
    setVisible(false)
    setChevron('')
    setSearchText('')
    if (
      !inputValue ||
      !options.filter((item) =>
        item.name
          ?.replaceAll(',', '')
          ?.toLowerCase()
          .includes(inputValue?.replaceAll(',', '')?.toLowerCase())
      )?.length
    ) {
      setInputValue(inputValueRef.current)
    }
  })

  useEffect(() => {
    setInputValue(value?.name)
    if (value?.name) {
      inputValueRef.current = value?.name
    }
  }, [value])

  function getOptions() {
    const opts = options.filter((item) =>
      item.name
        ?.replaceAll(',', '')
        ?.toLowerCase()
        .includes(searchText?.replaceAll(',', '')?.toLowerCase())
    )

    if (!opts?.length) {
      return (
        <div className='plan-option no-option'>
          <span>{'No options'}</span>
        </div>
      )
    }

    return opts.map((item, index) => (
      <div className='plan-option' onClick={() => selectOption(item)} key={index}>
        <span>{item.name}</span>
        <span>{item.listingsAmount.toLocaleString()} Listings {`$${Math.floor(item.priceInCents / 100)} Monthly`}</span>
      </div>
    ))
  }

  function _onDropdownOpen() {
    setVisible(true)
    setChevron('-dark')
    setInputValue('')
  }

  function _onInputChange(e: any) {
    setSearchText(e.target.value)
    setInputValue(e.target.value)
  }

  return (
    <Container ref={containerRef}>
      <div className='input-container'>
        <input
          ref={inputRef}
          className={`${inputClassName} custom-input ${
            inputValueRef.current ? ' non-empty-ref ' : ''
          }`}
          value={inputValue}
          placeholder={inputValueRef?.current || `Select subscription plan...`}
          onClick={_onDropdownOpen}
          onChange={(e) => _onInputChange(e)}
        />
        <div className='chevron-icon'>
          <img src={toAbsoluteUrl(`/media/svg/misc/chevron-down${chevron}.svg`)} />
        </div>
      </div>
      {visible ? <div className='option-container'>{getOptions()}</div> : null}
    </Container>
  )
}

export {PlanSelect}

const Container = styled.div`
  position: relative;
  .input-container {
    position: relative;
  }

  .custom-input {
    cursor: pointer;
  }

  .chevron-icon {
    position: absolute;
    top: 13px;
    right: 13px;
    color: #cccccc;
  }

  .option-container {
    box-sizing: border-box;
    position: absolute;
    top: 52px;
    background-color: #f0f4f8;
    border-radius: 4px;
    border: 1px solid #cdcfda;
    max-height: 220px;
    overflow: auto;
    width: 100%;
    z-index: 4;
  }

  .plan-option {
    display: flex;
    justify-content: space-between;
    background-color: #f0f4f8;
    color: #3f4254;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
  }

  .no-option {
    justify-content: center;
    color: #999eb5;
    font-size: 13px;
  }

  .plan-option:hover {
    background-color: #e6f5fe;
    color: #3f4254;
    cursor: default;
  }

  .custom-link {
    color: #009ef7;
    cursor: pointer;
  }

  .non-empty-ref {
    ::-webkit-input-placeholder {
      color: #3f4254;
    }

    :-ms-input-placeholder {
      color: #3f4254;
    }

    ::placeholder {
      color: #3f4254;
    }
  }
`
