import {createRef, FC, forwardRef, useImperativeHandle} from 'react'
import styled from 'styled-components'
import ReactSelect, {GroupBase, MenuPlacement} from 'react-select'

type Props = {
    className?: string
    name?: string
    id?: string
    value: any
    onChange?: (e: any) => void
    options: any
    withRs?: boolean
    placeholder?: string
    menuPlacement?: MenuPlacement | undefined
    customControl?: boolean
    ref?: any
    forPaging?: boolean
    withSearch?: boolean
    disabled?: boolean
    fontSize?: string
}

const AdditionalSettingsSelect: FC<Props> = forwardRef(
    (
        {
            options,
            className,
            name,
            value,
            onChange,
            withRs,
            placeholder = 'Select store...',
            menuPlacement = 'bottom',
            customControl = false,
            forPaging,
            withSearch,
            disabled,
            fontSize,
        },
        ref
    ) => {
        const customStyles = {
            option: (base: any, state: any) => ({
                ...base,
                fontSize: fontSize || `${forPaging ? '10px' : '12px'}`,
                backgroundColor: '#f0f4f8',
                color: '#3f4254',
                '&:hover': {
                    backgroundColor: '#e6f5fe',
                    color: '#000000',
                },
            }),
            input: (base: any) => ({
                ...base,
                padding: '1 0',
                fontSize: '16px',
                textIndent: withSearch ? '25px' : '0',
            }),
            placeholder: (base: any) => ({
                ...base,
                fontSize: fontSize || `${forPaging ? '11px' : '11px'}`,
                fontWeight: 400,
            }),
            singleValue: (base: any) => ({
                ...base,
                fontSize: fontSize || `${forPaging ? '12px' : '13px'}`,
                fontWeight: 500,
                borderRadius: '25px',
            }),
            control: (base: any, state: any) => ({
                ...base,
                borderRadius: '8px',
                boxShadow: state.isFocused ? '0 0 1px 3px #c0e7fd' : 'none',
                opacity: customControl ? 0 : 1,
                cursor: 'pointer',
                // height: `${forPaging && '35px'}`,
                // minHeight: `${forPaging && '35px'}`,
                textIndent: withSearch ? '25px' : '0',
            }),
            menu: (provided: any, state: any) => ({
                ...provided,
                zIndex: 5,
                backgroundColor: '#f0f4f8',
            }),
        }
        const selectRef = createRef<any>()

        useImperativeHandle(ref, () => ({
            _focus: () => selectRef.current.focus(),
        }))

        function getOptions(): any {
            return options.map((option: any, index: any) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))
        }

        if (withRs) {
            return (
                <ContainerDiv withSearch={withSearch}>
                    <ReactSelect
                        ref={selectRef}
                        openMenuOnFocus={true}
                        name={name}
                        value={value}
                        options={options}
                        onChange={onChange}
                        styles={customStyles}
                        placeholder={placeholder}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        menuPlacement={menuPlacement}
                        maxMenuHeight={150}
                        isDisabled={disabled}
                    />
                </ContainerDiv>
            )
        }

        return (
            <Container name={name} value={value} onChange={onChange} className={className}>
                {getOptions()}
            </Container>
        )
    }
)

export default AdditionalSettingsSelect

const Container = styled.select``

const ContainerDiv = styled.div<any>(
    ({withSearch}) => `
  cursor: pointer;
  width: 100%;

  input {
    text-indent: ${withSearch ? '25px' : '0'};
  }
`
)
