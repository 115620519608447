import {FC, useEffect, useState} from 'react'
import {FaChevronLeft} from 'react-icons/fa'
import {useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {ButtonSecondary, CustomHeader} from '../../components/CustomStyledComponents'
import {planSelector} from '../../modules/selectors/reduxSelectors'
import {loadFromSessionStorage, saveToSessionStorage} from '../../utils/sessionStorageUtils'
import {AccountInfo} from './AccountInfo'
import AddStore from './AddStore'
import {CreateAccount} from './CreateAccount'
import {PaymentProcessing} from './PaymentProcessing'

export type DetailsType = {
  username: string
  firstName: string
  lastName: string
  email: string
  password?: string
  confirmPassword?: string
  currentPassword?: string
  newPassword?: string
}

export type PlanOptionType = {
  value: string
  active: boolean
  currency: string
  id: string
  listingsAmount: number
  name: string
  priceInCents: number
}

export const initialDetails = {
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
}

const AccountCreation: FC = () => {
  const [saved, setSaved] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)
  const [data, setData] = useState<DetailsType>(initialDetails)
  const planOptions = useSelector(planSelector)
  const history = useHistory<{plan?: PlanOptionType}>()
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    const userDetails = loadFromSessionStorage('userDetails')
    if (userDetails) {
      setData(userDetails)
      setSaved(true)
    }
    const token = loadFromSessionStorage('login_data')?.access_token
    if (token) {
      setToken(token)
    }
  }, [])

  function _handleSaved(saved: boolean, data?: DetailsType) {
    setSaved((prev) => saved)
    if (data) {
      setData((prev) => data)
      saveToSessionStorage('userDetails', data);
      history.push('/stores/store-list');
    }
  }

  return (
    <Container className='card'>
      <div className='card-header align-items-center pe-8'>
        <CustomHeader className='mx-0'>
          {processing ? 'Order Summary' : 'Create Account'}
        </CustomHeader>
        <ButtonSecondary className="" onClick={() => history.goBack()}>
          <FaChevronLeft /><span className="d-none d-sm-inline">Back</span>
        </ButtonSecondary>
      </div>
      <hr className='m-0' />
      {processing ? (
        <PaymentProcessing goBack={() => setProcessing(false)} />
      ) : (
        <div className='creation-body'>
          <div className='left-side no-border'>
            {saved ? (
              <AccountInfo handleSaved={_handleSaved} values={data} />
            ) : (
              <CreateAccount
                handleSaved={_handleSaved}
                values={data}
                token={token}
                manageToken={(token: string) => setToken(token)}
              />
            )}
          </div>
          {saved ? (
            <div className='d-none d-lg-block right-side'>
            </div>
          ) : (
            // Showing extra column only on big screens
            <div className='d-none d-lg-block right-side no-border' />
          )}
        </div>
      )}
    </Container>
  )
}

export {AccountCreation}

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  margin-bottom: 48px;

  .creation-body {
    width: 100%;
    display: flex;
  }

  .left-side,
  .right-side {
    width: 100%;
    border-left: 1px solid #cdcfda;
  }

  .no-border {
    border: none;
  }

  .right-side {
    padding: 24px;
  }
`
