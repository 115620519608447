import { FC } from 'react';
import styled from 'styled-components'
import { useHistory } from 'react-router-dom';
import { OrderInfoType, StoreType } from '../../../setup/types/response-data-types/ResponseDataTypes'

type Props = {
  info: OrderInfoType
  selectedStore: StoreType
}

const OrdersListInfo: FC<Props> = ({ info, selectedStore }) => {
  const history = useHistory<{ data: StoreType }>()

  function goToOrders() {
    history?.push('/orders/order-list', { data: selectedStore })
  }

  return (
    <Container>
      <div className='product-info'>
        <div className='header-info'>
          <span className='header-name'>Orders List</span>
          <span className='store-name'>Store: {selectedStore?.label}</span>
        </div>
        <span onClick={goToOrders} className='custom-link'>
          <span>View details</span>
        </span>
      </div>
      <div className='product-info'>
        <div className='product-info-label'>
          <span>Total amount of orders</span>
        </div>
        <div className='product-info-value'>
          <span>{info?.totalAmountCount}</span>
        </div>
      </div>
      <hr className='custom-hr' />
      <div className='product-info'>
        <div className='product-info-label'>
          <span>Number of shipped orders</span>
        </div>
        <div className='product-info-value'>
          <span>{info?.shippedCount}</span>
        </div>
      </div>
      <hr className='custom-hr' />
      <div className='product-info'>
        <div className='product-info-label'>
          <span>Number of unshipped orders</span>
        </div>
        <div className='product-info-value'>
          <span>{info?.unshippedCount}</span>
        </div>
      </div>
      <hr className='custom-hr' />
      <div className='product-info'>
        <div className='product-info-label'>
          <span>Number of pending orders</span>
        </div>
        <div className='product-info-value'>
          <span>{info?.pendingCount}</span>
        </div>
      </div>
    </Container>
  )
}

export { OrdersListInfo }

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: -4px -4px 12px rgba(120, 146, 165, 0.05), 4px 4px 12px rgba(120, 146, 165, 0.05);
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  margin: 24px 0 0 0;

  .product-info {
    display: flex;
    justify-content: space-between;
  }

  .product-info:first-child {
    margin-bottom: 24px;
  }

  .header-info {
    display: flex;
    flex-direction: column;
    marfgin-bottom: 24px;
  }

  .header-name {
    font-size: 20px;
    font-weight: 700;
  }

  .store-name {
    font-size: 12px;
    font-weight: 400;
  }

  .custom-link {
    color: #009ef7;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .product-info-label {
    color: #252733;
    font-size: 14px;
    font-weight: 500;
  }

  .product-info-value {
    color: #9fa2b4;
    font-size: 14px;
    font-weight: 500;
  }

  .custom-hr {
    border-top: 1px dashed #d9dbe3;
  }
`
