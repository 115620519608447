import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { twj } from 'tw-to-css';

type Props = {
    title?: string;
    content?: string;
};

export const Pricing: FC<Props> = ({ title, content }) => {
    const history = useHistory();

    return (
        // <!-- Pricing plans section -->
            <section className="bg-white py-20 lg:py-[107px]">
                <div className="mx-auto w-5/6 max-w-[1700px]">
                    <h2 className="text-center text-[35px] font-bold font-['Inter'] leading-9 lg:leading-[43.05px]">Flexible
                        plans for your business size and needs</h2>
                    <p
                        className="mt-[15px] text-center text-slate-500 text-lg font-normal font-['Inter'] leading-6 lg:leading-7"></p>
                    {/* <!-- Pricing plans grid --> */}
                    <div className="space-y-[10px]  lg:space-y-0 flex flex-wrap lg:flex-nowrap justify-center items-start gap-[10px] mt-[35px]">
                        {/* <!-- Pricing plan card: Starter --> */}
                        <div
                            className="cursor-pointer tw-transition tw-duration-500 tw-ease-in-out hover:scale-105 font-['Inter']  max-w-[419.33px] h-[585px] p-8 bg-white rounded-[15px] shadow border border-black border-opacity-5 flex-col justify-between items-start gap-12 inline-flex">
                            {/* <!-- Icon --> */}
                            <div className="w-10 h-10"><img className="w-[40px] h-[40px]" src="./assets/img/Gem.svg" /></div>
                            {/* <!-- Plan details--> */}
                            <div className="h-[328px] flex flex-col justify-between">
                                <h3 className="text-zinc-900 text-[25px] font-bold leading-relaxed">Free Starter</h3>
                                <p className="text-gray-500 text-lg font-normal leading-[25.20px]">Introduction Plan</p>
                                <p className="text-zinc-900 text-base font-bold leading-snug">What's included:
                                </p>
                                <ul className="list-inside list-none text-zinc-900 text-base font-semibold leading-snug m-0 p-0">
                                    {/*<li className="inline-flex items-center gap-2"><img className="inline-block"*/}
                                    {/*        src="./assets/img/checkmark.svg" />Introduction Plan</li>*/}
                                    <li className="inline-flex items-center gap-2"><img className="inline-block"
                                            src="./assets/img/checkmark.svg" />Access to all features</li>
                                    <li className="inline-flex items-center gap-2"><img className="inline-block"
                                            src="./assets/img/checkmark.svg" />Up to 10,000 Active listings</li>
                                    <li className="inline-flex items-center gap-2"><img className="inline-block"
                                            src="./assets/img/checkmark.svg" />Email support</li>
                                </ul>
                                <p><span className="text-sky-500 text-[25px] font-semibold leading-7 line-through">$0/</span><span
                                        className="text-sky-500 text-lg font-semibold leading-tight line-through">Month</span></p>
                                <p className="text-blue-950 text-lg font-medium font-['Inter'] leading-[25.20px]">2% of
                                    Sales</p>
                            </div>
                            {/* <!-- CTA Button --> */}
                            <div className="w-full">
                                <button type="button"
                                 onClick={() => history.push('/registration/create-account')}
                                 className="hover:bg-[#1D1D25] hover:text-white hover:border-none"
                                 style={twj("font-['Inter'] w-full text-sm uppercase font-medium leading-6 rounded-3xl text-sky-500 border border-sky-500 inline-flex items-center justify-center gap-x-2 px-6 py-3")}>
                                    Get started
                                    <div className="h-[20px] w-[20px] inline-flex items-center justify-center">
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.16732 11.3346H14.0548L11.0273 14.968C10.7323 15.3213 10.7807 15.8471 11.134 16.1413C11.4882 16.4363 12.0132 16.388 12.3082 16.0346L16.4748 11.0346C16.5073 10.9955 16.5232 10.9496 16.5473 10.9063C16.5673 10.8713 16.5915 10.8413 16.6065 10.803C16.644 10.7071 16.6665 10.6063 16.6665 10.5046C16.6665 10.5038 16.6673 10.5021 16.6673 10.5013C16.6673 10.5005 16.6665 10.4988 16.6665 10.498C16.6665 10.3963 16.644 10.2955 16.6065 10.1996C16.5915 10.1613 16.5673 10.1313 16.5473 10.0963C16.5232 10.053 16.5073 10.0071 16.4748 9.96797L12.3082 4.96797C12.1423 4.77047 11.9057 4.66797 11.6673 4.66797C11.479 4.66797 11.2898 4.7313 11.134 4.8613C10.7807 5.15547 10.7323 5.6813 11.0273 6.03464L14.0548 9.66797H4.16732C3.70732 9.66797 3.33398 10.0413 3.33398 10.5013C3.33398 10.9613 3.70732 11.3346 4.16732 11.3346Z"
                                                fill="currentColor" />
                                            <mask id="mask0_1_3335" style={{maskType: "luminance"}}
                                                maskUnits="userSpaceOnUse" x="3" y="4" width="14" height="13">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M4.16732 11.3346H14.0548L11.0273 14.968C10.7323 15.3213 10.7807 15.8471 11.134 16.1413C11.4882 16.4363 12.0132 16.388 12.3082 16.0346L16.4748 11.0346C16.5073 10.9955 16.5232 10.9496 16.5473 10.9063C16.5673 10.8713 16.5915 10.8413 16.6065 10.803C16.644 10.7071 16.6665 10.6063 16.6665 10.5046C16.6665 10.5038 16.6673 10.5021 16.6673 10.5013C16.6673 10.5005 16.6665 10.4988 16.6665 10.498C16.6665 10.3963 16.644 10.2955 16.6065 10.1996C16.5915 10.1613 16.5673 10.1313 16.5473 10.0963C16.5232 10.053 16.5073 10.0071 16.4748 9.96797L12.3082 4.96797C12.1423 4.77047 11.9057 4.66797 11.6673 4.66797C11.479 4.66797 11.2898 4.7313 11.134 4.8613C10.7807 5.15547 10.7323 5.6813 11.0273 6.03464L14.0548 9.66797H4.16732C3.70732 9.66797 3.33398 10.0413 3.33398 10.5013C3.33398 10.9613 3.70732 11.3346 4.16732 11.3346Z"
                                                    fill="white" />
                                            </mask>
                                            <g mask="url(#mask0_1_3335)">
                                                <rect y="0.5" width="20" height="20" fill="currentColor" />
                                            </g>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {/* <!-- Pricing plan card: Professional --> */}
                        <div
                            className="cursor-pointer tw-transition tw-duration-500 tw-ease-in-out hover:scale-105  font-['Inter'] max-w-[419.33px] h-[585px] p-8 bg-white rounded-[15px] shadow border border-black border-opacity-5 flex-col justify-between items-start gap-12 inline-flex">
                            {/* <!-- Icon --> */}
                            <div className="w-10 h-10"><img className="w-[40px] h-[40px]" src="./assets/img/Diamond.svg" />
                            </div>
                            {/* <!-- Plan details--> */}
                            <div className="h-[328px] flex flex-col justify-between">
                                <h3 className="text-zinc-900 text-[25px] font-bold leading-relaxed">Professional</h3>
                                <p className="text-gray-500 text-lg font-normal leading-[25.20px]">Designed for growing businesses</p>
                                <p className="text-zinc-900 text-base font-bold leading-snug">What's included:
                                </p>
                                <ul className="list-inside list-none text-zinc-900 text-base font-semibold leading-snug m-0 p-0">
                                    <li className="inline-flex items-center gap-2"><img className="inline-block"
                                            src="./assets/img/checkmark.svg" />Access to all features</li>
                                    <li className="inline-flex items-center gap-2"><img className="inline-block"
                                            src="./assets/img/checkmark.svg" />Up to 100,000 Active listings</li>
                                    <li className="inline-flex items-center gap-2"><img className="inline-block"
                                            src="./assets/img/checkmark.svg" />Priority Email support</li>
                                </ul>
                                <p><span className="text-sky-500 text-[25px] font-semibold leading-7">$49/</span><span
                                        className="text-sky-500 text-lg font-semibold leading-tight">Month</span></p>
                                <p className="text-blue-950 text-lg font-medium font-['Inter'] leading-[25.20px]">2% of
                                    Sales</p>
                            </div>
                            {/* <!-- CTA Button --> */}
                            <div className="w-full">
                                <button type="button"
                                 onClick={() => history.push('/registration/create-account')}
                                 className="hover:bg-[#1D1D25] hover:text-white hover:border-none"
                                    style={twj("font-['Inter'] w-full text-sm uppercase font-medium leading-6 rounded-3xl text-sky-500 border border-sky-500 inline-flex items-center justify-center gap-x-2 px-6 py-3")}>
                                    Get started
                                    <div className="h-[20px] w-[20px] inline-flex items-center justify-center">
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.16732 11.3346H14.0548L11.0273 14.968C10.7323 15.3213 10.7807 15.8471 11.134 16.1413C11.4882 16.4363 12.0132 16.388 12.3082 16.0346L16.4748 11.0346C16.5073 10.9955 16.5232 10.9496 16.5473 10.9063C16.5673 10.8713 16.5915 10.8413 16.6065 10.803C16.644 10.7071 16.6665 10.6063 16.6665 10.5046C16.6665 10.5038 16.6673 10.5021 16.6673 10.5013C16.6673 10.5005 16.6665 10.4988 16.6665 10.498C16.6665 10.3963 16.644 10.2955 16.6065 10.1996C16.5915 10.1613 16.5673 10.1313 16.5473 10.0963C16.5232 10.053 16.5073 10.0071 16.4748 9.96797L12.3082 4.96797C12.1423 4.77047 11.9057 4.66797 11.6673 4.66797C11.479 4.66797 11.2898 4.7313 11.134 4.8613C10.7807 5.15547 10.7323 5.6813 11.0273 6.03464L14.0548 9.66797H4.16732C3.70732 9.66797 3.33398 10.0413 3.33398 10.5013C3.33398 10.9613 3.70732 11.3346 4.16732 11.3346Z"
                                                fill="currentColor" />
                                            <mask id="mask0_1_3335" style={{maskType: "luminance"}}
                                                maskUnits="userSpaceOnUse" x="3" y="4" width="14" height="13">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M4.16732 11.3346H14.0548L11.0273 14.968C10.7323 15.3213 10.7807 15.8471 11.134 16.1413C11.4882 16.4363 12.0132 16.388 12.3082 16.0346L16.4748 11.0346C16.5073 10.9955 16.5232 10.9496 16.5473 10.9063C16.5673 10.8713 16.5915 10.8413 16.6065 10.803C16.644 10.7071 16.6665 10.6063 16.6665 10.5046C16.6665 10.5038 16.6673 10.5021 16.6673 10.5013C16.6673 10.5005 16.6665 10.4988 16.6665 10.498C16.6665 10.3963 16.644 10.2955 16.6065 10.1996C16.5915 10.1613 16.5673 10.1313 16.5473 10.0963C16.5232 10.053 16.5073 10.0071 16.4748 9.96797L12.3082 4.96797C12.1423 4.77047 11.9057 4.66797 11.6673 4.66797C11.479 4.66797 11.2898 4.7313 11.134 4.8613C10.7807 5.15547 10.7323 5.6813 11.0273 6.03464L14.0548 9.66797H4.16732C3.70732 9.66797 3.33398 10.0413 3.33398 10.5013C3.33398 10.9613 3.70732 11.3346 4.16732 11.3346Z"
                                                    fill="white" />
                                            </mask>
                                            <g mask="url(#mask0_1_3335)">
                                                <rect y="0.5" width="20" height="20" fill="currentColor" />
                                            </g>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {/* <!-- Pricing plan card: Enterprise --> */}
                        <div
                            className="cursor-pointer tw-transition tw-duration-500 tw-ease-in-out hover:scale-105 font-['Inter'] max-w-[419.33px] h-[585px] p-8 bg-white rounded-[15px] shadow border border-black border-opacity-5 flex-col justify-between items-start gap-12 inline-flex">
                            {/* <!-- Icon --> */}
                            <div className="w-10 h-10"><img className="w-[40px] h-[40px]" src="./assets/img/Gem.svg" /></div>
                            {/* <!-- Plan details--> */}
                            <div className="h-[328px] flex flex-col justify-between">
                                <h3 className="text-zinc-900 text-[25px] font-bold leading-relaxed">Enterprise</h3>
                                <p className="text-gray-500 text-lg font-normal leading-[25.20px]">Tailored for established businesses</p>
                                <p className="text-zinc-900 text-base font-bold leading-snug">What's included:
                                </p>
                                <ul className="list-inside list-none text-zinc-900 text-base font-semibold leading-snug m-0 p-0">
                                    <li className="inline-flex items-center gap-2"><img className="inline-block"
                                            src="./assets/img/checkmark.svg" />Access to all features</li>
                                    <li className="inline-flex items-center gap-2"><img className="inline-block"
                                            src="./assets/img/checkmark.svg" />Up to 1,000,000 Active listings</li>
                                    <li className="inline-flex items-center gap-2"><img className="inline-block"
                                            src="./assets/img/checkmark.svg" />Dedicated account manager</li>
                                    <li className="inline-flex items-center gap-2"><img className="inline-block"
                                            src="./assets/img/checkmark.svg" />Priority Email support</li>
                                </ul>
                                <p><span className="text-sky-500 text-[25px] font-semibold leading-7">$499/</span><span
                                        className="text-sky-500 text-lg font-semibold leading-tight">Month</span></p>
                                <p className="text-blue-950 text-lg font-medium font-['Inter'] leading-[25.20px]">2% of
                                    Sales</p>
                            </div>
                            {/* <!-- CTA Button --> */}
                            <div className="w-full">
                                <button type="button"
                                onClick={() => history.push('/registration/create-account')}
                                className="hover:bg-[#1D1D25] hover:text-white hover:border-none"
                                style={twj("font-['Inter'] w-full text-sm uppercase font-medium leading-6 rounded-3xl text-sky-500 border border-sky-500 inline-flex items-center justify-center gap-x-2 px-6 py-3")}>
                                    Get started
                                    <div className="h-[20px] w-[20px] inline-flex items-center justify-center">
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.16732 11.3346H14.0548L11.0273 14.968C10.7323 15.3213 10.7807 15.8471 11.134 16.1413C11.4882 16.4363 12.0132 16.388 12.3082 16.0346L16.4748 11.0346C16.5073 10.9955 16.5232 10.9496 16.5473 10.9063C16.5673 10.8713 16.5915 10.8413 16.6065 10.803C16.644 10.7071 16.6665 10.6063 16.6665 10.5046C16.6665 10.5038 16.6673 10.5021 16.6673 10.5013C16.6673 10.5005 16.6665 10.4988 16.6665 10.498C16.6665 10.3963 16.644 10.2955 16.6065 10.1996C16.5915 10.1613 16.5673 10.1313 16.5473 10.0963C16.5232 10.053 16.5073 10.0071 16.4748 9.96797L12.3082 4.96797C12.1423 4.77047 11.9057 4.66797 11.6673 4.66797C11.479 4.66797 11.2898 4.7313 11.134 4.8613C10.7807 5.15547 10.7323 5.6813 11.0273 6.03464L14.0548 9.66797H4.16732C3.70732 9.66797 3.33398 10.0413 3.33398 10.5013C3.33398 10.9613 3.70732 11.3346 4.16732 11.3346Z"
                                                fill="currentColor" />
                                            <mask id="mask0_1_3335" style={{maskType: "luminance"}}
                                                maskUnits="userSpaceOnUse" x="3" y="4" width="14" height="13">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M4.16732 11.3346H14.0548L11.0273 14.968C10.7323 15.3213 10.7807 15.8471 11.134 16.1413C11.4882 16.4363 12.0132 16.388 12.3082 16.0346L16.4748 11.0346C16.5073 10.9955 16.5232 10.9496 16.5473 10.9063C16.5673 10.8713 16.5915 10.8413 16.6065 10.803C16.644 10.7071 16.6665 10.6063 16.6665 10.5046C16.6665 10.5038 16.6673 10.5021 16.6673 10.5013C16.6673 10.5005 16.6665 10.4988 16.6665 10.498C16.6665 10.3963 16.644 10.2955 16.6065 10.1996C16.5915 10.1613 16.5673 10.1313 16.5473 10.0963C16.5232 10.053 16.5073 10.0071 16.4748 9.96797L12.3082 4.96797C12.1423 4.77047 11.9057 4.66797 11.6673 4.66797C11.479 4.66797 11.2898 4.7313 11.134 4.8613C10.7807 5.15547 10.7323 5.6813 11.0273 6.03464L14.0548 9.66797H4.16732C3.70732 9.66797 3.33398 10.0413 3.33398 10.5013C3.33398 10.9613 3.70732 11.3346 4.16732 11.3346Z"
                                                    fill="white" />
                                            </mask>
                                            <g mask="url(#mask0_1_3335)">
                                                <rect y="0.5" width="20" height="20" fill="currentColor" />
                                            </g>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Pricing plan secondary text block --> */}
                    <h2
                        className="mt-20 lg:mt-[35px] text-center text-[35px] font-bold font-['Inter'] leading-9 lg:leading-[43.05px]">
                        Looking for even more Listings?</h2>
                    <p
                        className="mt-[15px] text-center text-slate-500 text-lg font-normal font-['Inter'] leading-6 lg:leading-7">
                        Contact us to discuss a custom package designed just for you.</p>
                </div>
            </section>
    )
};