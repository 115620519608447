import React from 'react'
import styled from 'styled-components'

interface Item {
  name: string
  path?: string
  button: React.ReactNode
}

interface IProps {
  tHeader: string[]
  data: Item[]
}

const InventoryTable = ({ tHeader, data }: IProps) => {
  return (
    <Container>
      <table className='table  table-row-gray-300 align-middle gy-4'>
        <thead>
          <tr className='fw-bolder thead-color table-header'>
            {tHeader.map((item, i) => (
              <th key={i} className='max-w-100px border-0'>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={`${item.name}-${i}`}>
              {Object.keys(item).map((key) => (
                <td key={key} className='border-0'>
                  {item[key as keyof typeof item]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  )
}

const Container = styled.div`
  .table-header {
    background: #f9fbfd;
    border: 1px solid #edf2f9;
    color: #95aac9;
    padding: 0 15px;
  }

  .table th:first-child,
  .table td:first-child {
    padding-left: 15px;
  }

  .table td:last-child {
    text-align: end;
    padding-right: 15px;
  }

  .table tbody tr {
    background: #fff;
    border: 1px solid #edf2f9;
  }
`

export default InventoryTable
