import { FC, useEffect } from 'react'
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../_metronic/helpers'

type Props = {
  totalCount: number
  pageSize?: number
  currentPage?: number
  onPageChange: (target: any) => void
  onPageSizeChange: (pageSize: number) => void
  withPageSizeSelect?: boolean
}

const Pagination2: FC<Props> = ({
  totalCount = 100,
  currentPage = 0,
  pageSize = 50,
  onPageChange,
  onPageSizeChange,
}) => {
  const from = currentPage * pageSize
  const to = from + pageSize > totalCount ? totalCount : from + pageSize
  const totalPages =
    totalCount % pageSize !== 0
      ? Math.floor(totalCount / pageSize) + 1
      : Math.floor(totalCount / pageSize)
  function _getPageSizes() {
    return pageSizeOptions.map((item, i) => (
      <div
        key={i}
        className={`page-size ${pageSize === item ? ' selected' : ''}`}
        onClick={() => onPageSizeChange(item)}
      >
        <span>{item}</span>
      </div>
    ))
  }

  function _onPageChange(pNum: number, dir: string) {
    if ((currentPage === 0 && dir === 'prev') || (currentPage + 1 >= totalPages && dir === 'next'))
      return
    onPageChange({ selected: pNum })
  }

  return (
    <Container>
      <div className='pagination-left-side'>
        <div className='info'>
          <span>Show</span>
        </div>
        {_getPageSizes()}
        <div
          className={`page-size ${pageSize === (totalCount || 200) ? ' selected' : ''}`}
          onClick={() => onPageSizeChange(totalCount || 200)}
        >
          <span>All</span>
        </div>
      </div>
      <div className='pagination-right-side'>
        <div className='info'>
          <span>{`Page ${currentPage + 1} of ${totalPages}`}</span>
        </div>
        <div className='pagination-buttons'>
          <div
            className={`prev ${currentPage === 0 ? 'disabled' : ''}`}
            onClick={() => _onPageChange(currentPage - 1, 'prev')}
          >
            <span>
              <img src={toAbsoluteUrl('/media/svg/misc/chevron-left.svg')} /> Previous page
            </span>
          </div>
          <div
            className={`next ${currentPage + 1 >= totalPages ? 'disabled' : ''}`}
            onClick={() => _onPageChange(currentPage + 1, 'next')}
          >
            <span>
              Next page <img src={toAbsoluteUrl('/media/svg/misc/chevron-right.svg')} />
            </span>
          </div>
        </div>
      </div>
    </Container>
  )
}

export { Pagination2 }

const Container = styled.div`
  border-top: 1px solid #80cffb;
  border-bottom: 1px solid #80cffb;
  padding-block: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pagination-left-side,
  .pagination-right-side,
  .pagination-buttons {
    display: flex;
  }

  .page-size,
  .pagination-buttons,
  .info {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }

  .page-size,
  .prev,
  .next {
    font-weight: 500;
    cursor: pointer;
  }

  .page-size:hover,
  .prev:hover,
  .next:hover,
  .selected {
    color: #009ef7;
  }

  .page-size,
  .info {
    margin-right: 24px;
  }

  .prev {
    margin-right: 24px;
  }

  .disabled {
    color: #cccccc;
  }

  .disabled:hover {
    color: #cccccc;
  }
`
const pageSizeOptions = [10, 20, 50, 75, 100]
