import { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { debounce } from 'lodash'

import { categoryApis } from '../../../../setup/apis/category/categoryApis'
import { StoreType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { importProductsApis } from '../../../../setup/apis/product/importProductsApis'
import { SelectEventKeys, FilterTypes } from '../../filters/importProductsFilters'
import { activeStoreSelector } from '../../../modules/selectors/reduxSelectors'
import useWhiteBlackListSelect from '../../../hooks/useWhiteBlackListSelect'
import { CustomHeader } from '../../../components/CustomStyledComponents'
import StoreSelect from '../../../components/StoreSelect'
import SearchBar from '../../../components/SearchBar'
import Accordion from '../../../components/Accordion'
import View from '../../../components/View'
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";

interface Item {
  categoryId: string
  categoryName: string
  parentCategoryName: string
  parentCategoryId: string
  childCategoryCount: number
  productCount: number
  totalProductCount: number
  value?: string
}
export interface ICategories extends Item {
  children: ICategories[]
}

const CategoriesPage = () => {
  const storeOptions = useSelector(activeStoreSelector)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])
  const [categoryWhiteList, setCategoryWhiteList] = useState<string[]>([])
  const [categoryBlackList, setCategoryBlackList] = useState<string[]>([])
  const [categoryList, setCategoryList] = useState<ICategories[]>([])
  const [filterIsLoading, setFilterIsLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory<any>()
  const params: { storeId: string} = useParams();

  useEffect(() => {
    if (!!selectedStore) {
      getFilters(selectedStore.storeId)
    }
  }, [selectedStore?.storeId])

  useEffect(() => {
    getCategories(0)
  }, [])

  const { manageList, manageDefaultList } = useWhiteBlackListSelect(
    categoryWhiteList,
    categoryBlackList,
    selectedStore?.storeId,
    FilterTypes.Category,
    setCategoryWhiteList,
    setCategoryBlackList
  )

  async function getFilters(storeId: number) {
    try {
      setFilterIsLoading(true)
      const response = await importProductsApis.getFilter(storeId);
      setCategoryWhiteList(response.categotyWhiteList?.map((item: Item) => item.categoryId))
      setCategoryBlackList(response.categotyBlackList?.map((item: Item) => item.categoryId))
    } catch (err) {
      console.log('error', err)
    } finally {
      setFilterIsLoading(false)
    }
  }

  async function getCategories(page: number) {
    try {
      setIsLoading(true)
      const res = await categoryApis.getAllTree({ page, pageSize: 50 })
      setCategoryList(res.data)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }


  useEffect(() => {
    if (storeOptions.length) {
      let selectedStoreId: number;
      if (params.storeId) {
        selectedStoreId = parseInt(params.storeId);
      } else if (sessionStorage.getItem('storeId')) {
        selectedStoreId = parseInt(sessionStorage.getItem('storeId') ?? '0');
        history.push(`/inventory/categories/${storeOptions.find(item => item.storeId === selectedStoreId)?.storeId}`);
      } else {
        selectedStoreId = storeOptions[0].storeId;
      }
      const selectedStore = storeOptions.find(item => item.storeId === selectedStoreId) || storeOptions[0];
      setSelectedStore(selectedStore);
      history.push(`/discover/categories/${selectedStore.storeId}`);
    }
  }, [storeOptions]);

  function handleStoreSelect(option: StoreType) {
    setSelectedStore(option);
    sessionStorage.setItem('storeId', String(option.storeId));
    history.push(`/discover/categories/${option.storeId}`);
  }

  function handleSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target

    setIsSearching(value.length >= 3)
    setSearchValue(value)
  }

  const debouncedSearchChangeHandler = useCallback(debounce(handleSearchValueChange, 600), [])

  function handleWhiteBlackSelect(eventKey: string | null, id: string, selectedValue: string) {
    if (eventKey !== selectedValue) {
      if (eventKey === SelectEventKeys.Default) {
        manageDefaultList(id)
      } else {
        manageList(eventKey, id)
      }
    }
  }

  const categoriesList = useMemo(
      () =>
          searchCategories(categoryList, ({ categoryName }: { categoryName: string }) => categoryName.toLowerCase().includes(searchValue.toLowerCase())),
      [categoryList, searchValue]
  )

  return (
    <Container>
      <CustomHeader marginl='0'>Discover Categories</CustomHeader>

      <div className='d-flex justify-content-end mb-5'>
        <StoreSelect
          isLoading={filterIsLoading}
          selectedStore={selectedStore}
          handleSelect={handleStoreSelect}
        />
      </div>

      <hr />

      <SearchBar handleChange={debouncedSearchChangeHandler} page='Categories' />

      <div className='accordion-title'>CATEGORY NAME</div>
      <View isLoading={isLoading}>
        <Accordion
          isSearching={isSearching}
          accordionData={categoriesList}
          categoryWhiteList={categoryWhiteList}
          categoryBlackList={categoryBlackList}
          handleSelect={handleWhiteBlackSelect}
        />
      </View>
    </Container>
  )
}

function searchCategories(
    categories: ICategories[],
    func: ({ categoryName }: { categoryName: string }) => boolean
) {
  return categories.reduce((acc: any, cur: any) => {
    let children = searchCategories(cur.children || [], func)
    if (func(cur) || children.length) {
      acc.push({ ...cur, ...(children.length && { children }) })
    }
    return acc
  }, [])
}

const Container = styled.div`
  .accordion-title {
    color: #95aac9;
    background: #f9fbfd;
    border: 1px solid #edf2f9;
    padding: 12px 15px;
    width: 100%;
  }

  .loading-info {
    font-size: 18px;
    font-weight: 500;
    margin-right: 0.75rem;
  }
`

export default CategoriesPage
