import { FC, useState, useEffect, createRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import clsx from 'clsx'
import styled from 'styled-components'
import moment from 'moment'
import { AnyMessageParams } from 'yup/lib/types'

type Props = {
  value?: string
  selected?: Date
  startDate?: Date | null
  endDate?: Date | null
  onChange: (date: any) => void
  className?: string
  calendarClassName?: string
  showTimeSelect?: boolean
  showTimeInput?: boolean
  showPopperArrow?: boolean
  forFilter?: boolean
  selectsRange?: boolean
  inline?: boolean
}

const DateTimePicker: FC<Props> = ({
  value,
  selected,
  startDate,
  endDate,
  onChange,
  className,
  calendarClassName,
  showTimeInput,
  showTimeSelect,
  showPopperArrow,
  forFilter,
  selectsRange,
  inline,
  ...props
}) => {
  return (
    <Container forFilter={forFilter}>
      <ReactDatePicker
        value={value}
        selected={selected}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        className={clsx(`custom-datepicker ${forFilter && 'date-filter'}`, className)}
        calendarClassName={clsx('custom-calendar ', calendarClassName)}
        showTimeInput={showTimeInput}
        showTimeSelect={showTimeSelect}
        timeIntervals={5}
        showPopperArrow={showPopperArrow}
        popperPlacement='bottom'
        popperClassName={forFilter ? 'popper-filter' : ''}
        selectsRange={selectsRange}
        shouldCloseOnSelect={true}
      />
    </Container>
  )
}

DateTimePicker.defaultProps = {
  value: moment(new Date()).format('DD/MM/YYYY hh:mm A'),
  selected: undefined,
  startDate: null,
  endDate: null,
  onChange: (date: Date) => { },
  className: 'form-control form-control-lg form-control-solid',
  calendarClassName: '',
  showTimeInput: false,
  showTimeSelect: false,
  showPopperArrow: true,
  forFilter: false,
  selectsRange: false,
}

const Container = styled.div<any>(({ forFilter }) => `
  width: 100%;

  .custom-calendar {
    border: 2px solid #a1a5bd;
    padding-bottom: 8px;
  }

  .date-filter {
    opacity: 0;
    cursor: pointer;
  }

  .popper-filter {
    margin-top: 18px;
    font-weight: 300;
  }

  .react-datepicker {
    background-color: #ffffff;
  }

  .react-datepicker__current-month {
    background-color: #ffffff;
    font-size: 16px;
    padding-left: 0;
  }

  .react-datepicker__day-names {
    background-color: #ffffff;
  }

  .react-datepicker__header {
    font-size: 12px;
    background-color: #ffffff;
    padding: 10px;
    border: none;
  }

  .react-datepicker__month {
    height: 130px;
    overflow: auto;
  }

  .react-datepicker__time-list,
  .react-datepicker__month {
    //-------------scrollbar------------

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #e4e6ef;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #40b6f9;
    }

    scrollbar-width: 4px;
    scrollbar-color: #40b6f9 #e4e6ef;
  }

  .react-datepicker__day-name {
    margin-inline: 8px;
  }

  .react-datepicker__day {
    font-size: 12px;
    margin-inline: 8px;
  }

  .react-datepicker__day--selected {
    text-align: center;
    background-color: #009ef7;
    border-radius: 50%;
  }

  .react-datepicker__day:hover {
    background-color: #009ef7;
    color: #ffffff;
    border-radius: 50%;
  }

  .react-datepicker__day--keyboard-selected {
    text-align: center;
    background-color: ${forFilter ? '#a9a9a9' : '#009ef7'};
    border-radius: 50%;
  }

  .react-datepicker__day--keyboard-selected:hover {
    background-color: ${forFilter ? '#a9a9a9' : '#009ef7'};
    border-radius: 50%;
  }

  .react-datepicker__time-list-item {
    font-size: 12px;
  }

  .react-datepicker__navigation {
    position: absolute;
    top: 10px;
  }

  .react-datepicker-time__header {
    font-size: 16px;
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background-color: #009ef7;
    color: #ffffff;
    border-radius: 50%;
  }

  .react-datepicker__day--in-range:hover,
  .react-datepicker__day--in-selecting-range:hover {
    background-color: #009ef7;
  }

  .react-datepicker__day--range-start {
    background-color: #009ef7;
    color: #ffffff;
  }

  .react-datepicker__day--range-end {
    // background-color: #c8d0db;
    // color: #000000;
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: #009ef7;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: #009ef7;
  }

  .react-datepicker__navigation *::before {
    border-color: #3f4254;
  }

  .react-datepicker__navigation:hover *::before {
    border-color: #3f4254;
  }
`)
export { DateTimePicker }
