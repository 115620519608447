import axios from 'axios'
import {
  PricingMarkupType,
  PricingTableCreationType,
  ShippingType,
  StorePropertiesType,
} from '../../types/request-data-types/RequestDataTypes'

export const storeSettingsApis = {
  async getPricingSettings(storeId: number) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/StoreSettings/getPricingMarkup?storeId=${storeId}`)
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
  async getShippingSettings(storeId: number) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/StoreSettings/getShippingPricing?storeId=${storeId}`)
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
  async getStoreProperties(storeId: number) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/StoreSettings/getStoreProperties?storeId=${storeId}`)
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
  async getPricingTable(storeId: number) {
    return await axios
      .get(
        `${process.env.REACT_APP_PROXY}/StoreSettings/getPricingTableByStoreId?storeId=${storeId}`
      )
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
  async getSummaryReview(storeId: number) {
    return await axios
      .get(
        `${process.env.REACT_APP_PROXY}/StoreSettings/getStoreSettingsSummaryReview?storeId=${storeId}`
      )
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
  async updatePricingSettings(storeId: number, data: any) {
    return await axios
      .post(
        `${process.env.REACT_APP_PROXY}/StoreSettings/updatePricingMarkup?storeId=${storeId}`,
        data
      )
      .then((res) => res)
      .catch((er) => console.log('error', er))
  },
  async updateShippingSettings(storeId: number, data: ShippingType) {
    return await axios
      .post(
        `${process.env.REACT_APP_PROXY}/StoreSettings/updateShippingPricing?storeId=${storeId}`,
        data
      )
      .then((res) => res)
      .catch((er) => console.log('error', er))
  },
  async updateStoreProperties(storeId: number, data: StorePropertiesType) {
    return await axios
      .post(
        `${process.env.REACT_APP_PROXY}/StoreSettings/updateStoreproperties?storeId=${storeId}`,
        data
      )
      .then((res) => res)
      .catch((er) => console.log('error', er))
  },
  async createPricingTable(data: PricingTableCreationType) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/StoreSettings/createPricingTable`, data)
      .then((res) => res)
      .catch((er) => console.log('error', er))
  },
}
