import React from 'react'

interface IProps {
  condition: boolean
  children: React.ReactNode
}

const When = ({ condition, children }: IProps) => {
  if (!condition) return null
  return <React.Fragment> {children} </React.Fragment>
}

export default When
