import React, { FC } from 'react';
import { twj } from 'tw-to-css';

type Props = {
    title?: string;
    content?: string;
};

export const Features: FC<Props> = ({ title, content }) => {
    return (
        // <!-- Features section -->
        <section className="bg-slate-50 py-20 lg:py-[107px]">
            <div className="mx-auto w-5/6 max-w-[1700px]">
                <h2 className="text-center text-[35px] font-bold font-['Inter'] leading-[43.05px]">Features</h2>
                <p className="text-center text-slate-500 text-lg font-normal font-['Inter'] leading-7"></p>

                {/* <!-- Features grid --> */}
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-10">
                    <div
                        className="bg-white rounded-[20px] shadow min-h-[311px] flex flex-col items-center justify-center">
                        <img className="w-[100px] h-[100px] rounded-full" src="./assets/img/features/Cross-marketplace.svg" />
                        <p
                            className="sm:text-center px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-5 lg:leading-6">
                            Cross-marketplace selling made easy</p>
                        <p
                            className="px-4 mt-[12px] lg:max-w-[365px] sm:text-center text-gray-500 text-sm lg:text-[15px] font-normal font-['Inter'] leading-5 lg:leading-snug">
                            Seamlessly list and sell your products on multiple Amazon marketplaces with our user-friendly tool, expanding your reach effortlessly.</p>
                    </div>
                    <div
                        className="bg-white rounded-[20px] shadow min-h-[311px] flex flex-col items-center justify-center">
                        <img className="w-[100px] h-[100px] rounded-full" src="./assets/img/features/Advanced-filters.svg" />
                        <p
                            className="sm:text-center px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-5 lg:leading-6">
                            Advanced Filters</p>
                        <p
                            className="px-4 mt-[12px] lg:max-w-[365px] sm:text-center text-gray-500 text-sm lg:text-[15px] font-normal font-['Inter'] leading-5 lg:leading-snug">
                            Streamline your product selection process with powerful filters, including brand, category, item cost, and weight options.</p>
                    </div>
                    <div
                        className="bg-white rounded-[20px] shadow min-h-[311px] flex flex-col items-center justify-center">
                        <img className="w-[100px] h-[100px] rounded-full" src="./assets/img/features/Zero-inventory.svg" />
                        <p
                            className="sm:text-center px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-5 lg:leading-6">
                            Zero Inventory Investment</p>
                        <p
                            className="px-4 mt-[12px] lg:max-w-[365px] sm:text-center text-gray-500 text-sm lg:text-[15px] font-normal font-['Inter'] leading-5 lg:leading-snug">
                            Purchase products only after customers place orders, reducing upfront expenses.</p>
                    </div>
                    <div
                        className="bg-white rounded-[20px] shadow min-h-[311px] flex flex-col items-center justify-center">
                        <img className="w-[100px] h-[100px] rounded-full" src="./assets/img/features/Bulk-auto.svg" />
                        <p
                            className="sm:text-center px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-5 lg:leading-6">
                            Bulk Auto Listing</p>
                        <p
                            className="px-4 mt-[12px] lg:max-w-[365px] sm:text-center text-gray-500 text-sm lg:text-[15px] font-normal font-['Inter'] leading-5 lg:leading-snug">
                            List hundreds of products quickly and effortlessly with our bulk auto-listing tool, saving you valuable time and effort.</p>
                    </div>
                    <div
                        className="bg-white rounded-[20px] shadow min-h-[311px] flex flex-col items-center justify-center">
                        <img className="w-[100px] h-[100px] rounded-full" src="./assets/img/features/Sync-inventory.svg" />
                        <p
                            className="sm:text-center px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-5 lg:leading-6">
                            Sync inventory in real-time</p>
                        <p
                            className="px-4 mt-[12px] lg:max-w-[365px] sm:text-center text-gray-500 text-sm lg:text-[15px] font-normal font-['Inter'] leading-5 lg:leading-snug">
                            Ensure your inventory levels are always up-to-date across multiple Amazon marketplaces, preventing stockouts and overselling.</p>
                    </div>
                    <div
                        className="bg-white rounded-[20px] shadow min-h-[311px] flex flex-col items-center justify-center">
                        <img className="w-[100px] h-[100px] rounded-full" src="./assets/img/features/Customizable-templates.svg" />
                        <p
                            className="sm:text-center px-4 mt-[28px] text-zinc-900 text-xl font-medium font-['Inter'] leading-5 lg:leading-6">
                            Customizable templates</p>
                        <p
                            className="px-4 mt-[12px] lg:max-w-[365px] sm:text-center text-gray-500 text-sm lg:text-[15px] font-normal font-['Inter'] leading-5 lg:leading-snug">
                            Tailor your pricing, currency conversion, and shipping settings to match your specific business needs with our customizable templates.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};