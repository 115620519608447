import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {twj} from "tw-to-css";

type Props = {
    title?: string;
    content?: string;
};

export const WhatIsMarketGap: FC<Props> = ({ title, content }) => {
    const history = useHistory()

    return (
        // <!-- What is MarketGap section -->
            <section className="bg-white tw-py-5 lg:py-[101px]">
                <div className="mx-auto w-5/6 grid grid-cols-1 lg:grid-cols-2 gap-4 max-w-[1700px]">
                    <div>
                        <img className="" src="./assets/img/illustrations/What-is-MarketGap.png" />
                    </div>
                    <div className="flex flex-col items-start justify-center">
                        <div className="flex flex-col gap-[11px] items-start justify-center">
                            <h2
                                className="mt-5 xl:mt-0 text-zinc-900 text-[35px] font-semibold font-['Inter'] leading-9 xl:leading-[58.37px]">
                                What is MarketGap</h2>
                            <p style={twj("text-gray-700 text-base font-light font-['Inter'] leading-[25px]")}>MarketGap is a
                                proprietary e-commerce solutions technology that empowers its members to sell millions
                                of products across the world's leading online marketplaces and buy them only after you
                                have already sold them.</p>
                            <p style={twj("text-gray-700 text-base font-light font-['Inter'] leading-[25px]")}>Our intuitive
                                software monitors millions of unique products offered in real time on online
                                marketplaces, enabling our members to automate listing, delisting, currency conversion,
                                profit markup, and shipping to offer an assortment of products that we monitor with the
                                click of a button in the concurrent marketplace across the globe.</p>
                        </div>
                        <div className="w-full">
                            <div className="sm:max-w-[240px] mt-10 flex items-center gap-x-8">
                                <button type="button"
                                onClick={() => history.push('/registration/create-account')}
                                    style={twj("font-['Inter'] w-full text-sm uppercase font-medium leading-6 rounded-3xl text-zinc-900 border border-sky-500 inline-flex items-center justify-center gap-x-2 px-6 py-3")}>
                                        <svg className="fill-current" width="21" height="21" viewBox="0 0 30 30"
                                            fill="none">
                                            <g clip-path="url(#clip0_1_6654)">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M10.8519 22.751L8.05193 20.651C7.49193 20.231 7.52011 19.0238 7.89128 17.5489L4.55193 18.026L4.37693 16.801L6.47693 14.001L9.3961 13.584C9.9043 12.552 10.472 11.5909 11.0269 10.851C13.1269 8.05098 17.8519 6.12598 19.9519 7.70098C22.0519 9.27598 21.5269 14.351 19.4269 17.151C18.8715 17.8916 18.1083 18.705 17.2599 19.4818L17.6769 22.401L15.5769 25.201L14.3519 25.376L13.8749 22.0366C12.5629 22.8059 11.4126 23.1715 10.8519 22.751ZM10.5019 20.301L9.37143 19.4531C9.36886 19.3636 9.37067 19.2741 9.37685 19.1848C9.41325 18.6303 9.58948 17.8513 9.88663 16.9608C10.4932 15.1429 11.5056 13.1295 12.4269 11.901C14.1059 9.66238 17.8127 8.28408 18.9019 9.10098C19.9911 9.91788 19.7059 13.8624 18.0269 16.101C17.1056 17.3295 15.4557 18.8658 13.8808 19.9564C13.1084 20.4905 12.4112 20.8776 11.8878 21.068C11.804 21.0991 11.7188 21.1261 11.6324 21.1489L10.5019 20.301ZM14.7019 14.701C15.0732 14.9795 15.54 15.099 15.9994 15.0334C16.4589 14.9677 16.8735 14.7223 17.1519 14.351C17.4304 13.9797 17.55 13.513 17.4843 13.0535C17.4187 12.594 17.1732 12.1795 16.8019 11.901C16.4306 11.6225 15.9639 11.5029 15.5044 11.5686C15.045 11.6342 14.6304 11.8797 14.3519 12.251C14.0735 12.6223 13.9539 13.089 14.0195 13.5485C14.0852 14.0079 14.3306 14.4225 14.7019 14.701Z" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1_6654">
                                                    <rect width="21" height="21"
                                                        transform="translate(12.6) rotate(36.8699)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    Get started for free
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
};