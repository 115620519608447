import React, { FC } from 'react';
import { twj } from 'tw-to-css';

type Props = {
    title?: string;
    content?: string;
};

export const HowItWorks: FC<Props> = ({ title, content }) => {
    return (
        // <!-- How it works section -->
            <section className="bg-white tw-py-20 lg:py-[107px]">
                <div className="w-5/6 mx-auto max-w-[1700px]">
                    <h2 className="text-center text-[35px] font-bold font-['Inter'] leading-[43.05px]">How it works</h2>

                    <div className="lg:flex flex-row-reverse justify-end items-start">
                        <div className="mt-10 lg:mt-0 flex flex-col items-center justify-center basis-2/4">
                            <img className="mx-20 lg:mx-0" src="./assets/img/illustrations/How-it-works.png" />
                        </div>
                        {/* <!-- Bullet points--> */}
                        <div className="grid grid-cols-1 gap-10 mt-10 basis-2/4">
                            {/* <!-- Bullet point card --> */}
                            <div className="flex">
                                <div className="flex items-start justify-contents mr-[28px]">
                                    <span
                                        className="inline-flex justify-center items-center w-[56px] h-[56px] bg-[#F8FCFF] rounded-full text-sky-400 text-3xl font-semibold font-['Inter'] leading-loose">1</span>
                                </div>
                                <div>
                                    <h3
                                        className="text-zinc-900 text-[19px] font-semibold font-['Inter'] leading-loose tracking-tight">
                                        Sign-up ➕</h3>
                                    <p
                                        style={twj("line-clamp-3 max-w-[308px] text-gray-500 text-sm lg:text-base font-normal font-['Inter'] leading-5 lg:leading-normal")}>
                                        Begin your journey by signing up for MarketGap. Creating your account is quick and easy, providing you access to a suite of powerful tools and resources.</p>
                                </div>
                            </div>
                            {/* <!-- Bullet point card --> */}
                            <div className="flex">
                                <div className="flex items-start justify-contents mr-[28px]">
                                    <span
                                        className="inline-flex justify-center items-center w-[56px] h-[56px] bg-[#F8FCFF] rounded-full text-sky-400 text-3xl font-semibold font-['Inter'] leading-loose">2</span>
                                </div>
                                <div>
                                    <h3
                                        className="text-zinc-900 text-[19px] font-semibold font-['Inter'] leading-loose tracking-tight">
                                        Filter keywords 📝</h3>
                                    <p
                                        style={twj("line-clamp-3 max-w-[308px] text-gray-500 text-sm lg:text-base font-normal font-['Inter'] leading-5 lg:leading-normal")}>
                                        Enhance compliance and visibility by specifying which words or phrases to allow or restrict in your product titles. Additionally, take control by filtering brands, categories, item purchase cost, weight, and dimensions to fine-tune your listings. </p>
                                </div>
                            </div>
                            {/* <!-- Bullet point card --> */}
                            <div className="flex">
                                <div className="flex items-start justify-contents mr-[28px]">
                                    <span
                                        className="inline-flex justify-center items-center w-[56px] h-[56px] bg-[#F8FCFF] rounded-full text-sky-400 text-3xl font-semibold font-['Inter'] leading-loose">3</span>
                                </div>
                                <div>
                                    <h3
                                        className="text-zinc-900 text-[19px] font-semibold font-['Inter'] leading-loose tracking-tight">
                                        Add Brands 💼</h3>
                                    <p
                                        style={twj("line-clamp-3 max-w-[308px] text-gray-500 text-sm lg:text-base font-normal font-['Inter'] leading-5 lg:leading-normal")}>
                                        Customize your store's brand selection. Add brands to your allowed or blocked lists to fine-tune your product offerings, aligning with your business strategy.</p>
                                </div>
                            </div>
                            {/* <!-- Bullet point card --> */}
                            <div className="flex">
                                <div className="flex items-start justify-contents mr-[28px]">
                                    <span
                                        className="inline-flex justify-center items-center w-[56px] h-[56px] bg-[#F8FCFF] rounded-full text-sky-400 text-3xl font-semibold font-['Inter'] leading-loose">4</span>
                                </div>
                                <div>
                                    <h3
                                        className="text-zinc-900 text-[19px] font-semibold font-['Inter'] leading-loose tracking-tight">
                                        Listed! 🚀</h3>
                                    <p
                                        style={twj("line-clamp-3 max-w-[308px] text-gray-500 text-sm lg:text-base font-normal font-['Inter'] leading-5 lg:leading-normal")}>
                                        With your filters and brand preferences set, watch as MarketGap efficiently lists your selected products. Your items are now available on your Amazon store, simplifying your cross-marketplace selling experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
};