import React, { ChangeEvent, FC } from 'react'
import styled from 'styled-components'

type Props = {
  type: string
  className?: string
  name: string
  id: string
  value?: string | number
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  readOnly?: boolean
  disabled?: boolean
  min?: number
  max?: number
  step?: string | "any"
  placeholder?: string
  defaultChecked?: boolean
  autoComplete?: string | undefined
  pattern?: string
  onKeyPress?: (event: any) => void
  onKeyDown?: (event: any) => void
}

const Input: FC<Props> = ({
  type,
  checked,
  className,
  name,
  value,
  onChange,
  readOnly,
  disabled,
  min,
  max,
  step,
  placeholder,
  defaultChecked,
  autoComplete,
  pattern,
  onKeyPress,
  onKeyDown
}) => {
  return (
    <Container
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      className={className}
      checked={checked}
      readOnly={readOnly}
      disabled={disabled}
      min={min}
      max={max}
      step={step || 'any'}
      placeholder={placeholder}
      defaultChecked={defaultChecked}
      autoComplete={autoComplete || 'off'}
      pattern={pattern}
    />
  )
}

export default Input

const Container = styled.input``
