import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'

const initialState = { access_token: '', refresh_token: '' }

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getToken: (state, action) => action.payload,
    deleteToken: () => ({ access_token: '', refresh_token: '' }),
  },
})

const persistConfig = {
  key: 'user',
  storage: new CookieStorage(Cookies)
}

const reducer = persistReducer(persistConfig, userSlice.reducer)

export const { getToken, deleteToken } = userSlice.actions
export default reducer
