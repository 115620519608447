import {FC, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useHistory} from 'react-router-dom'
import {useOnClickOutside} from '../../utils/customHooks'
import {
  StoreType,
  UnreadNotificationCountByStoreModelType,
} from '../../../setup/types/response-data-types/ResponseDataTypes'

type Props = {
  inputClassName: string
  dropdownClassName?: string
  options: StoreType[]
  value: StoreType
  onChange: (opion: StoreType) => void
  storesWithRedDot: UnreadNotificationCountByStoreModelType[]
}

const StoreSelect: FC<Props> = ({
  onChange,
  inputClassName,
  dropdownClassName,
  options,
  value,
  storesWithRedDot,
}) => {
  const [searchText, setSearchText] = useState<string>('')
  const [inputValue, setInputValue] = useState<string>(value?.label || '')
  const [chevron, setChevron] = useState<string>('')
  const [visible, setVisible] = useState<boolean>(false)
  const history = useHistory()
  const inputRef = useRef<any>()
  function selectOption(option: StoreType) {
    setInputValue(option?.label || '')
    setVisible(false)
    onChange(option)
    setChevron('')
  }

  useOnClickOutside(inputRef, () => {
    setVisible(false)
    setChevron('')
  })

  useEffect(() => {
    setInputValue(value?.label || '')
  }, [value])

  function getOptions() {
    const opts = options.filter((item) =>
      item.label.toLowerCase().includes(searchText.toLowerCase())
    )
    const withDot = opts.filter((item) => hasNotification(item?.storeId))
    const withoutDot = opts.filter((item) => !hasNotification(item?.storeId))
    return [...withDot, ...withoutDot].map((item, index) => (
      <div className='plan-option' onClick={() => selectOption(item)} key={index}>
        <span>{item.label}</span>
        {hasNotification(item?.storeId) ? <div className='red-dot' /> : null}
      </div>
    ))
  }

  function _onDropdownOpen() {
    setVisible(true)
    setChevron('-dark')
  }

  function _onInputChange(e: any) {
    setSearchText(e.target.value)
    setInputValue(e.target.value)
  }

  function hasNotification(storeId: number) {
    return storesWithRedDot?.findIndex((item) => item?.storeId === storeId) !== -1
  }

  return (
    <Container ref={inputRef}>
      <div className='input-container'>
        <input
          className={`${inputClassName} custom-input`}
          value={inputValue}
          placeholder='Select store...'
          onClick={_onDropdownOpen}
          onChange={(e) => _onInputChange(e)}
        />
        <div className='chevron-icon'>
          <img src={toAbsoluteUrl(`/media/svg/misc/chevron-down${chevron}.svg`)} />
        </div>
      </div>
      {visible ? <div className='option-container'>{getOptions()}</div> : null}
    </Container>
  )
}

export {StoreSelect}

const Container = styled.div`
  position: relative;
  .input-container {
    position: relative;
  }

  .custom-input {
    cursor: pointer;
  }

  .chevron-icon {
    position: absolute;
    top: 13px;
    right: 13px;
    color: #cccccc;
  }

  .option-container {
    box-sizing: border-box;
    position: absolute;
    top: 50px;
    background-color: #f0f4f8;
    border-radius: 4px;
    border: 1px solid #cdcfda;
    max-height: 220px;
    overflow: auto;
    width: 100%;
    z-index: 4;
  }

  .plan-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f4f8;
    color: #3f4254;
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
  }

  .plan-option:hover {
    background-color: #e6f5fe;
    color: #3f4254;
    cursor: default;
  }

  .custom-link {
    color: #009ef7;
    cursor: pointer;
  }

  .red-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #b90000;
    margin-right: 15px;
  }
`
