import {createSlice} from '@reduxjs/toolkit'
import {CountryType} from '../../../setup/types/response-data-types/ResponseDataTypes'

const initialState: CountryType[] = []

const countrySlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    getCountries: (state, action) => action.payload,
  },
})

export const {getCountries} = countrySlice.actions
export default countrySlice.reducer
