import axios from 'axios'
import LogRocket from 'logrocket';
import {contextType} from 'react-copy-to-clipboard'
import {AccountType} from '../../../app/layger/settings/account-settings/AccountSettings'
import {getConfig, METADATA_OIDC} from '../../../app/utils/authUtils'
import ReactGA from 'react-ga4';

import {
  ContactType,
  ForgotPasswordType,
  LoginType,
  RegisterType,
  ResetPasswordType,
} from '../../types/request-data-types/RequestDataTypes'
import qs from 'qs'

export const accountApis = {
  async register(requestData: RegisterType) {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Button',
      label: 'CreateAccount',
    });

    return await axios
      .post(`${process.env.REACT_APP_PROXY}/register`, requestData)
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
  async login(username: string, password: string) {
    const jsonData: LoginType = {
      grant_type: 'password',
      username,
      password,
      client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID || '',
      scope: 'api.full offline_access',
    }
    const requestData = new URLSearchParams(Object.entries(jsonData)).toString()
    return await axios
      .post(METADATA_OIDC.token_endpoint, requestData)
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
  async updateUser(requestData: any, token?: string) {
    return await axios
      .put(`${process.env.REACT_APP_PROXY}/Users`, requestData, getConfig(token || ''))
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
  async getUserById(userId: string) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/Users/${userId}`)
      .then((res) => res.data)
       .then((res) => {
         LogRocket.identify("userId", {
           name: res.username,
           email: res.email,
         })
         return res
       })
      .catch((er) => {
        throw er
      })
  },
  async sendMessage(requestData: FormData) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/UserMessages/contact-us`, requestData)
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
  async ctaToConsider(requestData: FormData) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/UserMessages/cta-to-consider`, requestData)
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
  async forgotPassword(requestData: ForgotPasswordType) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/Users/forgot-password`, requestData)
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
  async sendEmailConfirmation(requestData: ForgotPasswordType) {
    return await axios
      .post(
        `${process.env.REACT_APP_PROXY}/Users/send-email-confirmation?email=${requestData.email}`
      )
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
  async resetPassword(requestData: ResetPasswordType) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/Users/reset-password`, requestData)
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
  async confirmEmail(userId: string, token: string) {
    token = qs.stringify({token: token}, {format: 'RFC1738'})
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/Users/confirm-email?userId=${userId}&${token}`)
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
  async verifyLink(userId: string, token: string) {
    return await axios
      .get(
        `${process.env.REACT_APP_PROXY}/Users/verify-password-reset-token?userId=${userId}&token=${token}`
      )
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
}
