import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { CategoryList } from './CategoryList'

const categoryBreadCrumbs: Array<PageLink> = [
  {
    title: 'Categories',
    path: '/categories/categorylist',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CategoryPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/categories/categorylist'>
          <PageTitle breadcrumbs={categoryBreadCrumbs}>Category List</PageTitle>
          <CategoryList />
        </Route>
      </Switch>
    </>
  )
}

export default CategoryPage
