import { triggerAlert } from '../../app/components/Alert'
import { getToken } from '../../app/modules/redux-toolkit/userSlicer'
import store from '../redux/Store'
import { AUTH_STATUS, logout, METADATA_OIDC } from '../../app/utils/authUtils'
export default function setupAxios(axios: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        user: { access_token },
      } = store.getState()
      if (access_token && config.url !== METADATA_OIDC.token_endpoint) {
        config.headers = {
          'Access-Control-Allow-Origin': true,
          'Content-type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        }
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      const req = error?.config
      if (error?.response?.status === 500) {
        triggerAlert('There is a server side error', 'error')
        return
      }
      if (error?.response?.status === 401) {
        const {
          user: { refresh_token },
        } = store.getState()
        const jsonData = {
          grant_type: 'refresh_token',
          client_id: 'spa-client',
          refresh_token,
        }
        const requestData = new URLSearchParams(Object.entries(jsonData)).toString()
        return axios
          .post(METADATA_OIDC.token_endpoint, requestData)
          .then((res: any) => {
            if (localStorage.getItem('authStatus') !== AUTH_STATUS.loggedOut) {
              store.dispatch(getToken(res?.data))
            }
            return axios(req)
          })
          .catch(() => {
            logout()
          })
      }
      console.log('error')
      return Promise.reject(error)
    }
  )
}
