import React from 'react'

import 'react-loading-skeleton/dist/skeleton.css'
import { CustomLoading } from './CustomStyledComponents'

interface IProps {
  isLoading: boolean
  children: React.ReactNode
}

const View = ({ isLoading, children }: IProps) => {
  if (isLoading)
    return (
      <CustomLoading>
        <div className='text'>
          <span>Loading...</span>
        </div>
      </CustomLoading>
    )

  return <div>{children}</div>
}

export default View
