import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap-v5'

import { StoreType } from '../../setup/types/response-data-types/ResponseDataTypes'
import { activeStoreSelector } from '../modules/selectors/reduxSelectors'
import Select from './Select'

type Props = {
  width?: string
  isLoading?: boolean
  selectedStore: StoreType
  handleSelect: (option: StoreType) => void
}

const StoreSelect: React.FC<Props> = ({ selectedStore, handleSelect, width, isLoading }) => {
  const storeOptions = useSelector(activeStoreSelector)

  return (
    <SelectContainer width={width} className='store-select'>
      {isLoading && <Spinner animation='border' variant='secondary' />}

      <div className='select'>
        <Select
          value={selectedStore}
          options={storeOptions}
          name='layout-builder[layout][header][width]'
          onChange={(option) => handleSelect(option)}
          withRs
        />
      </div>
    </SelectContainer>
  )
}

const SelectContainer = styled.div<any>(
  ({ width }) => `
  display: flex;
  align-items: center;
  
  .select{
    margin-left: 1rem;
    width: ${width ? width : '223px'};

  }
  
  label {
    font-size: 14px;
    white-space: nowrap;
    margin-right: 30px;
    margin-top: 6px;
  }

  .store-select:focus {
    outline: none;
    border: 1px solid #009ef7;
    box-shadow: 0 0 1px 2px #c0e7fd;
  }
`
)

export default StoreSelect
