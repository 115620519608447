import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { StoreType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { ButtonPrimary, CustomHeader } from '../../../components/CustomStyledComponents'
import useGetStoreFilterSettings from '../../../hooks/useGetStoreFilterSettings'
import { activeStoreSelector } from '../../../modules/selectors/reduxSelectors'
import { FilterTypes, ListTypes } from '../../filters/importProductsFilters'
import { filterBySearchValue } from '../../../utils/helperUtils'
import InventoryTable from '../../../components/InventoryTable'
import AddListModal from '../../../components/AddListModal'
import RemoveButton from '../../../components/RemoveButton'
import StoreSelect from '../../../components/StoreSelect'
import useAddFilter from '../../../hooks/useAddFilter'
import SearchBar from '../../../components/SearchBar'
import Tabs from '../../../components/Tabs'
import View from '../../../components/View'
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {PagingType} from "../../../../setup/types/request-data-types/RequestDataTypes";
import {Pagination} from "../../../components/Pagination";
import debounce from "lodash/debounce";

const RestrictedKeywords = () => {
  const storeOptions = useSelector(activeStoreSelector)

  const [pagingData, setPagingData] = useState<PagingType>({ pageNumber: 0, pageSize: 500, count: 0 })
  const [show, setShow] = useState(false)
  const [tab, setTab] = useState<string>('black')
  const [selectedId, setSelectedId] = useState('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])
  const history = useHistory<any>()
  const params: { storeId: string } = useParams();

  const { isLoading, getKeywords, keywordsWhiteList, keywordsBlackList, setKeywordsBlackList, setKeywordsWhiteList } = useGetStoreFilterSettings<string>(
    '',
    'titleWordBlacklist',
    selectedStore?.storeId
  )

  const { isLoading: addIsLoading, addFilter } = useAddFilter(
    selectedStore?.storeId,
    setShow,
    () => getKeywords(pagingData, searchValue)
  )

    useEffect(() => {
        if (storeOptions.length === 0) return;

        const paramsStoreId = parseInt(params.storeId);
        const storedStoreId = parseInt(sessionStorage.getItem('storeId') || '0');

        const selectedStoreId = paramsStoreId || storedStoreId || storeOptions[0]?.storeId;
        const selectedStore = storeOptions.find(item => item.storeId === selectedStoreId) || storeOptions[0];

        setSelectedStore(selectedStore);
        sessionStorage.setItem('storeId', String(selectedStoreId));
        history.push(`/inventory/restricted-keywords/${selectedStore.storeId}`);
    }, [storeOptions]);

    function handleStoreSelect(option: StoreType) {
        setSelectedStore(option);
        sessionStorage.setItem('storeId', String(option.storeId));
        history.push(`/inventory/restricted-keywords/${option.storeId}`);
    }

  function handlePageChange({ selected }: any) {
      setPagingData((prev) => ({ ...prev, pageNumber: selected }))
  }


    useEffect(() => {
        let paramStoreId = parseInt(params.storeId);

        if (selectedStore?.storeId == paramStoreId) {
            const debouncedSearch = debounce(() => getKeywords(pagingData, searchValue), 300);
            debouncedSearch();

            return () => {
                debouncedSearch.cancel();
            };
        }
    }, [searchValue]);

    useEffect(() => {
        let storeParam = parseInt(params.storeId);
        if (selectedStore?.storeId == storeParam) getKeywords(pagingData, searchValue)
    }, [selectedStore?.storeId, pagingData])

    function handleSearchValueChange(value: string) {
        setSearchValue(value)
    }

    function search(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target
        handleSearchValueChange(value)
    }

  const tableData = keywordsBlackList
    .filter((el) => filterBySearchValue(el, searchValue))
    .map((item) => ({
      name: item,
      button: (
        <RemoveButton
          value={item}
          selectedId={selectedId}
          listType={ListTypes.BlackList}
          storeId={selectedStore?.storeId}
          filterType={FilterTypes.TitleWord}
          setSelectedId={setSelectedId}
          setWhiteList={setKeywordsWhiteList}
          setBlackList={setKeywordsBlackList}
        />
      ),
    }))

  return (
    <div style={{paddingBlockEnd: '100px'}}>
      <CustomHeader marginl='0'>Restricted Keyword List</CustomHeader>

      <div className='d-flex justify-content-end'>
        <StoreSelect
          selectedStore={selectedStore}
          handleSelect={(option: StoreType) => handleStoreSelect(option)}
        />

        <ButtonPrimary
          onClick={() => setShow(true)}
          bgColor='#037FC5'
          paddingx={22}
          paddingy={12}
          fontSize={13}
          marginl={12}
        >
          Add to list
        </ButtonPrimary>
      </div>

      <Tabs tabData={[{ title: 'Blocked', value: 'black' }]} tab={tab} setTab={setTab} />

      <SearchBar handleChange={search} page='Restricted Keywords' />

      <View isLoading={isLoading}>
        <InventoryTable tHeader={['Restricted Keywords', '']} data={tableData} />
          <Pagination
              currentPage={pagingData.pageNumber}
              pageSize={pagingData.pageSize}
              totalCount={tableData.length}
              onPageChange={handlePageChange}
              onPageSizeChange={() => {}}
          />
      </View>

      <AddListModal
        show={show}
        page='keyword'
        setShow={setShow}
        addFilter={addFilter}
        isLoading={addIsLoading}
        selectedStore={selectedStore}
        listType={ListTypes.BlackList}
        filterType={FilterTypes.TitleWord}
        handleStoreSelect={(option: StoreType) => setSelectedStore(option)}
      />
    </div>
  )
}

export default RestrictedKeywords
