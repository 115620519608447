import React from 'react'
import styled from 'styled-components'

type Props = {
  page: string
  value?: string
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchBar: React.FC<Props> = ({ page, value, handleChange }) => {
  return (
    <Container>
      <input
        type='text'
        value={value}
        onChange={handleChange}
        className='search-input'
        placeholder={`Search: ${page}`}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;

  .search-input {
    width: 100%;
    font-size: 16px;
    padding: 20px 45px;
    border: 1px solid #edf2f9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .search-input:focus {
    outline: none;
  }

  .search-input::placeholder {
    color: #95aac9;
  }
`

export default SearchBar
