import { useState, SetStateAction } from 'react'

import { importProductsApis } from '../../setup/apis/product/importProductsApis'
import { ListTypes, SelectEventKeys } from '../layger/filters/importProductsFilters'

const useWhiteBlackListSelect = (
  whiteList: string[],
  blackList: string[],
  storeId: number,
  filterType: string,
  setWhiteList: (value: SetStateAction<string[]>) => void,
  setBlackList: (value: SetStateAction<string[]>) => void
) => {
  const [isLoading, setIsLoading] = useState(false)

  async function setDefault(id: string, existsInWhiteList: boolean, existsInBlackList: boolean) {


    if(existsInBlackList) {
      await importProductsApis.removeStringFilter([
        {
          storeId,
          listType: ListTypes.BlackList,
          filterType,
          value: id,
        },
      ])
    }

    if(existsInWhiteList) {
      await importProductsApis.removeStringFilter([
        {
          storeId,
          listType: ListTypes.WhiteList,
          filterType,
          value: id,
        },
      ])
    }

    setBlackList((prevData) => filterItems(prevData, id))
    setWhiteList((prevData) => filterItems(prevData, id))

    return;
  }

  async function manageList(eventKey: string | null, id: string) {
    const existsInWhiteList = whiteList.includes(id)
    const existsInBlackList = blackList.includes(id)
    const isAllowed = eventKey === SelectEventKeys.Allowed
    const isBlocked = eventKey === SelectEventKeys.Blocked
    const isDefault = eventKey === SelectEventKeys.Default

    const addListType = isAllowed ? ListTypes.WhiteList : ListTypes.BlackList
    const removeListType = isAllowed ? ListTypes.BlackList : ListTypes.WhiteList

    try {
      setIsLoading(true)
      if(isDefault) {
        //If its default just remove from all lists and just return
        return await setDefault(id, existsInWhiteList, existsInBlackList);
      }

      if ((existsInWhiteList && isBlocked) || (existsInBlackList && isAllowed)) {
        await importProductsApis.removeStringFilter([
          {
            storeId,
            listType: removeListType,
            filterType,
            value: id,
          },
        ])

        isAllowed
          ? setBlackList((prevData) => filterItems(prevData, id))
          : setWhiteList((prevData) => filterItems(prevData, id))
      }

      await importProductsApis.addStringFilter([
        {
          storeId,
          listType: addListType,
          filterType,
          value: id,
        },
      ])

      isAllowed
        ? setWhiteList((prevData) => [...prevData, id])
        : setBlackList((prevData) => [...prevData, id])
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  async function manageDefaultList(id: string) {
    const existsInWhiteList = whiteList.includes(id)
    const existsInBlackList = blackList.includes(id)

    const listType = existsInWhiteList ? ListTypes.WhiteList : ListTypes.BlackList

    try {
      setIsLoading(true)
      if (existsInWhiteList || existsInBlackList) {
        await importProductsApis.removeStringFilter([
          {
            storeId,
            listType,
            filterType,
            value: id,
          },
        ])

        existsInBlackList
          ? setBlackList((prevData) => filterItems(prevData, id))
          : setWhiteList((prevData) => filterItems(prevData, id))
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, manageList, manageDefaultList }
}

export function filterItems<T>(list: T[], id: string) {
  return list.filter((item) => String(item) !== id)
}

export default useWhiteBlackListSelect
