import axios from 'axios'
import {ChartReportRequestDataType} from '../../types/request-data-types/RequestDataTypes'

export const reportApis = {
  async getProductIfno(storeId: number) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/Dashboard/produclist?storeId=${storeId}`)
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
  async getOrderIfno(storeId: number) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/Dashboard/orderslistreport?storeId=${storeId}`)
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
  async getChartReport(requestData: ChartReportRequestDataType) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/Dashboard/chartreport`, requestData)
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
}
